<template>
  <div class="codeAndService" :class="{codeAndServiceEn:lang=='e'}">
    <div
      v-for="(item,index) in codeAndServiceList"
      :key="index"
      class="codeService"
	  :class="{codeServiceEn:lang=='e'}"
    >
      <div class="imageAndIcon">
        <img :src="item.imgSrc" :class="{imgEn:lang=='e'}">
      </div>
      <div class="textcontent">
        <h3>{{ item.title }}</h3>
        <p :class="{pEn:lang=='e'}">{{ item.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
	export default{
		data(){
			return{
				lang:sessionStorage.getItem('lang')||'s'
				// codeAndServiceList: [{
				// 		imgSrc: require('../assets/static/daima.png'),
				// 		title: "代码审计",
				// 		content: "代码审计是指安全代码评估者尽可能的通过阅读应用系统的源代码来发现潜在的安全漏洞及隐患的技术手段。代码审计的技术手段，可以弥补黑盒渗透测试的未能完全覆盖的漏洞环节与安全隐患，是一种可靠性、安全性最高的修补漏洞的方法。 区块链代码审计云平台目前提供审计的类型为数字货币、智能合约。"
				// 	},
				// 	{
				// 		imgSrc: require('../assets/static/yewu.png'),
				// 		title: "业务范围",
				// 		content: "围绕网络安全、区块链底层技术和上层应用的安全，面向行业开展区块链安全检测工作，其中项目检测包括7个方面：公链联盟链安全检测、智能合约安全检测、钱包安全检测、APP安全检测、Web网站安全检测、系统渗透、日常监测。"
				// 	}
				// ],
			}
		},
		computed:{
			codeAndServiceList(){
				return [{
						imgSrc: require('../assets/static/daima.png'),
						title: this.$t('index.daimasj'),
						content: this.$t('index.daimaContent')
					},
					{
						imgSrc: require('../assets/static/yewu.png'),
						title: this.$t('index.yewufw'),
						content: this.$t('index.yewuContent')
					}
				]
			}
		},
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	.codeAndServiceEn{
		height: 14.45rem !important;
	}
	.codeAndService {
		width: 24.725rem;//989
		height: 11.75rem;//430
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 0 auto;
		
		@media (max-width:1024px){
			width: 17.25rem;//690
			height: 15rem;//
			margin: 60px auto 0;
		}
		.codeServiceEn{
			height: 6.5rem !important;
		}
		.codeService {
			width: 24.725rem;//989
			height: 5rem;//200
			display: flex;
			background: #FFFFFF;
			box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
			@media (max-width:1024px){
				width: 17.25rem;//690;
				height: 7rem;//140
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.imageAndIcon{
				.imgEn{
					width: 6.5rem;
					height: 6.5rem;
				}
				>img{
					width: 5rem;//200
					@media (max-width:1024px){
							width: 7rem;
							height: 7rem;
					}
				}
				
			}
		
			.textcontent {
				padding-right: 0.6rem;
				box-sizing: border-box;
				overflow: hidden;
				>h3 {
					margin-top: 0.75rem;//30
					margin-left: 0.75rem;//30
					font-size: 0.65rem;
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
					@media (max-width:1024px){
						font-size: 0.75rem;//24
						margin-top: 0.35rem;
						margin-left: 0.35rem;
					}
					
				}
				
	
				>p {
					text-align: justify;
					margin-top: 0.45rem;//20px
					margin-left: 0.75rem;//30
					font-size: 0.35rem;
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #666666;
					line-height: 0.55rem;//24
					@media (max-width:1024px){
						font-size: 0.45rem;
						margin-top: 0.05rem;
						margin-left: 0.35rem;
						
					}
				}
				.pEn{
					@media (max-width:1024px){
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 7;
						overflow: hidden;
					}
				}
			}
		}
	}
</style>
