<template>
	<div class="webDetection">
		<div class="header">
			<h2>{{ this.$t('webDetection.name',{name:this.name,englishName:this.englishName,TCname:this.TCname}) }}</h2>
			<p>{{ this.$t('webDetection.remark',{remark:this.remark,englishRemark:this.englishRemark,tcRemark:this.TCremark}) }}</p>
			<!-- <button @click="toProgramTestingItem($route.query.categoryCode)">立即检测</button> -->
		</div>
		<div class="process">
			<div class="testNav" :class="{centers:optionItemList.length<=3}">
				<!-- @click="clickCurrentNav(testItem.categoryCode,index1)" -->
				<p v-for="(testItem,index1) in optionItemList" :key="testItem.categoryCode" class="nav"
					:class="{classActive:testItem.isSelect,reds:$route.query.name=='软件产品测评'&&index1<3}"
					@click="toHash(testItem.categoryCode)">
					<!-- {{ testItem.categoryName }} -->
					{{ $t('webDetection.categoryName',{categoryName:testItem.categoryName,categoryEnglishName:testItem.categoryEnglishName,tcCategoryName:testItem.categoryTcName}) }}
				</p>
			</div>

			<div v-for="(testItem1,index2) in childTest" :id="testItem1.categoryCode" :key="testItem1.categoryCode"
				class="testItem">
				<h2>{{ $t('webDetection.categoryName',{categoryName:testItem1.categoryName,categoryEnglishName:testItem1.categoryEnglishName,tcCategoryName:testItem1.categoryTcName}) }}</h2>
				<!-- <h3>functions</h3> -->
				<p>{{ $t('webDetection.itemRemark',{itemRemark:testItem1.remark,itemEnglishRemark:testItem1.englishRemark,tcItemRemark:testItem1.tcRemark}) }}</p>
				<div v-for="(Item,index4) in testItem1.parentProjectDoList"
					v-show="testItem1.parentProjectDoList.length<2" class="childItem">
					<li v-for="(childItem,index3) in Item.childProjectDoList">
						<!-- {{ childItem.name }} -->
						{{ $t('webDetection.childName',{childName:childItem.name,childEnglishName:childItem.englishName,tcChildName:childItem.tcName}) }}
					</li>
				</div>
				<div v-for="(Item,index4) in testItem1.parentProjectDoList"
					v-show="testItem1.parentProjectDoList.length>1" class="childItem2">
					<div class="parentName">
						<!-- {{ Item.parentName }} -->
						{{ $t('webDetection.parentName',{parentName:Item.parentName,parentEnglishName:Item.parentEnglishName,tcParentName:Item.parentTcName}) }}
					</div>
					<div class="childMain">
						<li v-for="(childItem,index3) in Item.childProjectDoList"
							:class="{w100:Item.childProjectDoList.length==1,w50:lang=='e'}">
							{{ $t('webDetection.childName',{childName:childItem.name,childEnglishName:childItem.englishName,tcChildName:childItem.tcName}) }}
						</li>
						<li v-if="Item.parentName=='其它'" :class="{w50:lang=='e'}">
							<el-button type="primary" @click="toProgramTestingItem(testItem1.categoryCode)">
								{{$t('webDetection.detail')}}>>
							</el-button>
						</li>
					</div>
				</div>
				<!-- <table class="childItem" v-for="(Item,index4) in testItem1.parentProjectDoList" border="0" cellspacing="0" cellpadding="0" >
					<td v-for="(childItem,index3) in Item.childProjectDoList">{{childItem.name}}</td>
				</table> -->
			</div>
		</div>


		<other-footer :type="pageType" />
	</div>
</template>

<script>
	import api from '../request/api.js'
	import otherFooter from '../components/footer.vue'
	export default {
		components: {
			otherFooter
		},
		data() {
			return {
				detectionTypeObject: {},
				pageType: 'other',
				currentIndex: 0,
				childTest: [],
				optionItemList: [],
				childProjectDoList: [],
				categoryId: 0,
				name: '',
				remark: '',
				englishRemark:'',
				englishName:'',
				TCname:'',
				TCremark:'',
				lang:sessionStorage.getItem('lang')
			}
		},
		watch: {
			$route(to, from) {
				this.categoryId = this.$route.query.categoryId
				this.name = this.$route.query.name
				this.remark = this.$route.query.remark
				this.englishName=this.$route.query.englishName
				this.englishRemark=this.$route.query.englishRemark
				this.TCname=this.$route.query.TCname
				this.TCremark=this.$route.query.TCremark
				// this.$nextTick(() => {

				// 	this.$zh_tran(sessionStorage.getItem('lang'))
				// })
			},
			categoryCodes(val) {
				console.log(val)
				// this.childTest.forEach(item=>{
				// 	item.isSelect=false
				// 	if(item.categoryCode==val){
				// 		item.isSelect=true
				// 	}
				// })
			},
			categoryId() {
				this.getDetection(this.categoryId)
				// this.getTestCategoryChildData(this.categoryCode)
				// this.childTest.forEach(item=>{
				// 	item.isSelect=false
				// 	if(item.categoryCode==this.$route.query.categoryCode){
				// 		item.isSelect=true
				// 		this.detectionTypeObject=item
				// 	}
				// })
				// this.$zh_tran(sessionStorage.getItem('lang'))
			}
		},
		created() {
			this.categoryId = this.$route.query.categoryId
			this.name = this.$route.query.name
			this.remark = this.$route.query.remark
			this.englishName=this.$route.query.englishName
			this.englishRemark=this.$route.query.englishRemark
			this.TCname=this.$route.query.TCname
			this.TCremark=this.$route.query.TCremark
			this.getDetection(this.categoryId)
			// this.getTestCategoryChildData(this.$route.query.categoryCode)
			// this.childTest.forEach(item=>{
			// 	item.isSelect=false
			// 	if(item.categoryCode==this.$route.query.categoryCode){
			// 		item.isSelect=true
			// 		this.detectionTypeObject=item
			// 	}
			// })

		},
		mounted() {

		},
		methods: {
			getDetection(categoryId) {
				api.getDetection(categoryId).then(res => {
					this.childTest = res.levelTwoChildAllData
					let child1 = JSON.stringify(res.levelTwoChildAllData)
					let result1 = JSON.parse(child1)
					this.optionItemList = result1
					console.log(this.optionItemList)
					if (categoryId == 30) {
						let child = JSON.stringify(res.levelTwoChildAllData)
						let result = JSON.parse(child)
						result.splice(2, 3)
						result.push({
							categoryName: '区块链安全检测',
							categoryEnglishName:'Blockchain security detection',
							categoryCode: '2'
						})
						this.optionItemList = result
					}
					console.log(this.childTest)
				})
			},
			toHash(categoryCode) {
				document.getElementById(categoryCode).scrollIntoView({
					behavior: "smooth",
					block: "center",
					inline: "nearest"
				});
			},
			// getTestCategoryChildData(categoryCode) {
			// 	api.getTestCategoryChildData(categoryCode).then(res => {
			// 		this.optionItemList = res.parentAuditProjectDoList
			// 		console.log(this.optionItemList)
			// 		this.childProjectDoList=this.optionItemList[this.currentIndex].childProjectDoList
			// 		console.log(this.childProjectDoList)
			// 		this.$zh_tran(sessionStorage.getItem('lang'))
			// 	})
			// },
			// //选择子检测项
			// selectOption(index) {
			// 	this.currentIndex = index
			// 	this.childProjectDoList=this.optionItemList[this.currentIndex].childProjectDoList

			// },
			clickCurrentNav(categoryCode, index) {
				this.$emit('updateCategoryCode', categoryCode)
			},
			toProgramTestingItem(categoryCode) {
				this.$router.push({
					path: '/programTestingItem',
					query: {
						categoryCode
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.bagd {
		background: #000000 !important;
	}

	li {
		list-style: none;
	}

	.webDetection {
		width: 100%;

		.header {
			width: 100%;
			height: 17.5rem; //700
			overflow: hidden;
			background: url(../assets/static/beijing.png);

			>h2 {
				width: max-content;
				margin: 4.9rem auto 0 auto; //196
				font-size: 1.2rem; //48
				font-family: Source Han Sans SC;
				font-weight: bold;
				color: #FFFFFF;
			}

			>p {
				text-align: justify;
				margin: 1.25rem auto 0 auto; //50
				width: 23rem; //680
				font-size: 0.45rem; //18
				font-family: Source Han Sans SC;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 0.75rem; //30
			}

			>button {
				margin: 1.5rem auto 0 auto; //60
				display: block;
				width: 5.4rem; //216
				height: 1.55rem; //62
				background: #FE9901;
				border-radius: 5px;
				font-size: 0.45rem; //18
				font-family: Source Han Sans SC;
				font-weight: 500;
				color: #FFFFFF;
				border: none;
				cursor: pointer;
			}
		}

		.process {
			width: 100%;

			padding: 2rem 1px 2rem;
			box-sizing: border-box;
			background-color: #F8f8f8;
			position: relative;

			.centers {
				justify-content: center;

				.nav {
					margin: 0;
				}
			}

			.testNav {
				position: absolute;
				top: -2rem; //80
				left: 50%; //240
				transform: translateX(-50%);
				width: 36rem; //1440
				display: flex;
				flex-wrap: wrap;

				align-items: center;
				min-height: 4rem; //160
				background-color: #FFFFFF;
				overflow: hidden;
				box-shadow: 0px 0px 18px 0px rgba(184, 196, 204, 0.15);

				.nav {
					display: inline-block;
					text-align: left;
					width: 150px;
					margin: 0 0 0 0.75rem;
					font-size: 0.4rem; //16
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #999999;
					cursor: pointer;
				}

				.reds {
					color: red !important;
				}

				.classActive {
					position: relative;
					color: #2459D9 !important;
				}

				.classActive::after {
					content: '';
					position: absolute;
					background-color: #2459D9;
					bottom: -75%;
					left: 50%;
					width: 50%;
					transform: translateX(-50%);
					height: 3px;
				}
			}

			.testItem {
				width: 36rem; //1440
				padding: 1rem;
				margin: 2rem auto 0;
				box-sizing: border-box;
				box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
				background-color: #FFFFFF;

				>h2 {
					font-size: 0.7rem;
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
				}

				>h3 {
					font-size: 0.5rem;
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #265BDA;
					text-transform: uppercase;
				}

				>p {
					width: 60%;
					font-size: 0.45rem;
					font-family: Source Han Sans SC;
					font-weight: 400;
					text-align: justify;
					color: #666666;
					margin-top: 0.5rem;
					text-indent: 35px;
				}

				.childItem {
					width: max-content;
					max-width: 100%;

					margin-top: 1.25rem; //50
					// >td{
					// 	width: 25%;
					// 	white-space: normal;

					// 	border-left: 1px solid #666666; 
					// 	border-top: 1px solid #666666;
					// }
					>li {

						display: inline-block;
						height: 1.5rem;
						line-height: 1.5rem; //40
						width: 8rem; //360
						margin-left: 0.5rem;
						box-sizing: border-box;
						text-align: left;
						color: #2065FF;
						position: relative;
						&::before{
							content: '';
							position: absolute;
							width: 8px;
							height: 8px;
							border-radius: 50%;
							left: -0.5rem;
							background: #2065FF;
							top: 50%;
							transform: translate(0,-50%);
						}
					}
				}

				.childItem2 {
					width: 34rem; //1440
					display: flex;
					box-sizing: border-box;

					.parentName {
						width: 6.99rem; //360
						display: flex;
						justify-content: center;
						align-items: center;
						border-left: 1px solid #cdcdcd;
						border-top: 1px solid #cdcdcd;
						border-bottom: 1px solid #cdcdcd;
						box-sizing: border-box;
					}

					.childMain {
						width: max-content;
						max-width: 27rem;
						border-left: 1px solid #cdcdcd;
						border-top: 1px solid #cdcdcd;
						box-sizing: border-box;

						>li {
							display: inline-block;
							height: 1.5rem;
							line-height: 1.5rem; //40
							width: 8.98rem; //360
							border-right: 1px solid #cdcdcd;
							border-bottom: 1px solid #cdcdcd;
							box-sizing: border-box;
							text-align: center;
						}
						.w50{
							width: 13.48rem !important;
						}
						.w100 {
							width: 27rem !important;
						}
					}
				}

			}

		}

	}

	.reduce {
		font-size: 0.4rem !important; //16
	}

	/deep/ body {
		overflow: hidden;
	}

	@import url(../utils/mediaCss/webDetectionPhone.less);
</style>
