export default {
	// 允许页面滚动
	AddScroll () {
		document.body.style.overflow = "visible";
	},
	
	// 禁止页面滚动
	
	RemoveScroll () {
		document.body.style.overflow = "hidden";
	}
}
