import axios from 'axios'; // 引入axios


axios.defaults.timeout = 10000;

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
	return new Promise((resolve, reject) => {
		axios.get(url, {
			params: params
		}).then(res => {
			resolve(res.data);
		}).catch(err => {
			reject(err.data)
		})
	});
}

/**
 * post方法，对应post请求
 * 
 */
export function post (url,data){
	return new Promise((resolve,reject)=>{
		axios.post(url,data).then(res=>{
			resolve(res.data);
		}).catch(err=>{
			reject(err.data)
		})
	})
}

