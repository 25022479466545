// 将时间戳转换成日期
	export function formatDate(date) {
		 var d = new Date(date*1000),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
		    year = d.getFullYear(),
		    hour = d.getHours(),
		    minute = d.getMinutes(),
		    seconds = d.getSeconds();
		    month = parseInt(month) < 10?('0'+month):month;
		    day = parseInt(day) < 10?('0'+day):day;
		    hour = parseInt(hour) < 10?('0'+hour):hour;
		    minute = parseInt(minute) < 10?('0'+minute):minute;
		    seconds = parseInt(seconds) < 10?('0'+seconds):seconds;
		    return year+"-"+month+"-"+day;
	}
	export function formatDateChina(date){
		var d = new Date(date*1000),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
		   year = d.getFullYear(),
		   hour = d.getHours(),
		   minute = d.getMinutes(),
		   seconds = d.getSeconds();
		   month = parseInt(month) < 10?('0'+month):month;
		   day = parseInt(day) < 10?('0'+day):day;
		   hour = parseInt(hour) < 10?('0'+hour):hour;
		   minute = parseInt(minute) < 10?('0'+minute):minute;
		   seconds = parseInt(seconds) < 10?('0'+seconds):seconds;
		   return year+"年"+month+"月"+day+"日";
	}
	export function msFormatDateYear(date){
		var d = new Date(date),
		year=d.getFullYear()
		return year+'年'
	}
	export function formatDatedian(date) {
		 var d = new Date(date*1000),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
		    year = d.getFullYear(),
		    hour = d.getHours(),
		    minute = d.getMinutes(),
		    seconds = d.getSeconds();
		    month = parseInt(month) < 10?('0'+month):month;
		    day = parseInt(day) < 10?('0'+day):day;
		    hour = parseInt(hour) < 10?('0'+hour):hour;
		    minute = parseInt(minute) < 10?('0'+minute):minute;
		    seconds = parseInt(seconds) < 10?('0'+seconds):seconds;
		    return year+"."+month+"."+day;
	}
	export function msFormatDate(date) {
		 var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
		    year = d.getFullYear(),
		    hour = d.getHours(),
		    minute = d.getMinutes(),
		    seconds = d.getSeconds();
		    month = parseInt(month) < 10?('0'+month):month;
		    day = parseInt(day) < 10?('0'+day):day;
		    hour = parseInt(hour) < 10?('0'+hour):hour;
		    minute = parseInt(minute) < 10?('0'+minute):minute;
		    seconds = parseInt(seconds) < 10?('0'+seconds):seconds;
		    return year+"年"+month+"月"+day+'日';
	}
