module.exports={
	routerList:{
		title1:'Home',
		title2:'Service',
		title3:'Certificate',
		title4:'Information',
		title5:'News',
		title6:'About',
		title2child1:'',
		title2child2:'',
		title2child3:'',
		title2child4:'',
		title4child1:'Project',
		title4child2:'Company',
		title5child1:'News',
		title5child2:'Hotspot',
		title5child3:'Industry',
		title5child4:'Expert',
		title5child5:'Vulnerability',
		title6child1:'Center',
		title6child2:'Contact Us',
		childName:'{childEnglishName}'
	},
	index:{
		headerText:'Oriental Blockchain Security Technology Testing Center',
		headerText2:'Fairness Impartiality Authority Openness',
		fuwuTitle:'Service Type',
		serviceTypeTitle1:'Software Product Evaluation',
		serviceTypeTitle2:'Blockchain Product Evaluation',
		serviceTypeTitle3:'Blockchain Security Assessment',
		serviceTypeTitle4:'Blockchain Industry Security Monitoring',
		
		serviceTypeContent1:'According to the technical requirements specified in the purchase contract, construction scheme or requirements specification of the tested system, test the characteristics of the tested system specified in the test requirements. The test types support entrusted test, acceptance test, identification test, cost evaluation, etc., and a nationally recognized CMA third-party test report can be issued. Product types supported: mobile APP, desktop software, WEB website, small program, WeChat official account, database operating system and other supporting types of evaluation: document set, functionality, performance, compatibility, reliability, ease of use, maintainability, portability, security testing (source code security audit, black box penetration test, host security scan).',
		serviceTypeContent2:'Focusing on the network security, underlying technology, baas platform and the functions, performance and security of upper applications of blockchain, it provides detection services for blockchain projects for the industry, and can issue third-party detection certificates with national CMA certification. At present, it has completed the project testing of many domestic mainstream blockchains such as ant blockchain baas platform, Hangzhou fun chain blockchain platform, CLP cloud blockchain, complex America blockchain platform, Tencent blockchain platform and Inspur Group quality chain, and issued testing certificates. Supported product types: baas platform, public chain / alliance chain, smart contract, wallet, etc. supported evaluation types: functional detection, performance detection, security detection (source code security audit, black box penetration test, node host security scanning).',
		serviceTypeContent3:"Security assessment: in the process of attracting investment or investment, it is difficult to identify the authenticity of blockchain enterprises or projects, and there may be major security risks. We conduct a comprehensive risk assessment from multiple dimensions of the enterprise and the project, and issue a risk assessment report to find out the risk of the project in advance. At present, safety assessment has been carried out for hundreds of enterprises and projects, which has been highly recognized by government leaders. Security inspection: cooperate with relevant departments to conduct security inspection on the blockchain project and issue the inspection result report. On the one hand, it can help enterprises find security vulnerabilities and prevent economic losses. On the other hand, it can understand the real situation of the project, including function points and business models, so as to standardize and promote the healthy development of blockchain technical services, Provide effective legal basis for the provision, use and management of blockchain information services.",
		serviceTypeContent4:'Without causing any damage to the system, conduct a complete penetration test on the system by simulating hacker attack, including but not limited to host detection, port detection and scanning, vulnerability scanning, etc., and find its existing security defects.',
		processTitle:'Detection Process',
		process1:'Demand Confirmation',
		process2:'Scheme Confirmation',
		process3:'Quotation Confirmation',
		process4:'Contract Signing',
		process5:'Technical Detection',
		process6:'Report Issuance',
		process7:'Certificate Issuance',
		certificateTitle:'Certificate Query',
		seeMore:'See More',
		placeholder:'Please Enter Keyword',
		daimasj:'Code Audit',
		yewufw:'Scope Of Business',
		daimaContent:'Code audit refers to the technical means for security code evaluators to find potential security vulnerabilities and hidden dangers by reading the source code of the application system as much as possible. The technical means of code audit can make up for the loopholes and security risks that can not be fully covered by black box penetration test. It is a method with the highest reliability and security to repair loopholes. At present, the blockchain code audit cloud platform provides audit types such as digital currency and smart contract.',
		yewuContent:'Focusing on network security, blockchain underlying technology and upper application security, blockchain security detection is carried out for the industry. The project detection includes 7 aspects: public chain alliance chain security detection, smart contract security detection, wallet security detection, APP security detection, web site security detection, system penetration and daily monitoring.',
		newsTitle:'News Announcement',
		IndustryTitle:'Industry Dynamics',
		PartnersTitle:'Partners',
		contactUs:'Contact Us',
		workHours:'Working Hours: (9:00-17:00 From Monday To Friday)',
		dizhi:'Contact Address: Xingsha Blockchain Industrial Park, No. 260, Tianhua North Road, Changsha, Hunan',
		phone:'Phone',
		email:'Email',
		guanfang:'[official statement] as a third-party independent security organization in the blockchain ecology, it focuses on the ecological security of the blockchain and does not endorse or platform any project. All endorsement or platform flag items are not officially authorized, and we will reserve the right to be investigated by law',
		banquan:'Copyright Of Hunan Dongfang Blockchain Security Technology Testing Center',
		lianxiEmail:'Contact Email：info@eastsec.org.cn',
		zuixingongao:'Latest Announcement',
		weixin2wm:'WeChat Official Account',
		beian:'IPC Licensing at Hunan (Xiang) No.2021014390-1'
	},
	webDetection:{
		name:'{englishName}',
		remark:'{englishRemark}',
		categoryName:'{categoryEnglishName}',
		itemRemark:'{itemEnglishRemark}',
		childName:'{childEnglishName}',
		parentName:'{parentEnglishName}',
		detail:'Detail'
	},
	certificateQuery:{
		title:'Test Certificate Query',
		title2:'Latest Audit Presentation',
		showZS:'View Certificate'
	},
	certificateView:{
		weizhi:'Your Location',
		zscx:'Certificate Query',
		ckzs:'View Certificate',
		result:'Query Results',
		guanwang:'Official Website'
	},
	tankInformation:{
		title:'{englishtitle}',
		projectTitle:'Project',
		companyTitle:'Company',
		placeholder:'Please Enter Keyword Query',
		tableName:'Name',
		tableAbbreviation:'RecordNo',
		tableStatus:'Status',
		tableOnlineTime:'OnlineTime',
		companyName:'CompanyName',
		legalPerson:'LegalPerson',
		Time:'Time',
		registeredCapital:'RegisteredCapital',
		tableAddress:'Address',
		total:'Total'
	},
	tankInformationItem:{
		onlineTime:'Online Time',
		Status:'Status',
		projectDetails:'Project Details',
		icpNo:'ICP No',
		website:'Project Website',
		projectDescription:'Project Description',
		belongTo:'Affiliated Enterprise',
		companyName:'Enterprise Name',
		legalPerson:'Enterprise Legal Person',
		address:'Registered Office',
		capital:'Registered Capital',
		companyStatus:'Enterprise Status',
		companyWebsite:'Company Website'
	},
	tankInformationItemCompany:{
		clTime:'Establishment Time',
		Status:'Status',
		NatureBusiness:'Nature Of Business',
		BusinessDetails:'Business Details',
		legalPerson:'Enterprise Legal Person',
		address:'Registered Office',
		capital:'Registered Capital',
		companyStatus:'Enterprise Status',
		companyWebsite:'Company Website'
	},
	newsBulletin:{
		title:'{enTitle}',
		nodata:'NO DATA',
		newsDetail:'NEWS DETAIL',
		ljgd:'More'
	},
	test:{
		title:'CENTER INTRODUCTION',
		background:'Establishment Background',
		backgroundRemark:"In view of the increasingly prominent security problems exposed in the blockchain field, the national Internet Emergency Center, as the core coordination organization of China's network security emergency system, jointly initiated and established the blockchain security technology testing center with the Management Committee of Changsha Economic Development Zone in October 2018, and officially registered as a private non enterprise unit in Hunan Provincial Department of civil affairs in March 2020 (Social Service Organization) and its title: Hunan Dongfang blockchain security technology testing center (hereinafter referred to as Dongfang blockchain security and testing center). The business guidance unit is the office of network security and Information Technology Committee of Hunan Provincial CPC Committee.",
		centerIntroductionTitle:'Center Introduction',
		centerIntroduction1:'Dongfang chain security is the first third-party testing institution in the blockchain field to obtain the CMA certification and accreditation qualification for blockchain security evaluation. On the one hand, its main responsibilities are to carry out prevention, discovery, early warning and coordinated disposal of security events, so as to ensure the security of the national public Internet and the safe operation of the basic information network and important information systems, On the other hand, it provides market-oriented services such as software evaluation, blockchain product security detection, blockchain enterprise and project security evaluation.',
		centerIntroduction2:'Since its establishment, Dongfang Lianan has successively completed the project testing of leading enterprises in the industry such as ant blockchain platform, Hangzhou fun chain blockchain platform and Inspur quality chain, which has achieved good social impact and been recognized by the market. Six Hunan blockchain security detection technical standards jointly drafted by Oriental chain security have been officially released and implemented, and are being promoted to become national standards.',
		centerIntroduction3:'The development of Dongfang Lianan has been highly concerned and strongly supported by governments at all levels. Provincial and municipal leaders such as Mao Weiming, governor of Hunan Province, Huang Lanxiang, head of the United Front Work Department of the CPC Hunan Provincial Committee, Xie Jianhui, executive vice governor, Wu Guiying, Secretary of the CPC Changsha Municipal Committee, Zheng Jianxin, mayor of Changsha, Hu Henghua, former Secretary of the CPC Changsha Municipal Committee, and Hu Zhongxiong, mayor of Changsha, have visited and guided it for many times, Give high praise and high expectations.',
		
	},
	programTestingItem:{
		znhy:'Smart Contract Detection',
		gllm:'Public Chain / Alliance Chain Detection',
		qb:'Wallet Detection',
		jclm:'Detection Column',
		tj:'Recommend',
		zqjc:'Enhanced Detection',
		qmjc:'Comprehensive Inspection',
		jcjc:'Basic Detection',
		item1:'Item Enhancement Detection',
		item2:'Item Comprehensive Test',
		item3:'Item Basic Detection'
	}
}