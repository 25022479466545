<template>
  <div class="tankInformationItem">
    <header>
      <h2>{{ routeData.name }}</h2>
      <div>
        <p>{{$t('tankInformationItemCompany.clTime')}}:{{ routeData.fromTime }}</p>
        <p>{{$t('tankInformationItemCompany.Status')}}：{{ routeData.regStatus }}</p>
      </div>
    </header>
    <main>
      <div
        v-if="routeData.businessScope"
        class="projectDescriptionTitle"
      >
        <img src="../assets/static/xiangmu.png">
        <h3>{{$t('tankInformationItemCompany.NatureBusiness')}}</h3>
      </div>
      <p class="projectDescription">
        {{ routeData.businessScope||'' }}
      </p>
      <div class="affiliatedEnterpriseTitle">
        <img src="../assets/static/qiye.png">
        <h3>{{$t('tankInformationItemCompany.BusinessDetails')}}</h3>
      </div>
      <p v-if="routeData.legalPersonName">
        {{$t('tankInformationItemCompany.legalPerson')}}：{{ routeData.legalPersonName }}
      </p>
      <p>{{$t('tankInformationItemCompany.address')}}：{{ routeData.regLocation }}</p>
      <p v-if="routeData.regCapital">
        {{$t('tankInformationItemCompany.capital')}}：{{ routeData.regCapital }}
      </p>
      <p>{{$t('tankInformationItemCompany.companyStatus')}}：{{ routeData.regStatus }}</p>
      <p v-if="routeData.websiteList">
        {{$t('tankInformationItemCompany.companyWebsite')}}：{{ routeData.websiteList }}
      </p>
    </main>
    <other-footer :type="pageType" />
  </div>
</template>

<script>
	import otherFooter from '../components/footer.vue'
	import {msFormatDate} from '../utils/formatDate.js'
	import api from '../request/api.js'
	export default{
		components: {
			otherFooter
		},
		data(){
			return{
				pageType:'other',
				routeData:{}
			}
		},
		created(){
			this.getCompanyItem(this.$route.params.ref)
			// this.routeData.onLineTime?this.routeData.onLineTime=this.formatDateChina(this.routeData.onLineTime):''
			// this.routeData.createTime?this.routeData.createTime=this.formatDateChina(this.routeData.createTime):''
		
		},
		methods: {
			formatDateChina(date){
				let newDate=msFormatDate(date)
				return newDate
			},
			getCompanyItem(id){
				api.getCompanyItem(id).then(res=>{
					this.routeData=res.data
					this.routeData.fromTime=this.routeData.fromTime?this.formatDateChina(this.routeData.fromTime):''
					
				})
			}
		},
	}
</script>

<style lang="less" scoped>
	.tankInformationItem{
		width: 100%;
		overflow: hidden;
		header{
			width: 100%;
			height: 13.75rem;//550
			overflow: hidden;
			background: url(../assets/static/zkbanner.png);
			>h2{
				font-size: 1.2rem;//48
				font-family: Source Han Sans SC;
				font-weight: bold;
				color: #FFFFFF;
				width: max-content;
				margin: 5.125rem auto 0;//205
			}
			>div{
				display: flex;
				justify-content: center;
				margin: 0.9rem auto 0;//36
				>p{
					font-size: 0.6rem;//24
					margin: 0 0.5rem;//20
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #FFFFFF;
					height: max-content;
				}
			}
		}
		main{
			width: 100%;
			overflow: hidden;
			padding-bottom: 2rem;//80
			background-color: #FFFFFF;
			.projectDescriptionTitle,.affiliatedEnterpriseTitle{
				display: flex;
				align-items: center;
				margin: 1.75rem 0 0 12.5%;//70
				>h3{
					margin-left: 0.5rem;//20
					font-size: 0.6rem;//24
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
				}
			}
			.projectDescription{
				width: 36rem;//1440
				margin: 22px auto 0;
				font-size: 0.45rem;//18
				font-family: Source Han Sans SC;
				font-weight: 400;
				color: #666666;
				line-height: 0.85rem;//34
				@media (max-width:1024px){
					width: 75%;
				}
			}
			p:not(:nth-of-type(1)){
				font-size: 0.45rem;//18
				font-family: Source Han Sans SC;
				font-weight: 400;
				color: #666666;
				line-height: 1.25rem;//50
				margin-top: 0.65rem;//26
				margin-left: 12.5%;
			}
		}
		
	}
</style>
