<template>
  <div class="sousuo">
    <img src="../assets/static/sousuo.png">
    <input
      v-model="searchText"
      type="text"
      :placeholder="$t('tankInformation.placeholder')"
      @keyup="keyupSearch"
    >
  </div>
</template>

<script>
	export default{
		data(){
			return{
				searchText:''
			}
		},
		methods:{
			keyupSearch(e){
				if(e.keyCode===13){
					this.$emit('searchKeyup',this.searchText)
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.sousuo {
		max-width: 20rem;//800
		height: 1.5rem;//60
		background: #FFFFFF;
		border: 1px solid #CDCDCD;
		border-radius: 5px;
		position: relative;
		@media (max-width:1024px){
			width: 80%;
		}
		>img {
			position: absolute;
			width: 0.5rem;//20
			height: 0.5rem;//20
			top: 0.5rem;//20
			left: 0.75rem;//30
			z-index: 3;
		}
	
		>input {
			position: absolute;
			width: 15rem;//600
			height: 1.5rem;//60
			top: 0;
			left: 0;
			font-size: 0.45rem;//18
			font-family: Source Han Sans SC;
			font-weight: 400;
			color: #999999;
			text-align: left;
			outline: none;
			padding-left: 2.5rem;//100
			border: none;
			@media (max-width:1024px){
				width: 80%;
			}
		}
	}
</style>
