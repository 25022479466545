<template>
  <div class="index">
    <swiper
      ref="mySwiper"
      class="myswiper"
      :options="swiperOption"
      :class="[windowWidth<=1024?'':'swiper-no-swiping']"
    >
      <swiper-slide class="myswiperItem">
        <div class="indexHeader">
          <div
            class="mainTitle"
            :class="[activeIndex==0?&quot;animation&quot;:&quot;&quot;]"
          >
			<img src="../assets/static/index_logos.png" class="indexLogo">
            <h6 class="english" :class="{fontsz:lang=='e'}">
              Eastern Blockchain Security Technology Testing Center
            </h6>
            <p class="chinese" :class="{chineseEn:sessionStorage=='e',disno:lang=='e'}">
              {{ $t('index.headerText') }}
            </p>
            <p class="gp" :class="{margins:lang=='e'}">
              {{ $t('index.headerText2') }}
            </p>
          </div>
          <div
            class="xiayiye"
            :class="[activeIndex==0?&quot;animation&quot;:&quot;&quot;]"
            @click="nextPage"
          >
            <img src="../assets/static/xiaiye.png">
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="myswiperItem">
        <div class="indexfuwu">
          <p :class="[activeIndex==1?&quot;animation&quot;:&quot;&quot;]">
            {{ $t('index.fuwuTitle') }}
          </p>
          <p :class="[activeIndex==1?&quot;animation&quot;:&quot;&quot;,lang=='e'?'disno':'']">
            SERVICE
          </p>
          <div class="serviceType">
            <div
              v-for="(item,index) in serviceTypeList"
              :key="index"
              :class="[activeIndex==1?&quot;animation&quot;:&quot;&quot;]"
              class="serviceTypeItem"
              @click="toTest(item.categoryId,item.categoryCode,item.title,item.content,item.enTitle,item.enContent,item.tcTitle,item.tcContent)"
            >
              <div class="zcContent">
                <div
                  :class="[&quot;serviceBg&quot;]"
                  :style="{backgroundImage:'url('+(item.isSelect?item.selectIcon:item.iconsrc)+')'}"
                />
                <div class="content">
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.content }}</p>
                </div>
              </div>
              <div class="hoverContent">
                <p>{{ item.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="myswiperItem">
        <div class="detectionProcess">
          <div class="detectionMain">
            <div class="detectionTitle">
              <h2 :class="[activeIndex==2?&quot;animation&quot;:&quot;&quot;]">
                {{ $t('index.processTitle') }}
              </h2>
              <h3 :class="[activeIndex==2?&quot;animation&quot;:&quot;&quot;,lang=='e'?'disno':'']">
                process
              </h3>
            </div>
            <div
              class="processItem1"
              :class="[activeIndex==2?&quot;animation&quot;:&quot;&quot;]"
            >
              <h4>1</h4>
              <img src="../assets/static/tj.png">
              <h2 :class="{enh2:lang=='e'}">{{ $t('index.process1') }}</h2>
            </div>
            <div
              class="processItem2"
              :class="[activeIndex==2?&quot;animation&quot;:&quot;&quot;]"
            >
              <h4>3</h4>
              <img src="../assets/static/bj(2).png">
              <h2 :class="{enh2:lang=='e'}">{{ $t('index.process3') }}</h2>
            </div>
            <div
              class="processItem3"
              :class="[activeIndex==2?&quot;animation&quot;:&quot;&quot;]"
            >
              <h4>5</h4>
              <img src="../assets/static/js.png">
              <h2 :class="{enh2:lang=='e'}">{{ $t('index.process5') }}</h2>
            </div>
            <div
              class="processItem4"
              :class="[activeIndex==2?&quot;animation&quot;:&quot;&quot;]"
            >
              <h4>2</h4>
              <img src="../assets/static/fa.png">
              <h2 :class="{enh2:lang=='e'}">{{ $t('index.process2') }}</h2>
            </div>
            <div
              class="processItem5"
              :class="[activeIndex==2?&quot;animation&quot;:&quot;&quot;]"
            >
              <h4>4</h4>
              <img src="../assets/static/ht.png">
              <h2 :class="{enh2:lang=='e'}">{{ $t('index.process4') }}</h2>
            </div>
            <div
              class="processItem6"
              :class="[activeIndex==2?&quot;animation&quot;:&quot;&quot;]"
            >
              <h4>6</h4>
              <img src="../assets/static/bg.png">
              <h2 :class="{enh2:lang=='e'}">{{ $t('index.process6') }}</h2>
            </div>
            <div
              class="processItem7"
              :class="[activeIndex==2?&quot;animation&quot;:&quot;&quot;]"
            >
              <h4>7</h4>
              <img src="../assets/static/zs.png">
              <h2 :class="{enh2:lang=='e'}">{{ $t('index.process7') }}</h2>
            </div>
            <p
              class="bagimg"
              :class="[activeIndex==2?&quot;animation&quot;:&quot;&quot;]"
            />
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="myswiperItem">
        <div class="Certificate">
          <div class="header">
            <div
              class="titles"
              :class="[activeIndex==3?&quot;animation&quot;:&quot;&quot;]"
            >
              <h3>{{ $t('index.certificateTitle') }}</h3>
              <h2 :class="[lang=='e'?'disno':'']">certificate</h2>
            </div>
            <div
              class="genduo"
              :class="[activeIndex==3?&quot;animation&quot;:&quot;&quot;]"
            >
              <a @click="toCertificate">{{ $t('index.seeMore') }}</a>
            </div>
          </div>
          <div class="sousuos">
            <div
              class="sousuo"
              :class="[activeIndex==3?&quot;animation&quot;:&quot;&quot;]"
            >
              <span class="iconfont icon-sousuo" />
              <!--							<input type="text" placeholder="请输入关键词查询">-->
              <el-autocomplete
                v-model="searchkey"
                :trigger-on-focus="false"
                :fetch-suggestions="querySearchAsync"
                :placeholder="$t('index.placeholder')"
                @select="handleSelect"
              />
            </div>
            <!-- <button class="submit" :class='[activeIndex==2?"animation":""]'>确定</button> -->
          </div>
          <code-andservice
            class="op"
            :class="[activeIndex==3?&quot;animation&quot;:&quot;&quot;]"
          />
          <div
            class="genduo"
            :class="[activeIndex==3?&quot;animation&quot;:&quot;&quot;]"
          >
            <a @click="toCertificate">{{ $t('index.seeMore') }}</a>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="myswiperItem">
        <div class="newsBulletin">
          <!-- <div class="newsTop">
						<div class="newsTopLeft">
							<h3 :class='[activeIndex==4?"animation":""]'>{{$t('index.newsTitle')}}</h3>
							<div class="leftMain" >
								<img @click="toNewsBulletinItem(newsMainList1[0].articleId)" :src="httpstr+newsMainList1[0].homeImage" :class='[activeIndex==4?"animation":""]'/>
								<div class="leftMainRight">
									<h4 :class='[activeIndex==4?"animation":""]' @click="toNewsBulletinItem(newsMainList1[0].articleId)">{{newsMainList1[0].title}}</h4>
									<p :class='[activeIndex==4?"animation":""]'>{{newsMainList1[0].digest}}</p>
									<span :class='[activeIndex==4?"animation":""]'>{{api.formatDate(newsMainList1[0].gmtCreate)}}</span>
									<a @click="toNewsBulletin('07310220181206090100000098')" :class='[activeIndex==4?"animation":""]'>{{$t('index.seeMore')}}</a>
								</div>
							</div>
						</div>
						<div class="newsTopRight">
							<p :class='[activeIndex==4?"animation":""]' @click="toNewsBulletinItem(newsItem1.articleId)" v-for="(newsItem1,newsIndex1) in newsMainList1">
								{{newsItem1.title}}
							</p>
						</div>
					</div>
					<div class="newsBottom">
						<div class="newsBottomLeft">
							<div :class='[activeIndex==4?"animation":""]' class="newsBottomLeftHeader">
								<h5 :class='[activeIndex==4?"animation":""]'>{{$t('index.zuixingongao')}}</h5>
								<a :class='[activeIndex==4?"animation":""]' @click="toNewsBulletin('07310220181206090100000098')">{{$t('index.seeMore')}}</a>
							</div>
							<div :class='[activeIndex==4?"animation":""]' class="bottomLeftMain" v-for="(newsItem2,newsIndex2) in newsMainList2">
								<p @click="toNewsBulletinItem(newsItem2.articleId)">{{newsItem2.title}}</p>
								<p>{{api.formatDate(newsItem2.gmtCreate)}}</p>
							</div>
						</div>
						<div class="newsBottomRight">
							<div :class='[activeIndex==4?"animation":""]' class="xian"></div>
							<div :class='[activeIndex==4?"animation":""]' class="bottomRightMain" v-for="(newsItem3,newsIndex3) in newsMainList3">
								<p @click="toNewsBulletinItem(newsItem3.articleId)">{{newsItem3.title}}</p>
								<p>{{api.formatDate(newsItem3.gmtCreate)}}</p>
							</div>
						</div>
					</div> -->
					
          <div
            class="industryTitles"
            :class="[activeIndex==4?&quot;animation&quot;:&quot;&quot;]"
          >
            <h4 class="chineseTitle">
              {{ $t('index.newsTitle') }}
            </h4>
            <h3 class="englishTitle" :class="[lang=='e'?'disno':'']">
              news
            </h3>
          </div>
          <div
            class="industryMain"
            :class="[activeIndex==4?&quot;animation&quot;:&quot;&quot;]"
          >
            <div class="industryLeft">
              <img
                :src="httpstr+newsMainList2[0].homeImage"
                @click="toNewsBulletinItem(newsMainList2[0].articleId)"
              >
              <span>{{ newsMainList2[0].gmtCreate }}</span>
              <h4 @click="toNewsBulletinItem(newsMainList2[0].articleId)">
                {{ newsMainList2[0].title }}
              </h4>
              <p>{{ newsMainList2[0].digest }}</p>
            </div>
            <div class="industryRight">
              <div
                v-for="(hangyeItem,index5) in newsMainList2"
                class="industryRightItem"
              >
                <img
                  :src="httpstr+hangyeItem.homeImage"
                  @click.stop="toNewsBulletinItem(hangyeItem.articleId)"
                >
                <div class="industryRightContent">
                  <span>{{ hangyeItem.gmtCreate }}</span>
                  <h5 @click.stop="toNewsBulletinItem(hangyeItem.articleId)">
                    {{ hangyeItem.title }}
                  </h5>
                  <p>{{ hangyeItem.digest }}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="industryGenduo"
            :class="[activeIndex==4?&quot;animation&quot;:&quot;&quot;]"
          >
            <a @click="toNewsBulletinhangye('07310220181206090100000098','新闻公告','NEWS')">
              {{ $t('index.seeMore') }}
            </a>
          </div>
					
					
					
					
					
          <!-- <div class="newsHeader">
						<div class="newsTitles" :class='[activeIndex==4?"animation":""]'>
							<h4>{{$t('index.newsTitle')}}</h4>
							<h3>news bulletin</h3>
						</div>
						<div class="newsGenduo" :class='[activeIndex==4?"animation":""]'>
							<a @click="toNewsBulletin('07310220181206090100000098')">{{$t('index.seeMore')}}</a>
						</div>
					</div> -->
          <!-- <div class="newsMain">
						<div class="newsItem" v-for="(newsItem,index) in newsMainList" :key="index"
							@mouseenter.stop="selectNews(index)" @mouseleave="leaveNews(index)"
							:class='[activeIndex==4?"animation":""]'>
							<div class="picAndTime">
								<img @click="toNewsBulletinItem(newsItem.articleId)" :src="httpstr+newsItem.homeImage" onerror="../assets/static/tu1.png" />
								<span>{{api.formatDate(newsItem.gmtModify).substring(0,7)}}</span>
								<!--.substring(0,7)-->
          <!-- 	<div :class="['day',newsItem.isSelect?'dayActive':'']">
									{{api.formatDate(newsItem.gmtModify).substring(8,11)}}</div>
								
							</div> --> 
          <!-- 	<h5 @click="toNewsBulletinItem(newsItem.articleId)" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;"
								:class="[newsItem.isSelect?'titleActive':'']">{{newsItem.title}}</h5>
							<p
								style="display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;word-break: break-all;text-overflow: ellipsis;-webkit-line-clamp: 2;">
								{{newsItem.digest}}</p>
							<div @click="toNewsBulletinItem(newsItem.articleId)" class="ljgenduo"
								v-if="newsItem.isSelect">
								<img src="../assets/static/gd.png">
								<p>{{$t('index.seeMore')}}</p>
							</div>
						</div>
					</div> -->
          <!-- <div class="newsGenduoPhone" :class='[activeIndex==4?"animation":""]'>
						<a @click="toNewsBulletin('07310220181206090100000098')">{{$t('index.seeMore')}}</a>
					</div> -->
          <!--					<div class="upAndDown" :class='[activeIndex==3?"animation":""]'>-->
          <!--						<div class="up">-->
          <!--							<img src="../assets/static/xyy.png" />-->
          <!--						</div>-->
          <!--						<div class="down">-->
          <!--							<img src="../assets/static/xiaiye.png" />-->
          <!--						</div>-->
          <!--					</div>-->
          <!--					<div class="progress" :class='[activeIndex==3?"animation":""]'>-->
          <!--						<div class="progressItem1"></div>-->
          <!--						<div class="progressItem2"></div>-->
          <!--					</div>-->
          <!--					<div class="progressNum" :class='[activeIndex==3?"animation":""]'>-->
          <!--						<p>01</p>-->
          <!--						<p>02</p>-->
          <!--					</div>-->
        </div>
      </swiper-slide>
      <swiper-slide class="myswiperItem">
        <div class="industryAction">
          <div
            class="industryTitles"
            :class="[activeIndex==5?&quot;animation&quot;:&quot;&quot;]"
          >
            <h4 class="chineseTitle">
              {{ $t('index.IndustryTitle') }}
            </h4>
            <h3 class="englishTitle" :class="[lang=='e'?'disno':'']">
              industry
            </h3>
          </div>
          <div
            class="industryMain"
            :class="[activeIndex==5?&quot;animation&quot;:&quot;&quot;]"
          >
            <div class="industryLeft">
              <img
                :src="httpstr+newsContentMainList[0].homeImage"
                @click="toNewsBulletinItem(newsContentMainList[0].articleId)"
              >
              <span>{{ newsContentMainList[0].gmtCreate }}</span>
              <h4 @click="toNewsBulletinItem(newsContentMainList[0].articleId)">
                {{ newsContentMainList[0].title }}
              </h4>
              <p>{{ newsContentMainList[0].digest }}</p>
            </div>
            <div class="industryRight">
              <div
                v-for="(hangyeItem,index5) in newsContentMainList"
                class="industryRightItem"
              >
                <img
                  :src="httpstr+hangyeItem.homeImage"
                  @click.stop="toNewsBulletinItem(hangyeItem.articleId)"
                >
                <div class="industryRightContent">
                  <span>{{ hangyeItem.gmtCreate }}</span>
                  <h5 @click.stop="toNewsBulletinItem(hangyeItem.articleId)">
                    {{ hangyeItem.title }}
                  </h5>
                  <p>{{ hangyeItem.digest }}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="industryGenduo"
            :class="[activeIndex==5?&quot;animation&quot;:&quot;&quot;]"
          >
            <a @click="toNewsBulletinhangye('07310220181206090100000097','行业报告','INDUSTRY')">
              {{ $t('index.seeMore') }}
            </a>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="myswiperItem">
        <div class="partners">
          <div
            class="partnersTitles"
            :class="[activeIndex==6?&quot;animation&quot;:&quot;&quot;]"
          >
            <h4>{{ $t('index.PartnersTitle') }}</h4>
            <h3 :class="[lang=='e'?'disno':'']">partners</h3>
          </div>
          <div
            class="partnersMain"
            :class="[activeIndex==6?&quot;animation&quot;:&quot;&quot;]"
          >
            <img
              v-for="(img,index1) in partnersImageList"
              :key="index1"
              :src="require('../assets/static/'+img.imgSrc+'.png')"
            >
          </div>
          <!-- <div class="partnersUp" :class='[activeIndex==5?"animation":""]'>
						<img src="../assets/static/xiaiye@2x.png" />
					</div>
					<div class="partnersDown" :class='[activeIndex==5?"animation":""]'>
						<img src="../assets/static/xiaiye@2x.png" />
					</div> -->
        </div>
      </swiper-slide>
      <swiper-slide class="myswiperItem">
        <div class="dizhi">
          <!--					<div class="ditu"></div>-->
          <!-- <div class="dizhiTitlesPhone">
						<h4>{{$t('index.contactUs')}}</h4>
						<h3>Contact us</h3>
					</div> -->
          <div class="map_index">
            <!--						<img style="width: 100%;height: 100%;" src="../assets/static/index_map.png">-->

            <baidu-map
              class="map"
              :center="center"
              :zoom="13"
              :scroll-wheel-zoom="true"
              :zoom-control="true"
              @ready="handler"
            >
              <bm-marker-clusterer :average-center="true">
                <bm-marker
                  :position="center"
                  @click="showDituxx"
                />
              </bm-marker-clusterer>
              <bm-info-window
                :position="center"
                :title="stationList.name"
                :show="dituShow"
                @close="closeDituxx"
              >
                <p><span class="left">地点：</span><span class="right">{{ stationList.address }}</span></p>
                <p><span class="left">名称：</span><span class="right">{{ stationList.name }}</span></p>
                <p><span class="left">描述：</span><span class="right">{{ stationList.remark }}</span></p>
                <p><span class="left">电话：</span><span class="right">{{ stationList.telephone }}</span></p>
                <p><span class="left">工作时间：</span><span class="right">{{ stationList.workTime }}</span></p>
              </bm-info-window>
              <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" />
            </baidu-map>
          </div>
          <div class="indexFooter">
            <div class="indexFooterTop">
              <div class="indexFooterTopLeft">
                <div class="topLeftLeft">
                  <h4>{{ $t('index.contactUs') }}</h4>
                  <p>{{ $t('index.email') }}：&nbsp;info@eastsec.org.cn</p>
                  <span :class="[lang=='e'?'w500s':'']">{{ $t('index.dizhi') }}</span>
                </div>
                <div class="topLeftRight">
                  <h4>
                    {{ $t('index.phone') }}
                  </h4>
                  <p>0731-86869068</p>
                  <span>{{ $t('index.workHours') }}</span>
                </div>
              </div>
              <div class="indexFooterTopRight">
                <img src="../assets/static/2wm.jpg">
                <p>{{ $t('index.weixin2wm') }}</p>
              </div>
            </div>
            <div class="indexFooterBottom">
              <div class="bot_cell1">
                <h2 :class="{movedAnima:true}">
                  {{ $t('index.guanfang') }}
                </h2>
              </div>
              <div class="bot_cell2 disflx aicen">
                <p>{{ $t('index.banquan') }}</p>
                <p @click="toBeian">
                  {{$t('index.beian')}}
                </p>
              </div>
            </div>
            <!-- <div class="index_contactus disflx aicen">
						<div class="dizhiTitles">
							<h4>{{$t('index.contactUs')}}</h4>
							<h3>Contact us</h3>
						</div>
						<div class="women">
							<div class="dizhiText">
								<img src="../assets/static/dizhi.png" />
								<p>{{$t('index.dizhi')}}</p>
							</div>
							<div class="phoneAndEmail" style="display: flex;">
								<div class="phone">
									
									<p>{{$t('index.phone')}}:&nbsp;0731-86869068</p>
								</div>
								<div class="email">
									
									<p>{{$t('index.email')}}：&nbsp;info@eastsec.org.cn</p>
								</div>
							</div>
						</div>
					</div>
					<div class="index_bott disflx">
						<div class="bot_cell1">
							<h2>{{$t('index.guanfang')}}</h2></div>
						<div class="bot_cell2 disflx aicen">
							<p>{{$t('index.banquan')}}</p>
							<p @click="toBeian">湘ICP备2021014390号-1</p>
						-->
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="swiper-pagination dd" />
  </div>
</template>

<script>
	import indexFooter from '../components/footer.vue'
	import scroll from '../utils/scroll.js'
	import codeAndservice from '../components/codeAndService.vue'
	import api from '../request/api.js'
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'
	import {
		formatDatedian
	} from '../utils/formatDate.js'
	import BaiduMap from "vue-baidu-map";
	import BmNavigation from 'vue-baidu-map/components/controls/Navigation'
	let vm = null;
	let thatSwiper = null;
	export default {
		
		components: {
			indexFooter,
			Swiper,
			SwiperSlide,
			codeAndservice
		},
		data() {
			return {
				windowWidth:window.document.documentElement.clientWidth,
				center: {
					lng: '',
					lat: ''
				},
				lang:sessionStorage.getItem('lang')||'s',
				httpstr: 'https://www.eastsec.org.cn/api',
				searchkey: '',
				api: api,
				swiperOption: {
					init: true,
					pagination: {
						el: '.swiper-pagination',
						type: 'bullets',
						progressbarOpposite: true,
						bulletElement: 'span',
						clickable: true
					},
					observer: true,
					mousewheel: true,
					direction: "vertical",
					autoHeight: true,
					on: {
						init: function() {
							thatSwiper = this
							this.activeIndex=vm.$route.query.active||0
							this.slideTo(this.activeIndex)
							vm.$emit('changeActiveStep', this.activeIndex)
						},
						slideChangeTransitionEnd: function() {
							//切换结束时，告诉我现在是第几个slide
							vm.$emit('changeActiveStep', this.activeIndex)
							vm.activeIndex = this.activeIndex
							console.log(thatSwiper.activeIndex)
						}
					}
				},
				pageType: 'index',
				activeIndex: 0,
				serviceTypeList: [
					{
						enTitle:"Software product evaluation",
						enContent:"According to the technical requirements specified in the system under test purchase contract, construction plan or requirements specification, test the various characteristics of the system under test specified in the test requirements. The test types support commissioned testing, acceptance testing, identification testing, cost evaluation, etc. , Can issue a nationally recognized CMA third-party test report.Supported product types: mobile APP, desktop software, WEB website, applet, WeChat official account, database, operating system, etc.Supported evaluation types: document set, functionality, performance, compatibility, reliability, ease of use, maintainability, portability, security testing (source code security audit, black box penetration testing, host security scanning).",
						tcTitle:"軟件產品服務",
						tcContent:"主要被測系統採購合同、建設方案或需求說明書等規定的技術要求，對測試需求中規定的被測系統的各項特性進行測試，測試類型支持委託測試、驗收測試、鑑定、測試成本評估等，可出具國家認可的 CMA 測試報告。支持的產品類型：移動APP、桌面軟件、WEB網站、小程序、微信公眾號、數據庫、硬件等支持的功能類型：文檔集、性能、擴展、性能、維護性、可移植性、安全性（源代碼安全審計、黑盒檢測測試、主機安全掃描）。",
						iconsrc: require('../assets/static/icon4.png'),
						title: this.$t('index.serviceTypeTitle1'),
						content: this.$t('index.serviceTypeContent1'),
						categoryCode: 'rc',
						categoryId: 31
					},
					{
						enTitle:"Blockchain product evaluation",
						enContent:"Focusing on the network security, underlying technology, baas platform and the functions, performance and security of upper applications of blockchain, it provides detection services for blockchain projects for the industry, and can issue third-party detection certificates with national CMA certification. At present, it has completed the project testing of many domestic mainstream blockchains such as ant blockchain baas platform, Hangzhou fun chain blockchain platform, CLP cloud blockchain, complex America blockchain platform, Tencent blockchain platform and Inspur Group quality chain, and issued testing certificates.Supported product types: baas platform, public chain / alliance chain, smart contract, wallet, etcSupported evaluation types: functional detection, performance detection, security detection (source code security audit, black box penetration test, node host security scanning).",
						tcTitle:"區塊鏈產品測評",
						tcContent:"圍繞區塊鏈的網路安全、底層科技、BAAS平臺以及上層應用的功能、效能及安全，面向行業提供區塊鏈項目的檢測服務，可出具國家CMA認證認可資質的協力廠商檢測。 現時已完成螞蟻區塊鏈BaaS平臺、杭州趣鏈區塊鏈平臺、中電雲網區塊鏈、複雜美區塊鏈平臺、騰訊區塊鏈平臺以及浪潮集團質量鏈等國內多個主流區塊鏈的項目檢測並發放了檢測證書。支持的產品類型：BAAS平臺、公鏈/聯盟鏈、智慧合約、錢包等支持的測評類型：功能性檢測、效能檢測、安全性檢測（原始程式碼安全稽核、黑盒滲透測試、節點主機安全掃描）。",
						iconsrc: require('../assets/static/icon1.png'),
						title: this.$t('index.serviceTypeTitle2'),
						content: this.$t('index.serviceTypeContent2'),
						categoryCode: 'bc',
						categoryId: 30
					},
					{
						enTitle:"Blockchain security assessment",
						enContent:"Security assessment: It is difficult to identify the authenticity of blockchain companies or projects in the process of attracting investment or investment, and there may be greater security risks. We conduct a comprehensive risk assessment from multiple dimensions of the company and the project, and issue a risk assessment report to discover the risk of the project in advance. At present, safety assessments have been conducted for hundreds of enterprises and projects, and they have been highly recognized by government leaders.Security inspection: Cooperate with relevant departments to conduct security inspections on blockchain projects and issue inspection results reports. On the one hand, t can help companies discover security vulnerabilities and prevent economic losses. On the other h",
						tcTitle:"區塊鏈安全評估",
						tcContent:"安全評估：在招商引資或投資過程中很難鑑別區塊鏈企業或項目的真假，可能存在較大安全隱患。我們從企業和項目的多個維度進行全面的風險評估，並出具風險評估報告，提前發現項目的風險情況。目前已為數百個企業、項目進行了安全評估，得到了政府領導的高度認可。安全檢查：配合有關部門對區塊鏈項目進行安全檢查工作，並出具檢查結果報告，一方面可以幫助企業發現安全漏洞防止經濟損失，另一方面通過檢查可了解項目真實的情況，包括功能點、業務模式等，以規範和促進區塊鏈技術服務健康發展，為區塊鏈信息服務的提供、使用和管理等提供有效的法律依據。",
						iconsrc: require('../assets/static/aq_1.png'),
						title: this.$t('index.serviceTypeTitle3'),
						content: this.$t('index.serviceTypeContent3'),
						categoryCode: 'bg',
						categoryId: 32
					},
					{
						enTitle:"Blockchain industry security monitoring",
						enContent:"The platform implements 7*24 hours all-round, highly dynamic risk monitoring for national blockchain companies and global blockchain projects, and timely warning of risks is found; real-time transactions and node monitoring of mainstream public chains; satisfy local governments, provinces and cities The Cyberspace Administration of China, financial regulatory authorities, law enforcement agencies, and blockchain industry parks need to supervise the blockchain industry to ensure the orderly development of the blockchain industry and the scope of business can be customized.",
						tcTitle:"区块链行业安全监测",
						tcContent:"平台對全國區塊鏈企業和全球區塊鏈項目實行7*24小時全方位、高動態的風險監測，發現風險及時預警；並對主流公鏈進行實時交易和節點監控；滿足地方政府、各省市網信辦、金融監管部門、執法機構以及區塊鏈產業園區等對區塊鏈行業進行監管的需求，確保區塊鏈產業發展有序，業務範圍可定制。",
						iconsrc: require('../assets/static/jc.png'),
						title: this.$t('index.serviceTypeTitle4'),
						content: this.$t('index.serviceTypeContent4'),
						categoryCode: 'bj',
						categoryId: 33
					},
					// {
					// 	isSelect: false,
					// 	selectIcon: require('../assets/static/selectIcon3.png'),
					// 	iconsrc: require('../assets/static/icon3.png'),
					// 	title: this.$t('index.serviceTypeTitle5'),
					// 	content: this.$t('index.serviceTypeContent5'),
					// 	categoryCode: 3,
					// },
					// {
					// 	isSelect: false,
					// 	selectIcon: require('../assets/static/selectIcon8.png'),
					// 	iconsrc: require('../assets/static/icon8.png'),
					// 	title: this.$t('index.serviceTypeTitle6'),
					// 	content: this.$t('index.serviceTypeContent6'),
					// 	categoryCode: 8,
					// },
					// {
					// 	isSelect: false,
					// 	selectIcon: require('../assets/static/selectIcon5.png'),
					// 	iconsrc: require('../assets/static/icon5.png'),
					// 	title: this.$t('index.serviceTypeTitle7'),
					// 	content: this.$t('index.serviceTypeContent7'),
					// 	categoryCode: 4,
					// },
					// {
					// 	isSelect: false,
					// 	selectIcon: require('../assets/static/selectIcon4.png'),
					// 	iconsrc: require('../assets/static/icon4.png'),
					// 	title: this.$t('index.serviceTypeTitle8'),
					// 	content: this.$t('index.serviceTypeContent8'),
					// 	categoryCode: 6,
					// },	
				],
				codeAndServiceList: [{
						bgSrc: "",
						imgSrc: '',
						title: "代码审计",
						content: "围绕网络安全、区块链底层技术和上层应用的安全，面向行业开展区块链安全检测工作，其中项目检测包括7个方面：公链联盟链安全检测、智能合约安全检测、钱包安全检测、APP安全检测、Web网站安全检测、系统渗透、日常监测......"
					},
					{
						bgSrc: "",
						imgSrc: '',
						title: "业务范围",
						content: "围绕网络安全、区块链底层技术和上层应用的安全，面向行业开展区块链安全检测工作，其中项目检测包括7个方面：公链联盟链安全检测、智能合约安全检测、钱包安全检测、APP安全检测、Web网站安全检测、系统渗透、日常监测......"
					}
				],
				partnersImageList: [{
						imgSrc: 'parent2'
					},
					{
						imgSrc: 'parent3'
					},
					{
						imgSrc: 'parent4'
					},
					{
						imgSrc: 'parent5'
					},
					{
						imgSrc: 'parent6'
					},
					{
						imgSrc: 'parent7'
					},
					{
						imgSrc: 'parent8'
					},
					{
						imgSrc: 'parent9'
					},
					{
						imgSrc: 'parent10'
					},
					{
						imgSrc: 'parent11'
					},
					{
						imgSrc: 'parent12'
					},
					{
						imgSrc: 'parent13'
					},
					{
						imgSrc: 'parent14'
					},
					{
						imgSrc: 'parent15'
					},
					{
						imgSrc: 'parent16'
					},
					{
						imgSrc: 'parent17'
					}
				],
				newsMainList1: [],
				newsMainList2:[],
				newsMainList3:[],
				stationList: {},
				//行业动态
				newsContentMainList:[],
				//控制地图点击事件的显示隐藏
				dituShow:false,
			}
		},
		computed: {
			sessionStorage(){
				return sessionStorage.getItem('lang')
			}

		},
		watch: {
			$route(to,from){
				this.activeIndex=this.$route.query.active
				thatSwiper.slideTo(this.activeIndex)
			},
			
			
			activeIndex(val) {
				// if(val!==5){
				// 	//禁用滚动条
				// 	scroll.RemoveScroll()
				// }else{
				// 	//开启滚动条
				// 	scroll.AddScroll()
				// 	this.swiperOption.mousewheel=false
				// }
			}
		},
		created() {
			//禁用滚动条
			vm = this
			//this.$zh_tran(sessionStorage.getItem('lang'))
			vm.$emit('changeActiveStep', this.activeIndex)
			// this.getStationList()
		},
		mounted() {
			this.getStationList()
			//this.getnewslist()
			this.getNewsContentMainList()
			//this.getnewslist1(0)
			this.getnewslist2(0)
			//this.getnewslist3(0)
			//console.log(this.newsMainList1)
			console.log(this.newsMainList2)
			//console.log(this.newsMainList3)
			//this.getnewslist()
			// if(sessionStorage.getItem('lang')=='s'){
			// 	this.getNewsContentMainList()
			// 	this.getnewslist()
			// }else if(sessionStorage.getItem('lang')=='e'){
			// 	this.getNewsContentMainList(1)
			// 	this.getnewslist(1)
			// 	console.log('英文')
			// }else{
			// 	this.getNewsContentMainList()
			// }
			
			console.log(this.$route.query.active)
			
			var that = this;
			    // <!--把window.onresize事件挂在到mounted函数上-->
			    window.onresize = () => {
			      return (() => {
			        window.fullWidth = document.documentElement.clientWidth;
			        that.windowWidth = window.fullWidth; // 宽
			      })()
			    }
			// this.myMap();
		},
		beforeUpdate(){
			this.$zh_tran(sessionStorage.getItem('lang'))
		},
		methods: {
			showDituxx(){
				this.dituShow=true
			},
			closeDituxx(){
				this.dituShow=false
			},
			toBeian(){
				window.open('https://beian.miit.gov.cn')
			},
			//跳转到检测页
			toTest(categoryId, categoryCode, name, remark,englishName,englishRemark,tcName,tcRemark) {
				
					// this.$router.push({
					// 	path: '/webDetection',
					// 	query: {
					// 		categoryId,
					// 		categoryCode,
					// 		name,
					// 		remark
					// 	}
					// })	
				window.open('https://www.eastsec.org.cn/webDetection?categoryId='+categoryId+'&categoryCode='+categoryCode+'&name='+name+'&remark='+remark+'&englishName='+englishName+'&englishRemark='+englishRemark+'&TCname='+tcName+'&TCremark='+tcRemark)
			},
			//跳到新闻分类页
			toNewsBulletinhangye(catalogueId,title,EnglishTitle){
				// this.$router.push({
				// 	path:'/newsBulletin',
				// 	query:{
				// 		catalogueId
				// 	}
				// })
				
				window.open('https://www.eastsec.org.cn/newsBulletin?catalogueId='+catalogueId+'&title='+title+'&EnglishTitle='+EnglishTitle)
			},
			//搜索
			querySearchAsync(queryString, cb) {
				let data = {
					itemName: this.searchkey
				};
				api.getItemNameListByName(data).then(res => {
					console.log(res)
					if (res.itemNameList) {
						var data = res.itemNameList ? res.itemNameList : '';
						data = data.map(da => ({
							value: da
						}))
						console.log(data)
						let results = data ? data : '';
						results = queryString ?
							results.filter(this.createFilter(queryString)) :
							results;
						//cb是回调函数，返回筛选出的结果数据到输入框下面的输入列表
						cb(results);
					}
				})

			},
			createFilter(queryString) {
				return (item) => {
					return item.value.toUpperCase().match(queryString.toUpperCase());
				};
			},
			handleSelect(itemName) {
				this.$router.push({
					path: '/certificateView',
					query: {
						itemName
					}
				})
			},
			//首页下一页方法
			nextPage() {
				thatSwiper.slideNext()
			},
			selectService(index) {
				this.serviceTypeList[index].isSelect = true
			},
			leaveService(index) {
				this.serviceTypeList[index].isSelect = false
			},
			selectNews(index) {
				this.newsMainList[index].isSelect = true
			},
			leaveNews(index) {
				this.newsMainList[index].isSelect = false
			},
			getnewslist1(language=0,pageNum=3) {
				let data = {
					language,
					pageNum,
					pageSize: 6,
					catalogueId: "07310220181206090100000098",
					isDesc: true
				};
				api.getAllArticles(data).then(res => {
					let data = res.articleDOs;
					this.newsMainList1=data
					console.log(this.newsMainList1)
				})
			},
			getnewslist2(language=0,pageNum=1) {
				let data = {
					language,
					pageNum,
					pageSize: 3,
					catalogueId: "07310220181206090100000098",
					isDesc: true
				};
				api.getAllArticles(data).then(res => {
					let data = res.articleDOs;
					this.newsMainList2=data
					this.newsMainList2.forEach(item => {
						item.gmtCreate = formatDatedian(parseInt(item.gmtCreate))
					})
				})
			},
			getnewslist3(language=0,pageNum=2) {
				let data = {
					language,
					pageNum,
					pageSize: 6,
					catalogueId: "07310220181206090100000098",
					isDesc: true
				};
				api.getAllArticles(data).then(res => {
					let data = res.articleDOs;
					this.newsMainList3=data
				})
			},
			//跳转到证书查询
			toCertificate() {
				this.$router.push('/certificateQuery')
			},
			//跳转到新闻公告页
			toNewsBulletin(catalogueId) {
				this.$router.push({
					path: '/newsBulletin',
					query: {
						catalogueId
					}
				})
			},
			toNewsBulletinItem(articleId) {
				// this.$router.push({
				// 	path: '/newsBulletinItem',
				// 	query: {
				// 		articleId
				// 	}
				// })
				window.open('https://www.eastsec.org.cn/newsBulletinItem?articleId='+articleId)
			},
			//获取检测站列表
			getStationList() {
				api.getStationList().then(res => {
					this.stationList = res.auditStationDoList[0]
					console.log(this.stationList)
					this.center.lng = this.stationList.longitude
					this.center.lat = this.stationList.latitude
				})
			},
			// 地图初始化
			handler({
				BMap,
				map
			}) {
				console.log(BMap, map)
				// this.map.center.lng = 118.802422
				// this.map.center.lat = 32.065631
				// this.map.zoom = 12;
				// this.map.width= document.getElementById("container").clientWidth-330+'px'
				// this.map.height=document.body.clientHeight -160+'px'

			},
			//行业报告
			getNewsContentMainList(language=0,catalogueId='07310220181206090100000097', pageNum = 1, pageSize = 3, isDesc = true) {
				api.getAllArticles({
					pageNum,
					pageSize,
					catalogueId,
					language,
					isDesc
				}).then(res => {
					this.newsContentMainList = res.articleDOs
					this.totalPages = res.totalPages
					this.pageSize = res.pageNum
					this.newsContentMainList.forEach(item => {
						item.gmtCreate = formatDatedian(parseInt(item.gmtCreate))
					})
				})
			},
			


		}
	}
</script>

<style lang="less" scoped>
	@import "../assets/font/iconfont.css";
	
	.indexLogo{
		display: none;
		
		@media (max-width:1024px){
			display: block;
			width: 5.5rem;
			transform: scale(0.8);
		}
	}
	
	.disno{
		display: none !important;
	}
	
	.fontsz{
		font-size: 0.9rem !important;
	}
	.margins{
		margin: 80px auto 0 !important;
	}
	
	/deep/.swiper-pagination-bullet-active {
		width: 12px !important;
		height: 12px !important;
		background: #FFFFFF !important;
		border-radius: 50% !important;
		@media (max-width:1024px){
			width: 14px !important;
			height: 14px !important;
		}
	}

	/deep/.swiper-pagination-bullet {
		width: 4px;
		height: 4px;
		background: #FFFFFF;
		border-radius: 50%;
		margin-left: 14px;
		@media (max-width:1024px){
			width: 8px;
			height: 8px;
		}
	}

	//动画类
	.animation {
		animation-name: fadeInUp;
		animation-duration: 2s;
		animation-fill-mode: forwards;
	}

	.animation1 {
		animation-name: fadeInUp;
		animation-duration: 2s;
		animation-fill-mode: forwards;
	}
	.detectionProcess{
		width: 100%;
		height: 100%;
		background: url(../assets/static/6.png);
		background-size: cover;
		.detectionMain{
			width: 36rem;//1440
			height: 100%;
			margin: 0 auto;
			position: relative;
			@media (max-width:1024px){
				width: 90%;
			}
			>div:not(:first-child){
				width: 9.125rem;//365
				height: 3.075rem;//123
				border-radius: 5px ;
				background: url(../assets/static/jx.png);
				position: absolute;
				opacity: 0;
				@media (max-width:1024px){
					width: 38%;
				}
				>h4{
					position: absolute;
					top: 0.8rem;//28
					left: 0.65rem;//26
					font-size: 1.2rem;//48
					color: #9cb9ff;
					@media (max-width:1024px){
						left: 0.35rem;
					}
				}
				>img{
					width: 1.575rem;//63
					height: 1.45rem;//58
					position: absolute;
					top: 0.825rem;//33
					left: 2.2rem;//88
					@media (max-width:1024px){
						width:1.5rem;
						height: 1.4rem;
						top: 0.9rem;
						left: 1.3rem;
					}
				}
				>h2{
					position: absolute;
					top: 50%;//28
					transform: translateY(-50%);
					left: 4.25rem;//170
					font-size: 0.75rem;//30
					font-family: Source Han Sans SC;
					font-weight: 500;
					
					color: #FFFFFF;
					@media (max-width:1024px){
						font-size: 0.7rem;//20
						left: 3rem;
					}
				}
				.enh2{
					@media (max-width:1024px){
						font-size: 0.5rem;//20
						
					}
				}
				.disnone{
					display: none;
				}
				>p{
					position: absolute;
					top: 1.8rem;//80
					left: 4.25rem;//148
					font-size: 0.4rem;//16
					font-family: Roboto;
					font-weight: 400;
					color: #BACFFE;
					@media (max-width:1024px){
						display: none;
						top: 1.2rem;
						left: 2rem;
					}
				}
			}
			.detectionTitle{
				position: absolute;
				top: 50%;
				@media (max-width:1024px){
					top: 3rem;//120
					left: 50%;
					transform: translateX(-50%);
				}
				>h2{
					width: max-content;
					font-size: 1rem;//40
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #FFFFFF;
					text-transform: uppercase;
					opacity: 0;
					@media (max-width:1024px){
						margin-top: 1rem;
					}
				}
				>h3{
					font-size: 0.9rem;//36
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #FFFFFF;
					text-transform: uppercase;
					opacity: 0;
				}
			}
			.processItem1{
				
				top: 3.6rem;//144
				right: 13.525rem;//541
				@media (max-width:1024px){
					top: 8rem;//380
					left: 0;
				}
			}
			.processItem2{
				
				top: 9.175rem;//367
				right: 13.525rem;//541
				@media (max-width:1024px){
					top: 14rem;//621
					left: 0;
				}
			}
			.processItem3{
				
				top: 14.775rem;//591
				right: 13.525rem;//541
				@media (max-width:1024px){
					top: 20rem;//860
					left: 0;
				}
			}
			.processItem4{
				
				top: 5.725rem;//229
				right: 0;
				@media (max-width:1024px){
					top: 9.5rem;//297
					right: 0;
				}
			}
			.processItem5{
				
				top: 11.7rem;//468
				right: 0;
				@media (max-width:1024px){
					top: 15.5rem;//520
					right: 0;
				}
			}
			.processItem6{
				
				top: 17.675rem;//707
				right: 0;
				@media (max-width:1024px){
					top: 21.5rem;//744
					right: 0;
				}
			}
			.processItem7{
				top: 20.35rem;//814
				right: 13.525rem;//541
				@media (max-width:1024px){
					top: 26rem;//744
					left: 0;
				}
			}
			.bagimg{
				position: absolute;
				right: 10.35rem;//414
				top: 6.325rem;//253
				width: 2.45rem;//98
				height: 16.35rem;//599
				background: url(../assets/static/1.png);
				background-position: center;
				background-size: cover;
				@media (max-width:1024px){
					top: 10.45rem;//338
					right: 7.5rem;
				}
				opacity: 0;
			}
			
		}
	}



	.dd {
		position: fixed;
		top: 50%;
		right: 2%;
		transform: rotate(90deg);
		display: inline-flex;
		align-items: center;

	}

	.myswiper {
		position: relative;
		height: 100vh;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		touch-action: none;
		overflow: hidden;
	}

	.myswiperItem {
		width: 100%;
		height: 100%;

		.dizhi {
			width: 100%;
			height: 100%;
			overflow: hidden;
			background: #FFFFFF;
			display: flex;
			flex-direction: column;
			
			.indexFooter{
				flex: 1;
				overflow: hidden;
				background-color: rgb(0 58 130);
				padding: 0.75rem;
				 
				width: 100%;
				.indexFooterTop{
					width: 36rem;
					margin: 0 auto 0;
					display: flex;
					justify-content: space-between;
					@media (max-width:1024px){
						width: 90%;
						
					}
					.indexFooterTopLeft{
						width: 23rem;
						display: flex;
						justify-content: space-between;
						@media (max-width:1024px){
							flex-direction: column;
						}
						.topLeftLeft{
							>h4{
								font-size: 0.55rem;//40
								font-family: Source Han Sans SC;
								font-weight: 500;
								color: #FFFFFF;
								@media (max-width:1024px){
									font-size: 0.9rem;
								}
							}
							>p{
								margin-top: 0.65rem;
								font-size: 0.4rem;
								font-family: Source Han Sans SC;
								font-weight: 300;
								color: #cdcdcd;
								@media (max-width:1024px){
									font-size: 0.75rem;
								}
							}
							>span{
								margin-top: 0.25rem;
								display: block;
								font-size: 0.4rem;
								font-family: Source Han Sans SC;
								font-weight: 300;
								color: #cdcdcd;
								@media (max-width:1024px){
									font-size: 0.75rem;
									width: 90% !important;
									text-align: justify;
								}
							}
							.w500s{
								width: 12.5rem;
							}
						}
						.topLeftRight{
							@media (max-width:1024px){
								margin-top: .5rem;
							}
							>h4{
								font-size: 0.55rem;//40
								font-family: Source Han Sans SC;
								font-weight: 500;
								color: #FFFFFF;
								@media (max-width:1024px){
									font-size: 0.9rem;
								}
							}
							>p{
								margin-top: 0.45rem;
								font-size: 0.5rem;
								font-family: Source Han Sans SC;
								font-weight: 300;
								color: #cdcdcd;
								@media (max-width:1024px){
									font-size: 0.75rem;
								}
							}
							>span{
								margin-top: 0.24rem;
								display: block;
								font-size: 0.4rem;
								font-family: Source Han Sans SC;
								font-weight: 300;
								color: #cdcdcd;
								@media (max-width:1024px){
									font-size: 0.75rem;
									width: 90%;
									text-align: justify;
								}
							}
						}
					}
					.indexFooterTopRight{
						
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						@media (max-width:1024px){
							display: none;
						}
						>img{
							width: 2rem;
						}
						>p{
							font-size: 0.4rem;
							font-family: Source Han Sans SC;
							font-weight: 400;
							color: #FFFFFF;
						}
					}
				}
			}
			
			.dizhiTitlesPhone{
				width: max-content;
				display: none;
				margin: 3rem auto 0;//120
				@media (max-width:1024px){
					
					display: block;
				}
				>h3 {
					font-size: 0.7rem;//24
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #2459D9;
					text-transform: uppercase;
					margin-bottom: 0.25rem;
				}
				
				>h4 {
					font-size: 0.9rem;//40
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
					width: max-content;
					margin: 0 auto;
				}
			}
			// .ditu {
			// 	width: 100%;
			// 	height: 680px;
			// 	background-color: #000000;
			// }

			.dizhiTitles {
				//float: left;
				@media (max-width:1024px){
					display: none;
				}
				>h3 {
					font-size: 0.5rem;//24
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #2459D9;
					text-transform: uppercase;
				}

				>h4 {
					font-size: 0.7rem;//40
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
				}
			}

			.dizhiText {
				
				margin-top: 2.9rem;//116px
				margin-right: 10.725rem;//429px
				display: flex;
				align-items: center;

				>img {
					width: 1.075rem;//43
					height: 1.325rem;//53
					transform: scale(0.7);
					@media (max-width:1024px){
						transform: scale(0.9);
					}
				}

				>p {
					//margin-right: auto;
					margin-left: 0.25rem;//10
					font-size: 0.5rem;//20
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
					@media (max-width:1024px){
						font-size: 0.7rem;
					}
				}
			}

			.phoneAndEmail {
				float: right;
				margin-top: 4px;
				margin-right: 336px;
				display: flex;
				width: 15rem; //600
				justify-content: space-between;

	

				p {
					font-size: 0.45rem;//18
					font-family: Roboto;
					font-weight: 500;
					color: #333333;
					@media (max-width:1024px){
						font-size: 0.6rem;
					}
				}
			}

			.pic {
				float: right;
				transform: scale(0.6);
				margin-top: -90px;
				/*width: 961px;*/
				/*overflow: hidden;*/
			}
		}
	}

	.indexHeader {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		background-image: url(../assets/static/shouye.png);
		@media (max-width:1024px){
			background: url(../assets/static/bg_phone.png);
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
		.xiayiye {
			width: 30px;
			height: 16px;
			cursor: pointer;
			position: absolute;
			bottom: 61px;
			transform: translate(-50%);
			opacity: 0;
		}

		.xiayiye>img {

			width: 100%;
			height: 100%;
			@media (max-width:1024px){
				transform: scale(.75);
			}
		}

		.mainTitle {
			display: flex;
			flex-direction: column;
			align-items: center;
			
			opacity: 1;

			.english {

				width: max-content;
				height: 23px;
				font-size: 0.75rem;//30px
				//font-family: Roboto;
				font-weight: 500;
				color: #FFFFFF;
				letter-spacing: 4px;
				text-transform: uppercase;
				line-height: 23px;
				text-align: justify;
				@media (max-width:1024px){
					font-size: 0.62rem !important;
					letter-spacing: 0;
					transform: scale(.9);
					margin-top: 0.75rem;
				}
			}

			.english::after {
				display: inline-block;
				width: 100%;
				content: "";
			}

			.chinese {
				width: max-content;
				font-size: 1.8rem;//72px
				font-family: Source Han Sans SC;
				font-weight: bold;
				margin: 30px auto;
				color: #FFFFFF;
				@media (max-width:1024px){
					font-size: 1.2rem;//36
				}
			}
			.chineseEn{
				
				@media (max-width:1024px){
					display: none;
					font-size: 0.7rem !important;
				}
			}
			.gp {
				width: max-content;
				height: 34px;
				font-size: 0.9rem;//36px
				font-family: Source Han Sans SC;
				font-weight: 400;
				color: #FFFFFF;
				margin: 40px auto;
				@media (max-width:1024px){
					font-size: 0.7rem;//24
					margin: 40px auto 0 !important;
				}
			}

		}
	}

	.indexfuwu {
		width: 100%;
		height: 100%;
		background: #FFFFFF;

		
			

			>p:nth-child(1) {
				width: max-content;
				font-size: 0.7rem;//50
				font-family: Source Han Sans SC;
				font-weight: 400;
				color: #333333;
				margin: 149px auto 0;
				opacity: 0;
				@media (max-width:1024px){
					margin: 3.5rem auto 0;
					font-size: 0.9rem;//50
				}
			}

			>p:nth-child(2) {
				width: max-content;
				font-size: 0.5rem;//30
				font-family: Source Han Sans SC;
				font-weight: 500;
				
				color: #265BDA;
				margin: 0 auto;
				opacity: 0;
				@media (max-width:1024px){
					font-size: 0.7rem;
				}
			}
		

		.serviceType {
			width: 36rem;//1440
			height: 15rem;
			margin: 2rem auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			
			@media (max-width:1024px){
				width: 90%;
				height: 77%;
			}

			.serviceTypeItem {
				position: relative;
				opacity: 0;
				width: 40.6%;
				height: 5.5rem;//220
				//display: flex;
				box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
				//justify-content: space-around;
				//align-items: center;
				cursor: pointer;
				@media (max-width:1024px){
					width: 100%;//330
					height: 20%;//127
					border-radius: 5px;
				}
				&:hover .zcContent{
					opacity: 0;
				}
				&:hover .hoverContent{
					opacity: 1;
				}
				.zcContent{
					position: absolute;
					z-index: 1;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					width: 100%;
					display: flex;
					//box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
					justify-content: space-around;
					align-items: center;
					cursor: pointer;
					transition: all .5s ease-in-out;
					@media (max-width:1024px){
						top: 38%;
					}
					.serviceBg {
						width: 2.625rem;//105
						height: 2.625rem;//105
						background-position: center;
						background-size: 1.5rem;
						background-repeat: no-repeat;
						border-radius: 10px;
					}
					.content {
						height: 3rem;//120
					
					
						>h3 {
							width: 9.575rem;
							font-size: 0.55rem;//18
							font-family: Source Han Sans SC;
							font-weight: 500;
							color: #292F33;
							@media (max-width:1024px){
								font-size: 0.75rem;
							}
						}
					
						>p {
							width: 9.575rem;//183
							margin-top: 0.25rem;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							font-size: 0.45rem;//14
							font-family: Source Han Sans SC;
							font-weight: 400;
							color: #999999;
							
							@media (max-width:1024px){
								-webkit-line-clamp: 3;
								font-size: 0.65rem;
							}
							@media (max-width:320px){
								-webkit-line-clamp: 2;
							}
						}
					}
				}
				.hoverContent{
					opacity: 0;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: -2;
					width: 100%;
					height: 100%;
					overflow: scroll;
					background: #FFFFFF;
					box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
					padding: 0.25rem;
					box-sizing: border-box;
					transition: all .5s ease-in-out;
					display: flex;
					justify-content: center;
					align-items: center;
					>p{
						font-size: 0.4rem;//14
						font-family: Source Han Sans SC;
						font-weight: 400;
						color: #999999;
					}
				}
				

				
			}

		}

	}

	.Certificate {
		width: 100%;
		height: 100%;
		background: #F8F8F8;

		.header {
			width: 36rem;//1440
			margin: 0.75rem auto 0 auto;//30
			padding-top: 68px;//56
			display: flex;
			justify-content: space-between;
			align-items: center;
			@media (max-width:1024px){
				width: max-content;
				margin: 0 auto;
			}
			.titles {
				opacity: 0;
				>h2 {
					width: max-content;
					font-size: 0.5rem;
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #265BDA;
					
					text-transform: uppercase;
					@media (max-width:1024px){
						font-size: 0.7rem;
					}
				}

				>h3 {
					width: max-content;
					font-size: 0.7rem;//50
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
					@media (max-width:1024px){
						margin: 0 auto;
						font-size: 0.9rem;
					}
					margin-top: 0.5rem;//20
				}
			}

			.genduo {
				opacity: 0;
				@media (max-width:1024px){
					display: none;
				}
				>a {
					border-bottom: 2px solid #2459D9;
					font-size: 0.45rem;//18px
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #2459D9;
					cursor: pointer;
					
				}
			}
		}

		.sousuos {
			position: relative;
			width: 22.5rem; //900px
			height: 1.5rem;//60
			margin: 1.75rem auto 2rem auto;//70 100
			@media (max-width:1024px){
				width: 80%;
				margin: 1.75rem auto 2rem;
			}
			.sousuo {
				width: 22.5rem;
				height: 1.5rem;//60
				background: #FFFFFF;
				border: 1px solid #CDCDCD;
				position: relative;
				opacity: 0;
				@media (max-width:1024px){
					width: 100%;
				}
				>span {
					position: absolute;
					font-size: 0.7rem;//28
					color: #2459D9;
					top: 0.35rem;//14
					left: 30px;
					z-index: 3;
					@media (max-width:1024px){
						
						left: 0.9rem;//36
					}
				}

				>input {
					position: absolute;
					width: 496px;
					height: 56px;
					top: 0;
					left: 0;
					font-size: 18px;
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #999999;
					text-align: left;
					padding-left: 100px;
				}
			}

			// .submit {
			// 	opacity: 0;
			// 	position: absolute;
			// 	top: 0;
			// 	right: 0;
			// 	width: 150px;
			// 	height: 61px;
			// 	line-height: 60px;
			// 	text-align: center;
			// 	font-size: 18px;
			// 	color: #FFFFFF;
			// 	outline: none;
			// 	border: none;
			// 	background: linear-gradient(90deg, #2459D9, #4C7DF5);
			// }
		}
		>.genduo{
			display: none;
			
			@media (max-width:1024px){
				width: max-content;
				display: block;
				opacity: 0;
				margin: 3.25rem auto 0;//90
				>a {
					border-bottom: 2px solid #2459D9;
					font-size: 0.75rem;//18px
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #2459D9;
					cursor: pointer;
				}
			}
		}

		.op {
			opacity: 0;
		}

	}

	.newsBulletin {
		width: 100%;
		height: 100%;
		overflow: hidden;
		background: #FFFFFF;
		// .newsTop{
		// 	background: #FFFFFF;
		// 	padding: 1rem;
		// 	box-sizing: border-box;
		// 	width: 36rem;//1440
		// 	box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
		// 	margin: 3rem auto 0;
		// 	display: flex;
		// 	@media (max-width:1024px){
		// 		width: 90%;
		// 		padding: 0.4rem;
		// 	}
		// 	.newsTopLeft{
		// 		width: 60%;
		// 		box-sizing: border-box;
		// 		border-right: 1px solid #CDCDCD;
		// 		@media (max-width:1024px){
		// 			width: 100%;
		// 			border: none;
		// 		}
		// 		>h3{
		// 			font-size: 0.7rem;
		// 			font-family: Source Han Sans SC;
		// 			font-weight: 500;
		// 			color: #255AD9;
		// 			opacity: 0;
		// 			@media (max-width:1024px){
		// 				font-size: 0.9rem;
		// 			}
		// 		}
		// 		.leftMain{
		// 			width: 100%;
		// 			display: flex;
					
		// 			>img{
		// 				width: 10rem;//400
		// 				cursor: pointer;
		// 				opacity: 0;
		// 				@media (max-width:1024px){
		// 					width: 7rem;
		// 				}
		// 			}
		// 			.leftMainRight{
		// 				margin-left: 1rem;
		// 				width: 8.75rem;//350
		// 				position: relative;
		// 				>h4{
		// 					margin-top: 0.5rem;
		// 					width: 8.75rem;//350
		// 					font-size: 0.5rem;//22
		// 					font-family: Source Han Sans SC;
		// 					font-weight: bold;
		// 					color: #333333;
		// 					cursor: pointer;
		// 					opacity: 0;
		// 					@media (max-width:1024px){
		// 						width: 8.2rem;
		// 					}
		// 				}
		// 				>p{
		// 					width: 8.75rem;//350
		// 					opacity: 0;
		// 					font-size: 0.4rem;//16
		// 					font-family: Source Han Sans SC;
		// 					font-weight: 400;
		// 					color: #999999;
		// 					line-height: 0.75rem;//30
		// 					@media (max-width:1024px){
		// 						width: 8.2rem;
		// 					}
		// 				}
		// 				>span{
		// 					opacity: 0;
		// 					position: absolute;
		// 					font-size: 0.45rem;
		// 					font-family: Roboto;
		// 					font-weight: 400;
		// 					color: #6995FE;
		// 					bottom: 0.5rem;
		// 					left: 0;
		// 					@media (max-width:1024px){
		// 						bottom: 0rem;
		// 					}
		// 				}
		// 				>a{
		// 					opacity: 0;
		// 					position: absolute;
		// 					font-size: 0.45rem;
		// 					font-family: Source Han Sans SC;
		// 					font-weight: 400;
		// 					color: #6995FE;
		// 					cursor: pointer;
		// 					bottom: 0.5rem;
		// 					right: 0;
		// 					@media (max-width:1024px){
		// 						bottom: 0rem;
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}
		// 	.newsTopRight{
		// 		@media (max-width:1024px){
		// 			display: none;
		// 		}
		// 		>p{
		// 			opacity: 0;
		// 			cursor: pointer;
		// 			margin: 0.25rem 0 0.25rem 1.25rem;
		// 			width: 10rem;
		// 			font-size: 0.45rem;
		// 			font-family: Source Han Sans SC;
		// 			font-weight: 400;
		// 			color: #666666;
		// 			line-height: 0.6rem;
		// 			position: relative;
		// 			&::before{
		// 				content: '';
		// 				position: absolute;
		// 				top: 0.2rem;
		// 				left: -0.5rem;
		// 				width: 0.2rem;
		// 				height: 0.2rem;
		// 				background: #255AD9;
		// 				border-radius: 50%;
		// 				border: 0.025rem solid #255AD9;
						
		// 			}
		// 		}
				
		// 	}
		// }
		// .newsBottom{
		// 	width: 36rem;
		// 	margin: 0.5rem auto 0;
		// 	display: flex;
		// 	justify-content: space-between;
		// 	@media (max-width:1024px){
		// 		width: 90%;
		// 		flex-direction: column;
		// 	}
		// 	.newsBottomLeft{
		// 		width: 49%;
		// 		box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
		// 		padding: 1rem;
		// 		box-sizing: border-box;
		// 		@media (max-width:1024px){
		// 			width: 100%;
		// 		}
		// 		.newsBottomLeftHeader{
		// 			opacity: 0;
		// 			display: flex;
		// 			justify-content: space-between;
		// 			align-items: center;
		// 			padding-bottom: 0.25rem;
		// 			border-bottom: 1px solid #CDCDCD ;
		// 			>h5{
		// 				opacity: 0;
		// 				font-size: 0.6rem;
		// 				font-family: Source Han Sans SC;
		// 				font-weight: 400;
		// 				color: #333333;
		// 				@media (max-width:1024px){
		// 					font-size: 0.8rem;
							
		// 				}
		// 			}
		// 			>a{
		// 				opacity: 0;
		// 				cursor: pointer;
		// 				font-size: 0.4rem;
		// 				font-family: Source Han Sans SC;
		// 				font-weight: 400;
		// 				color: #6995FE;
		// 				@media (max-width:1024px){
		// 					font-size: 0.6rem;
							
		// 				}
		// 			}
		// 		}
		// 		.bottomLeftMain{
		// 			opacity: 0;
		// 			display: flex;
		// 			justify-content: space-between;
		// 			align-items: center;
		// 			position: relative;
					
		// 			&::before{
		// 				content: '';
		// 				position: absolute;
		// 				top: 0.45rem;
		// 				left: 0rem;
		// 				width: 0.2rem;
		// 				height: 0.2rem;
		// 				background: #255AD9;
		// 				border-radius: 50%;
		// 				border: 0.025rem solid #255AD9;
		// 			}
		// 			>p:first-child{
		// 				margin: 0.25rem 0 0.25rem .5rem;
		// 				width: 10rem;//400
		// 				font-size: 0.45rem;//18
		// 				font-family: Source Han Sans SC;
		// 				font-weight: 400;
		// 				color: #666666;
		// 				line-height: 0.6rem;//24
		// 				overflow: hidden;
		// 				white-space: nowrap;
		// 				text-overflow: ellipsis;
		// 				cursor: pointer;
		// 				@media (max-width:1024px){
		// 					font-size: 0.6rem;
		// 					margin: 0.35rem 0 0.35rem .6rem;
		// 				}
						
		// 			}
		// 			>p:last-child{
		// 				font-size: 0.4rem;
		// 				font-family: Source Han Sans SC;
		// 				font-weight: 400;
		// 				color: #999999;
		// 				line-height: 0.6rem;
		// 				@media (max-width:1024px){
		// 					font-size: 0.6rem;
							
		// 				}
		// 			}
		// 		}
		// 	}
		// 	.newsBottomRight{
		// 		box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
		// 		width: 49%;
		// 		padding: 1rem;
		// 		box-sizing: border-box;
		// 		@media (max-width:1024px){
		// 			width: 100%;
		// 			margin-top: 0.5rem;
		// 		}
		// 		.xian{
		// 			opacity: 0;
		// 			width: 100%;
		// 			height: 1px;
		// 			background-color: #CDCDCD;
		// 			margin-top: 1.15rem;
		// 		}
		// 		.bottomRightMain{
		// 			opacity: 0;
		// 			display: flex;
		// 			justify-content: space-between;
		// 			align-items: center;
		// 			position: relative;
		// 			&::before{
		// 				content: '';
		// 				position: absolute;
		// 				top: 0.45rem;
		// 				left: 0rem;
		// 				width: 0.2rem;
		// 				height: 0.2rem;
		// 				background: #255AD9;
		// 				border-radius: 50%;
		// 				border: 0.025rem solid #255AD9;
		// 			}
		// 			>p:first-child{
		// 				margin: 0.25rem 0 0.25rem .5rem;
		// 				width: 10rem;//400
		// 				font-size: 0.45rem;//18
		// 				font-family: Source Han Sans SC;
		// 				font-weight: 400;
		// 				color: #666666;
		// 				line-height: 0.6rem;//24
		// 				overflow: hidden;
		// 				white-space: nowrap;
		// 				text-overflow: ellipsis;
		// 				cursor: pointer;
		// 				@media (max-width:1024px){
		// 					font-size: 0.6rem;
		// 					margin: 0.35rem 0 0.35rem .6rem;
		// 				}
						
		// 			}
		// 			>p:last-child{
		// 				font-size: 0.4rem;
		// 				font-family: Source Han Sans SC;
		// 				font-weight: 400;
		// 				color: #999999;
		// 				line-height: 0.6rem;
		// 				@media (max-width:1024px){
		// 					font-size: 0.6rem;
		// 				}
		// 			}
		// 		}
		// 	}
		// }
		
		.industryTitles {
			padding-top: 110px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			opacity: 0;
			@media (max-width:1024px){
				padding-top: 90px;
			}
			>h3 {
				width: max-content;
				font-size: 0.5rem;//30
				
				font-family: Source Han Sans SC;
				font-weight: 400;
				color: #265BDA;
				text-transform: uppercase;
				@media (max-width:1024px){
					font-size: 0.7rem;
				}
			}
		
			>h4 {
				margin-top: 0.5rem;
				font-size: 0.7rem;//50
				font-family: Source Han Sans SC;
				font-weight: 500;
				color: #333333;
				@media (max-width:1024px){
					font-size: 0.9rem;
				}
			}
		}
		
		.industryMain {
			width: 36rem;//1440
			height: 12.5rem;//500px
			margin: 1rem auto 0;//40
			display: flex;
			justify-content: space-between;
			opacity: 0;
			@media (max-width:1024px){
				width: 90%;
				
			}
		
			.industryLeft {
				width: 15rem;//600
				height: 12.5rem;//500
				
				@media (max-width:1024px){
					display: none;
				}
				>img {
					width: 100%;
					height: 9.5rem;//440
					cursor: pointer;
				}
		
				>span {
					font-size: 0.35rem;//18
					font-family: Roboto;
					font-weight: 400;
					color: #265BDA;
				}
		
				>h4 {
					margin-top: 0.125rem;//5
					font-size: 0.5rem;//24
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
					cursor: pointer;
				}
		
				>p {
					margin-top: 0.125rem;//5
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 0.3rem;//18
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #999999;
				}
			}
		
			.industryRight {
				width: 20rem;//800
				height: 12.5rem;//500
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				@media (max-width:1024px){
					width: 100%;
					height: 18rem;//600
				}
				.industryRightItem {
					box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
					width: 100%;
					height: 3.75rem;//150
					overflow: hidden;
					background-color: #FFFFFF;
					display: flex;
					@media (max-width:1024px){
						height: 5.5rem;//180
						border-radius: 0.25rem;
					}
					>img {
						width: 5.5rem;//220
						height: 3.75rem;//160
						float: left;
						cursor: pointer;
						@media (max-width:1024px){
							width: 6.5rem;
							height: 5.5rem;//180
						}
					}
					.industryRightContent{
						width: 70%;
						overflow: hidden;
						>span {
							display: block;
							margin-top: 0.75rem;//30
							margin-left: 0.75rem;//30
							font-size: 0.3rem;//16
							font-family: Roboto;
							font-weight: 400;
							color: #265BDA;
							width: 50%;
							@media (max-width:1024px){
								font-size: 0.5rem;
							}
						}
						
						>h5 {
							cursor: pointer;
							margin-top: 0.3rem;//12
							margin-left: 0.75rem;//30
							width: 70%;//450
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							font-size: 0.5rem;//18
							font-family: Source Han Sans SC;
							font-weight: 500;
							color: #333333;
							@media (max-width:1024px){
								width: 9rem;
								font-size: 0.65rem;
							}
						}
						
						>p {
							margin-top: 0.125rem;//5
							margin-left: 0.75rem;//30
							
							width: 70%;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							font-size: 0.25rem;//14
							font-family: Source Han Sans SC;
							font-weight: 400;
							color: #999999;
							line-height: 0.6rem;//24
							@media (max-width:1024px){
								width: 85%;
								font-size: 0.5rem;
								margin-top: 0.5rem;
							}
						}
					}
					
				}
			}
		}
		
		.industryGenduo {
			width: 81px;
			height: 18px;
			margin: 1.5rem auto;//60
			opacity: 0;
			@media (max-width:1024px){
				margin: 8rem auto 0;
				text-align: center;
			}
			>a {
				font-size: 0.4rem;//18
				font-family: Source Han Sans SC;
				font-weight: 400;
				color: #2459D9;
				cursor: pointer;
				border-bottom: 2px solid #2459D9;
				@media (max-width:1024px){
					font-size: 0.75rem;
				}
			}
		}
		
		
		
		
		
		// .newsHeader {
		// 	width: 36rem;//1440
		// 	margin: 0 auto;
		// 	padding-top: 114px;//106
		// 	box-sizing: border-box;
		// 	display: flex;
		// 	justify-content: space-between;
		// 	align-items: center;
		// 	@media (max-width:1024px){
		// 		width: max-content;
				
		// 	}
		// 	.newsTitles {
		// 		opacity: 0;

		// 		h3 {
		// 			text-transform: uppercase;
		// 			font-size: 0.5rem;//30
		// 			font-family: Source Han Sans SC;
		// 			font-weight: 400;
		// 			color: #265BDA;
					
		// 		}

		// 		h4 {
		// 			font-size: 0.7rem;//50
		// 			font-family: Source Han Sans SC;
		// 			font-weight: 500;
		// 			color: #333333;
		// 			@media (max-width:1024px){
		// 				width: max-content;
		// 				margin: 0 auto;
		// 			}
		// 		}
		// 	}

		// 	.newsGenduo {
		// 		opacity: 0;
		// 		border-bottom: 2px solid #2459D9;
		// 		font-size: 0.4rem;//18
		// 		font-family: Source Han Sans SC;
		// 		font-weight: 400;
		// 		color: #2459D9;
		// 		cursor: pointer;
		// 		@media (max-width:1024px){
		// 			display: none;
					
		// 		}
		// 	}
		// }
		// .newsGenduoPhone{
		// 	display: none;
		// 	opacity: 0;
		// 	width: max-content;
		// 	margin: 3rem auto 0;//120
		// 	border-bottom: 2px solid #2459D9;
		// 	font-size: 0.4rem;//18
		// 	font-family: Source Han Sans SC;
		// 	font-weight: 400;
		// 	color: #2459D9;
		// 	cursor: pointer;
		// 	@media (max-width:1024px){
		// 		display: block;
				
		// 	}
		// }

		// .newsMain {
		// 	width: 36rem;//1440
		// 	height: 11.25rem;//450
		// 	margin: 3.25rem auto 0;//100
		// 	display: flex;
		// 	justify-content: space-between;
		// 	align-items: flex-end;
		// 	@media (max-width:1024px){
		// 		width: 90%;
		// 		height: auto;
		// 	}
		// 	.newsItem {
		// 		opacity: 0;
		// 		width: 11.5rem; //460px
		// 		@media (max-width:1024px){
		// 			width: 8.25rem;//330
		// 			&:nth-of-type(3){
		// 				display: none;
		// 			}
		// 		}
				
		// 		.picAndTime {
		// 			position: relative;
		// 			width: 100%;
		// 			@media (max-width:1024px){
		// 				width: 8.25rem;//330
		// 				height: auto;
		// 			}
		// 			// height: 300px;
		// 			>img {
		// 				width: 100%;
		// 				height: 6.5rem;//240
		// 				display: block;
		// 				cursor: pointer;
		// 			}

		// 			>span {
		// 				position: absolute;
		// 				bottom: 0.975rem;//39
		// 				left: 0.725rem;//29
		// 				font-size: 0.40rem;//18
		// 				font-family: Roboto;
		// 				font-weight: 500;
		// 				font-style: italic;
		// 				color: #FEFEFF;
		// 			}

		// 			.day {
		// 				position: absolute;
		// 				width: 1.5rem;//60
		// 				height: 1.5rem;
		// 				bottom: -0.75rem;//30
		// 				left: 0.775rem;//31
		// 				background: #E3E7FF;
		// 				font-size: 0.75rem;//30
		// 				font-family: Roboto;
		// 				font-weight: 500;
		// 				font-style: italic;
		// 				color: #2459D9;
		// 				line-height: 1.5rem;
		// 				text-align: center;
		// 			}

		// 			.dayActive {
		// 				background-color: #2459D9 !important;
		// 				color: #FFFFFF !important;
		// 				@media (max-width:1024px){
		// 					background: #E3E7FF !important;
		// 					color: #2459D9 !important;
		// 				}
		// 			}
		// 		}



		// 		>h5 {
		// 			margin-left: 0.8rem;//32
		// 			margin-top: 1.25rem;//50
		// 			cursor: pointer;
		// 			font-size: 0.50rem;//24
		// 			font-family: Source Han Sans SC;
		// 			font-weight: 500;
		// 			color: #333333;
		// 			line-height: 1rem;//40
		// 		}

		// 		.titleActive {
		// 			color: #2459D9;
		// 			@media (max-width:1024px){
		// 				color: #333333;
		// 			}
		// 		}

		// 		>p {
		// 			margin-left: 0.8rem;//32
		// 			font-size: 0.4rem;//16
		// 			font-family: Source Han Sans SC;
		// 			font-weight: 400;
		// 			color: #999999;
		// 			line-height: 0.75rem;//30
		// 		}

		// 		.ljgenduo {
		// 			margin-left: 0.8rem;//32px
		// 			display: inline-flex;
		// 			align-items: center;
		// 			padding-top: 0.25rem;//10
		// 			cursor: pointer;
		// 			@media (max-width:1024px){
		// 				display: none;
		// 			}
		// 			>img {
		// 				width: 0.25rem;//10
		// 				height: 0.35rem;//14
		// 			}

		// 			>p {
		// 				font-size: 0.35rem;//14
		// 				font-family: Source Han Sans SC;
		// 				font-weight: 500;
		// 				color: #2459D9;
		// 				line-height: 0.75rem;//30
		// 			}
		// 		}
		// 	}

		// }

		// .progress {
		// 	float: left;
		// 	margin-top: 50px;
		// 	margin-left: 3%;
		// 	width: 560px;
		// 	display: flex;
		// 	justify-content: space-between;
		// 	opacity: 0;

		// 	.progressItem1 {
		// 		width: 280px;
		// 		height: 2px;
		// 		background: linear-gradient(90deg, #2459D9, #4C7DF5);
		// 	}

		// 	.progressItem2 {
		// 		width: 280px;
		// 		height: 2px;
		// 		background: #E0E9FF;
		// 	}
		// }

		// .progressNum {
		// 	float: left;
		// 	margin-left: 1.5%;
		// 	display: flex;
		// 	width: 120px;
		// 	justify-content: space-around;
		// 	opacity: 0;

		// 	>p:nth-child(1) {
		// 		font-size: 48px;
		// 		font-family: Roboto;
		// 		font-weight: 300;
		// 		font-style: italic;
		// 		color: #285CDB;
		// 	}

		// 	>p:nth-child(2) {
		// 		font-size: 24px;
		// 		font-family: Roboto;
		// 		font-weight: 300;
		// 		font-style: italic;
		// 		color: #999999;
		// 		margin-top: 20px;
		// 	}
		// }
	}

	.industryAction {
		width: 100%;
		height: 100%;
		background: #F8F8F8;

		.industryTitles {
			padding-top: 110px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			opacity: 0;
			@media (max-width:1024px){
				padding-top: 90px;
			}
			>h3 {
				width: max-content;
				font-size: 0.5rem;//30
				
				font-family: Source Han Sans SC;
				font-weight: 400;
				color: #265BDA;
				text-transform: uppercase;
				@media (max-width:1024px){
					font-size: 0.7rem;
				}
			}

			>h4 {
				margin-top: 0.5rem;
				font-size: 0.7rem;//50
				font-family: Source Han Sans SC;
				font-weight: 500;
				color: #333333;
				@media (max-width:1024px){
					font-size: 0.9rem;
				}
			}
		}

		.industryMain {
			width: 36rem;//1440
			height: 12.5rem;//500px
			margin: 1rem auto 0;//40
			display: flex;
			justify-content: space-between;
			opacity: 0;
			@media (max-width:1024px){
				width: 90%;
			}

			.industryLeft {
				width: 15rem;//600
				height: 12.5rem;//500
				@media (max-width:1024px){
					display: none;
				}
				>img {
					width: 100%;
					height: 9.5rem;//440
					cursor: pointer;
				}

				>span {
					font-size: 0.35rem;//18
					font-family: Roboto;
					font-weight: 400;
					color: #265BDA;
				}

				>h4 {
					margin-top: 0.125rem;//5
					font-size: 0.5rem;//24
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
					cursor: pointer;
				}

				>p {
					margin-top: 0.125rem;//5
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 0.3rem;//18
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #999999;
				}
			}

			.industryRight {
				width: 20rem;//800
				height: 12.5rem;//500
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				@media (max-width:1024px){
					width: 100%;
					height: 18rem;//600
				}
				.industryRightItem {
					width: 100%;
					height: 3.75rem;//150
					overflow: hidden;
					background-color: #FFFFFF;
					display: flex;
					@media (max-width:1024px){
						height: 5.5rem;//180
						border-radius: 0.25rem;
					}
					>img {
						width: 5.5rem;//220
						height: 3.75rem;//160
						float: left;
						cursor: pointer;
						@media (max-width:1024px){
							width: 6.5rem;
							height: 5.5rem;//180
						}
					}
					.industryRightContent{
						width: 70%;
						overflow: hidden;
						>span {
							display: block;
							margin-top: 0.75rem;//30
							margin-left: 0.75rem;//30
							font-size: 0.3rem;//16
							font-family: Roboto;
							font-weight: 400;
							color: #265BDA;
							width: 50%;
							@media (max-width:1024px){
								font-size: 0.5rem;
							}
						}
						
						>h5 {
							cursor: pointer;
							margin-top: 0.3rem;//12
							margin-left: 0.75rem;//30
							width: 12.25rem;//450
							font-size: 0.5rem;//18
							font-family: Source Han Sans SC;
							font-weight: 500;
							color: #333333;
							@media (max-width:1024px){
								width: 9rem;
								font-size: 0.65rem;
							}
						}
						
						>p {
							margin-top: 0.125rem;//5
							margin-left: 0.75rem;//30
							
							width: 70%;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							font-size: 0.25rem;//14
							font-family: Source Han Sans SC;
							font-weight: 400;
							color: #999999;
							line-height: 0.6rem;//24
							@media (max-width:1024px){
								width: 85%;
								font-size: 0.5rem;
								margin-top: 0.5rem;
							}
						}
					}
					
				}
			}
		}

		.industryGenduo {
			width: 81px;
			height: 18px;
			margin: 1.5rem auto;//60
			opacity: 0;
			@media (max-width:1024px){
				margin: 8rem auto 0;
				text-align: center;
			}
			>a {
				font-size: 0.4rem;//18
				font-family: Source Han Sans SC;
				font-weight: 400;
				color: #2459D9;
				cursor: pointer;
				border-bottom: 2px solid #2459D9;
				@media (max-width:1024px){
					font-size: 0.75rem;
				}
			}
		}
	}

	.partners {
		width: 100%;
		height: 100%;
		background: #2161FA;
		position: relative;
		background-image: url(../assets/static/shouye.png);
		@media (max-width:1024px){
			background-size: cover;
		}
		.partnersTitles {
			padding-top: 2.475rem;//179
			display: flex;
			flex-direction: column;
			align-items: center;
			opacity: 0;

			>h3 {
				width: max-content;
				font-size: 0.5rem;//30
				
				font-family: Source Han Sans SC;
				font-weight: 400;
				color: #FFFFFF;
				text-transform: uppercase;
				@media (max-width:1024px){
					font-size: 0.7rem;
				}
			}

			>h4 {
				margin-top: 0.25rem;//10
				width: max-content;
				font-size: 0.7rem;//50
				font-family: Source Han Sans SC;
				font-weight: 500;
				color: #FFFFFF;
				@media (max-width:1024px){
					font-size: 0.9rem;
				}
			}
		}

		.partnersMain {
			display: flex;
			margin: .5rem auto 0;//80
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-start;
			width: 36rem;//1440
			
			//height: 14.5rem;//460
			opacity: 0;
			@media (max-width:1024px){
				width: 90%;
			}
			>img {
				
				padding-top: .5rem;//40
				display: block;
				width: 8.5rem;//466
				height: 4.25rem;//200
				@media (max-width:1024px){
					width: 32%;//330
					height: 3.8rem;//140
				}
			}
		}

		.partnersUp,
		.partnersDown {
			opacity: 0;
			position: absolute;
			width: 37px;
			height: 68px;
			top: 590px;
			opacity: 0.2;
		}

		.partnersUp {
			left: 173px;

			>img {
				transform: rotate(90deg);
			}
		}

		.partnersDown {
			right: 184px;

			>img {
				transform: rotate(270deg);
			}
		}
		
	}
</style>
<style scoped lang="less">
	.index_contactus {
		justify-content: center;
		height: 3.875rem;
		position: relative;
		margin:0.25rem 0 0;//10
	}
	.women{
		
		display: flex;
		flex-direction: column;
		margin-left: 20%;
		@media (max-width: 1024px){
			margin-left: 0;
		}
	}
	.map_index {
		width: 100%;
		height: 70%;//750
		
		@media (min-height: 600px){
			height: 420px !important;
			
		}
		@media (min-height:568px) and (max-width:1024px){
			height: 340px !important;
		}
		@media (min-height:667px) and (max-width:1024px){
			height: 370px !important;
		}
		@media (min-height: 720px)and (max-width:1024px) {
			height: 304px !important;
		}
		@media (min-height:736px) and (max-width:1024px){
			height: 400px !important;
		}
		@media (min-height: 768px){
			height: 544px !important;
		}
		@media (min-height: 800px){
			height: 455px !important;
		}
		@media (min-height: 844px){
			height: 600px !important;
		}
		@media (min-height: 864px){
			height: 650px !important;
		}
		@media (min-height: 870px){
			height: 660px !important;
		}
		@media (min-height: 960px){
			height: 690px !important;
		}
		@media (min-height: 1024px){
			height: 740px !important;
		}
		@media (min-height: 1080px){
			height: 780px !important;
		}
		
	}
	
	// @media screen and (min-width: 1024px) {
	// 	.map_index {
	// 		height: 18.25rem !important;
	// 	}
	// } 
	/* @media screen and (max-width: 1440px)  {
		html{
			font-size: 30px;
		}
		
	  .indexfuwu .serviceType .serviceTypeItem{
		  width: 300px;
	  }
	  .map_index{
		  height: 62%;
	  }
	}
	@media screen and (max-width: 1280px) {
		
		.map_index {
			height: 60%;
		}
	} */
	
	.map {
		width: 100%;
		height: 100%;
	}

	.disflx {
		display: flex;
	}

	.aicen {
		align-items: center;
	}

	.dizhiText,
	.dizhiTitles {
		margin: 0 !important;
	}

	.phoneAndEmail {
		margin-right: 0 !important;
	}

	.pic {
		position: absolute;
		transform: scale(0.8);
		margin-bottom: -320px;
		margin-right: -500px;
	}

	.index_bott {
		background-color: rgb(0 58 130);
		padding: 30px 0;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		position: absolute;
		width: 100%;
		bottom: 0;
	}
	.movedAnima{
		animation: translateX 10s infinite linear;
	}
	
	.bot_cell1 {
		width: 28rem;
		white-space: nowrap;
		overflow: hidden;
		font-size: 0.4rem;//16
		color: #c1c1c1;
		font-family: "思源黑体 CN Regular";
		margin: 50px auto 24px;
		@media (max-width:1024px){
			display: none;
			width: 90%;
			white-space: nowrap;
			overflow: hidden;
			margin: 30px auto 10px;
		}
		>h2{
			margin: 0 auto;
			font-size: 0.4rem;//16
			color: #cdcdcd;
			width: max-content;
			font-weight: 400;
			animation: translateX2 20s infinite linear;
			//font-family: "思源黑体 CN Regular";
			@media (max-width:1024px){
				
				font-size: 0.75rem;//16
				animation: translateX 10s infinite linear;
			}
		}
	}
	@keyframes translateX{
		0%{
			transform: translateX(10%);
		}
		100%{
			transform: translate(-100%);
		}
	}
	@keyframes translateX2{
		0%{
			transform: translateX(10%);
		}
		100%{
			transform: translate(-90%);
		}
	}
	
	.bot_cell2{
		justify-content: center;
		@media (max-width:1024px){
			flex-direction: column;
			margin-top: 0.25rem;
		}
	}

	.bot_cell2 p {
		font-size: 0.4rem;//18
		color: #c1c1c1;
		font-family: "思源黑体 CN Regular";
		margin-right: 22px;
		@media (max-width:1024px){
			font-size: 0.65rem;//16
			margin: 0.45rem auto 0;
			width: 90%;
		}
	}
	.bot_cell2 p:nth-of-type(2){
		
		font-size: 0.4rem;//18
		color: #c1c1c1;
		font-family: "思源黑体 CN Regular";
		margin-right: 22px;
		text-decoration: none;
		cursor: pointer;
		line-height: 0.45rem;
		@media (max-width:1024px){
			width: 90%;
			margin: 0.45rem auto 0;
			
			font-size: 0.65rem;//16
		}
	}

	.el-autocomplete>.el-input {
		height: 100%;
		rder: none;
	}

	.el-autocomplete>.el-input--small .el-input__inner {
		height: 100%;
		border: none;
	}

	.el-autocomplete {
		width: 92%;
		height: 100%;
		padding-left: 8%;
	}
	
</style>
