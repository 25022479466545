<template>
  <ul class="pagination">
    <li class="shouye">
      首页
    </li>
    <li class="prev">
      <p>上一页</p>
      <img src="../assets/static/xyy.png">
    </li>
    <li v-for="page in exceedPage">
      {{ page }}
    </li>
    <li v-if="pageNum>exceedPage">
      ...
    </li>
    <li class="">
      {{ pageNum }}
    </li>
    <li class="next">
      <p>下一页</p>
      <img src="../assets/static/xyy.png">
    </li>
    <li class="moye">
      末页
    </li>
  </ul>
</template>

<script>
	//分页组件
	export default{
		props:{
			//当前页码
			pageIndex:{
				type:Number,
			    default:1
			},
			//超过多少条就会显示...
			exceedPage:{
				type:Number,
				default:3
			},
			//每页显示条数
			pageSize:{
			    type:Number,
			    default:6
			},
			//总记录数
			total:{
			    type:Number,
			    default:100
			},
		},
		data(){
			return{
				
			}
		},
		computed:{
			//计算总页码
			pageNum(){
				return Math.ceil(this.total/this.pageSize)   
			}
		}
	}
</script>

<style lang="less" scoped>
	.pagination{
		list-style: none;
		display: flex;
		justify-content: space-around;
		>li{
			cursor: pointer;
		}
		.shouye{
			width: 50px;
			height: 36px;
			background: #EEF6FF;
			border-radius: 2px;
			font-size: 14px;
			font-family: SimSun;
			font-weight: 400;
			color: #2459D9;
			line-height: 36px;
			text-align: center;
		}
		.prev{
			position: relative;
			width: 74px;
			height: 36px;
			background: #EEF6FF;
			border-radius: 2px;
			>p{
				position: absolute;
				top: 0;
				left: 24px;
				height: 36px;
				font-size: 14px;
				font-family: SimSun;
				font-weight: 400;
				color: #2459D9;
				line-height: 36px;
			}
			>img{
				position: absolute;
				top: 2px;
				left: 4px;
				transform: scale(0.4);
			}
		}
	}
</style>
