<template>
  <div class="certificateView">
    <div class="viewMain">
      <div class="main">
        <div class="position">
          <span>{{$t('certificateView.weizhi')}}:&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <a @click.prevent="toCertificateQuery">{{$t('certificateView.zscx')}}</a>
          <span>&nbsp;&nbsp;>&nbsp;&nbsp;{{$t('certificateView.ckzs')}}</span>
        </div>
        <div class="queryResult">
          <p>{{$t('certificateView.result')}}</p>
          <el-autocomplete
            v-model="search_result"
            :fetch-suggestions="querySearchAsync"
            :trigger-on-focus="false"
            :placeholder="$t('index.placeholder')"
            @select="handleSelect"
          />	
        </div>
        <div class="certificateImg">
          <img src="../assets/static/zhenshu00.png">
          <span />
          <span />
          <span />
          <span />
		  <h6>{{certificateObject.reason}}</h6>
          <h2>证书号:{{ certificateObject.certificateNum }}</h2>
          <h3>{{ certificateObject.itemAbbreviation }}</h3>
          <h4>通过东方区块链安全技术检测中心的检测，予以认证</h4>
          <h5>此证书仅适用于该产品的上述版本或型号</h5>
          <div class="itemName">
            <span :class="{i1:certificateObject.itemName.length>16}">测评项目:</span><span>{{ certificateObject.itemName }}</span>
          </div>
          <div class="number">
            <span :class="{i2:certificateObject.itemName.length>16}">测评报告编号:</span><span>{{ certificateObject.auditReportNum }}</span>
          </div>
          <div class="mechanism">
            <span :class="{i1:certificateObject.itemName.length>16}">测评机构:</span><span>湖南省东方区块链安全技术检测中心</span>
          </div>
          <p>{{ certificateObject.finishTime }}</p>
          <img
            v-if="certificateObject.isCMA=='1'"
            src="../assets/static/cma.png"
            class="cma"
          >
        </div>
        <div
          v-if="certificateObject.websiteUrl"
          class="websiteUrl"
        >
          {{$t('certificateView.guanwang')}}：{{ certificateObject.websiteUrl }}
        </div>
      </div>
    </div>
    <other-footer :type="pageType" />
  </div>
</template>

<script>
	import otherFooter from '../components/footer.vue'
	import api from '../request/api.js'
	import {formatDateChina} from '../utils/formatDate.js'
	export default{
		components:{
			otherFooter
		},
		data(){
			return{
				pageType:'other',
				search_result:'',
				certificateObject:{}
			}
		},
		watch:{
			$route(to,from){
				this.search_result=this.$route.query.itemName
			},
			
		},
		mounted() {
			this.search_result=this.$route.query.itemName.value?this.$route.query.itemName.value:this.$route.query.itemName
			console.log(this.search_result)
			this.getItemCertificateByName(this.search_result)
		},
		methods: {
			getItemCertificateByName(itemName){
				api.getItemCertificateByName({itemName}).then(res=>{
					this.certificateObject=res.certificateDataDo
					this.certificateObject.finishTime=formatDateChina(parseInt(this.certificateObject.finishTime))
					
				})
			},
			toCertificateQuery(){
				this.$router.replace('/certificateQuery')
			},
			querySearchAsync(queryString, cb) {
				let data={
					itemName:this.search_result
				};
				api.getItemNameListByName(data).then(res=>{
					console.log(res)
					if(res.itemNameList){
						var data = res.itemNameList?res.itemNameList:'';
						data=data.map(da=>({
							value:da
						}))
						console.log(data)
						let results = data?data:'';
						results = queryString
								? results.filter(this.createFilter(queryString))
								: results;
						//cb是回调函数，返回筛选出的结果数据到输入框下面的输入列表
						cb(results);
					}
				})
			
			},
			createFilter(queryString) {
				return (item) => {
					return item.value.toUpperCase().match(queryString.toUpperCase());
				};
			},
			handleSelect(itemName) {
				this.$emit('updateCertificateView',itemName)
				this.getItemCertificateByName(this.search_result)
			}
		}
	}
</script>

<style lang="less" scoped>
	.el-autocomplete{
		// width: 100%;
		// height: 100%;
		// background-color: rgba(255,255,255,0.2);
		width: 85%;
		height: 1.25rem;//50
		//background: rgba(255,255,255,0.2);
		border-radius: 4px;
		font-size: 0.6rem;//24
		font-family: Source Han Sans SC;
		font-weight: 400;
		color: #FEFEFF;
		// line-height: 1.5rem;//60
		// padding-left: 0.75rem;//30
		box-sizing: border-box;
		
	}
	.el-autocomplete >.el-input{
		
		height: 100%;
		border: none;
	}
	.el-autocomplete >.el-input--small .el-input__inner{
		height: 100%;
		border: none;
	}
	.certificateView{
		width: 100%;
		.viewMain{
			width: 100%;
			
			overflow: hidden;
			background:url(../assets/static/viewBj_01.png);
			.main{
				width: 36rem;//1440
				padding-bottom: 60px;
				overflow: hidden;
				margin: 110px auto 0 auto;//110
				.position{
					float: right;
					font-size: 0.4rem;//16
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 0.6rem;//24
					opacity: 0.7;
					margin-top: 0.5rem;//20
					>a{
						text-decoration: none;
						font-size: 0.4rem;//16
						font-family: Source Han Sans SC;
						font-weight: 400;
						color: #FFFFFF;
						cursor: pointer;
					}
				}
				.queryResult{
					width: 23.4rem;//936
					height: 1.5rem;//60
					display: flex;
					justify-content: space-between;
					align-items: center;
					clear: both;
					margin: 2.5rem auto 0 auto;//100
					>p:nth-of-type(1){
						font-size: 0.75rem;//30
						font-family: Source Han Sans SC;
						font-weight: 500;
						color: #FFFFFF;
						width: max-content;
						white-space: nowrap;
						margin-right: 0.5rem;
					}
					>p:nth-of-type(2){
						width: 85%;
						height: 1.5rem;//60
						background: rgba(255,255,255,0.2);
						border-radius: 4px;
						font-size: 0.6rem;//24
						font-family: Source Han Sans SC;
						font-weight: 400;
						color: #FEFEFF;
						line-height: 1.5rem;//60
						padding-left: 0.75rem;//30
						box-sizing: border-box;
					}
				}
				.certificateImg{
					width: 500px;//500
					height: 700px;//700
					padding: 10px;//10
					margin: 1.75rem auto 0 auto;//70
					border: 1px solid RGBA(77, 118, 209, 1);
					position: relative;
					
					.i1{
						margin-right: 45px !important;
					}
					.i2{
						margin-right: 22px !important;
					}
					>h4{
						width: max-content;
						font-size: 12px;//12
						font-family: Source Han Sans SC;
						font-weight: 400;
						color: #505050;
						position: absolute;
						top: 230px;
						left: 50%;
						transform: translateX(-50%);
					}
					>h5{
						width: max-content;
						font-size: 12px;//12
						font-weight: 200;
						color: #505050;
						position: absolute;
						bottom: 60px;
						left: 50%;
						transform: translateX(-50%);
					}
					>img{
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						z-index: 0;
					}
					.cma{
						width: 379px;//379
						height: 292px;//292 
						transform: scale(0.2);
						position: absolute;
						top: 380px;//380
						left: -40px;//40
					}
					>h6{
						position: absolute;
						font-size: 18px;
						left: 50%;
						transform: translate(-50%);
						margin: 165px auto 0 auto;//200
						color: #333333;
					}
					>h2{
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						font-size: 16px;//16
						font-family: Source Han Sans SC;
						font-weight: 500;
						color: #B8843E;
						width: max-content;
						margin: 195px auto 0 auto;//200
					}
					>h3{
						position: absolute;
						top: 301px;//301
						left: 50%;//142
						transform: translateX(-50%);
						font-size: 14px;//14
						font-family: Source Han Sans SC;
						font-weight: bold;
						color: #101010;
						width: max-content;
					}
					.itemName,.number,.mechanism{
						position: absolute;
						width: 100%;
						
						font-size: 12px;//12
						font-family: Source Han Sans SC;
						font-weight: 400;
						color: #505050;
					}
					.itemName{
						display: flex;
						top: 347px;//347
						left: 114px;//114
						>span:nth-child(1){
							margin-right: 80px;//80
						}
						>span:nth-child(2){
							width: 240px;
							//line-height: 14.5px;
							display: block;
						}
					}
					.number{
						top: 380px;//376
						left: 114px;
						>span:nth-child(1){
							margin-right: 56px;//56
						}
					}
					.mechanism{
						display: flex;
						//justify-content: space-around;
						top: 407px;//407
						left: 114px;
						>span:nth-child(1){
							margin-right: 81px;//81
							display: block;
						}
						>span:nth-child(2){
							display: block;
							width: 280px;
						}
						
					}
					
					>p{
						position: absolute;
						bottom: 154px;//154
						right: 120px;//122
						font-size: 12px;//12
						font-family: Source Han Sans SC;
						font-weight: 400;
						color: #101010;
					}
					>span{
						position: absolute;
						width: 30px;//30
						height: 30px;//30
					}
					>span:first-of-type{
						top: -29px;//29
						left: -27px;//27
						border-top: 2px solid RGBA(255, 255, 255, 0.2);
						border-left: 2px solid RGBA(255, 255, 255, 0.2);
					}
					>span:nth-of-type(2){
						top:-29px;
						right: -27px;
						border-top: 2px solid RGBA(255, 255, 255, 0.2);
						border-right: 2px solid RGBA(255, 255, 255, 0.2);
					}
					>span:nth-of-type(3){
						bottom: -29px;
						left: -27px;
						border-bottom: 2px solid RGBA(255, 255, 255, 0.2);
						border-left: 2px solid RGBA(255, 255, 255, 0.2);
					}
					>span:nth-of-type(4){
						bottom: -29px;
						right: -27px;
						border-bottom: 2px solid RGBA(255, 255, 255, 0.2);
						border-right: 2px solid RGBA(255, 255, 255, 0.2);
					}
				}
				
			}
		}
		
		.websiteUrl{
			width: 7rem;//280
			margin:1rem auto 0 auto;//40
			font-size: 0.4rem;//16
			font-family: Source Han Sans SC;
			font-weight: 400;
			color: #FFFFFF;
			text-align: center;
			line-height: 0.925rem;//37
			border-top: 1px rgba(255,255,255,0.34) solid;
			border-bottom: 1px rgba(255,255,255,0.34) solid;
		}
	}
	@import url("../utils/mediaCss/certificateViewPhone.less");
</style>

