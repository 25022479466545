import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BaiduMap from 'vue-baidu-map'
import animated from "animate.css" 
import zh_tran from './utils/lang.js'
import {Pagination,Card,autocomplete,Table,TableColumn,Tooltip,Button} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
//(sessionStorage.getItem('lang')=='t'?'s':sessionStorage.getItem('lang'))||"s"
const i18n = new VueI18n({
	locale:sessionStorage.getItem('lang')||'s',
	messages:{
		's':require('./utils/zh.js'),
		'e':require('./utils/en.js'),
	    't':require('./utils/tc.js')
	}
})


import axios from 'axios'
Vue.use(animated)
Vue.use(Pagination)
Vue.use(Card)
Vue.use(autocomplete)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tooltip)
Vue.use(Button)



Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }
Vue.prototype.axios=axios
Vue.prototype.$zh_tran=zh_tran

Vue.config.productionTip = false
Vue.use(BaiduMap,{
	ak:'gGlvyErP1GbYWsMiEHuvytannCB0qqq4'
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
