<template>
  <div class="certificateQuery">
    <div class="certificateHeader">
      <h4>{{ $t('certificateQuery.title') }}</h4>
      <div class="sousuo">
        <img src="../assets/static/sousuo.png">
        <el-autocomplete
          v-model="searchkey"
          :fetch-suggestions="querySearchAsync"
          :placeholder="$t('index.placeholder')"
          :trigger-on-focus="false"
          @select="handleSelect"
        />
      </div>
    </div>
    <div class="shenji">
      <code-andservice class="codeandservice" />
      <div class="TheLatestAudit">
        <h4>{{ $t('certificateQuery.title2') }}</h4>
        <h3 :class="{disno:lang=='e'}">The Latest Audit</h3>
        <div class="latestItems">
          <div
            v-for="item in latestItemList"
            class="latestItem"
          >
            <img :src="'https://www.eastsec.org.cn/api'+item.itemImg">
            <h5>{{ item.Name }}</h5>
            <p>{{ item.edition }}</p>
            <li @click="toCertificate(item.itemName)">
              <span>{{ $t('certificateQuery.showZS') }}</span>
            </li>
          </div>
        </div>
      </div>
    </div>
    <other-footer
      class="foot"
      :type="pageType"
    />
  </div>
</template>

<script>
	import souSuo from '../components/sousuo.vue'
	import codeAndservice from '../components/codeAndService.vue'
	import scroll from '../utils/scroll.js'
	import otherFooter from '../components/footer.vue'
	import api from '../request/api.js'

	export default {
		components: {
			souSuo,
			codeAndservice,
			otherFooter
		},
		data() {
			return {
				pageType:'other',
				latestItemList: [
					
				],
				searchkey:'',
				lang:sessionStorage.getItem('lang')||'s'
			}
		},
		created(){
			scroll.AddScroll()
			this.getPublishTestRecords('')
		},
		methods: {
			querySearchAsync(queryString, cb) {
				let data={
					itemName:this.searchkey
				};
				api.getItemNameListByName(data).then(res=>{
					console.log(res)
					if(res.itemNameList){
						var data = res.itemNameList?res.itemNameList:'';
						data=data.map(da=>({
							value:da
						}))
						console.log(data)
						let results = data?data:'';
						results = queryString
								? results.filter(this.createFilter(queryString))
								: results;
						//cb是回调函数，返回筛选出的结果数据到输入框下面的输入列表
						cb(results);
					}
				})

			},
			createFilter(queryString) {
				return (item) => {
					return item.value.toUpperCase().match(queryString.toUpperCase());
				};
			},
			handleSelect(itemName) {
				this.$router.push({
					path:'/certificateView',
					query:{
						itemName
					}
				})
			},
			toCertificate(itemName){
				this.$router.push({
					path:'/certificateView',
					query:{
						itemName
					}
				})
			},
			getPublishTestRecords(itemName,pageNum=1,pageSize=8){
				api.getPublishTestRecords({itemName,pageNum,pageSize}).then(res=>{
					this.latestItemList=res.frontItemRecordDoList
					this.latestItemList.forEach(item=>{
						let arr=item.itemName.split('V')
						if(arr.length==2){
							arr[1]='V'+arr[1]
						}
						item.Name=arr[0]?arr[0]:null
						item.edition=arr[1]?arr[1]:null
						
					})
					console.log(this.latestItemList)
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.disno{
		display: none;
	}
	.certificateQuery {
		width: 100%;

		.certificateHeader {
			width: 100%;
			height: 13.75rem;
			overflow: hidden;
			background: url(../assets/static/banner.png);

			>h4 {
				font-size: 1.2rem;//48
				font-family: Source Han Sans SC;
				font-weight: bold;
				color: #FFFFFF;
				width: max-content;
				margin: 4.25rem auto 0 auto;//170
			}

			.sousuo {
				margin: 2.25rem auto 0 auto;//90
			}
			.el-autocomplete{
				width: 92%;
				height: 100%;
				padding-left: 8%;
			}
		}

		.shenji {
			width: 100%;
			padding: 1px;
			box-sizing: border-box;
			background-color: #FFFFFF;

			.codeandservice {
				margin-top: 45px;
			}

			.TheLatestAudit {
				width: 100%;
				// max-width: 36rem;//1440
				// min-width: 50%;
				margin: 1.75rem auto 0 auto;//70
				// @media (max-width:1024px){
				// 	width: 95%;
				// }
				
				>h3 {
					text-transform: uppercase;
					width: max-content;
					margin: 0.125rem auto 0 auto;//5
					font-size: 0.5rem;//30
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #265BDA;
				}

				>h4 {
					width: max-content;
					margin: 0 auto 0 auto;
					font-size: 0.7rem;//50
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
				}
				.latestItems{
					width: 36rem;//1440
					margin: 0 auto;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-around;
					@media (max-width:1024px){
					 	width: 90%;
					 }
					.latestItem {
						//margin-left: 0.75rem;//30
						margin-top: 1.5rem;//60
						//display: inline-block;
						overflow: hidden;
						border-radius: 10px;
						width: 8rem;//320
						height: 9rem;//360
						position: relative;
						background: url(../assets/static/shenji.png);
						>img{
							display: block;
							clip-path: circle();
							width: 2.25rem;//90
							height: 2.25rem;
							margin: 0.5rem auto 0 auto;//20
						}
						>h5{
							font-size: 0.5rem;//24
							font-family: Source Han Sans SC;
							font-weight: 500;
							color: #FEFFFF;
							width: 7.5rem;//300
							
							text-align: center;
							margin: 0.5rem auto 0 auto;//20
						}
						>p{
							font-size: 0.35rem;//16
							font-family: Source Han Sans SC;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 1rem;//40
							height: 1rem;//40
							width: max-content;
							margin: 0 auto 0 auto;
						}
						>li{
							list-style: none;
							width: 3.75rem;//180
							height: 1rem;//40
							background: rgba(255,255,255,0.2);
							border-radius: 4px;
							position: absolute;
							bottom: 14%;
							left: 50%;
							transform: translateX(-50%);
							cursor: pointer;
							line-height: 1rem;//40
							text-align: center;
							//margin: 55px auto 0 auto;
							>span{
								font-size: 0.4rem;//40
								font-family: Source Han Sans SC;
								font-weight: 400;
								color:#FFFFFF;
							}
						}
					}
				}
		


			}
		}
		.foot{
			margin-top: 150px;
		}


		.sousuo {
			max-width: 20rem;//800
			height: 1.5rem;//60
			background: #FFFFFF;
			border: 1px solid #CDCDCD;
			border-radius: 5px;
			position: relative;

			>img {
				position: absolute;
				top: 0.5rem;//20
				left: 0.75rem;//30
				z-index: 3;
			}

			>input {
				position: absolute;
				width: 15rem;//600
				height: 1.5rem;//60
				top: 0;
				left: 0;
				font-size: 0.45rem;//18
				font-family: Source Han Sans SC;
				font-weight: 400;
				color: #999999;
				text-align: left;
				outline: none;
				padding-left: 2.5rem;//100
				border: none;
			}
		}
	}


	@import url('../utils/mediaCss/certificateQueryPhone.less');
</style>
<style>
	.el-autocomplete >.el-input{
		height: 100%;rder: none;
	}
	.el-autocomplete >.el-input--small .el-input__inner{
		height: 100%;
		border: none;
	}
	
</style>
