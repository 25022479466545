<template>
  <div class="tankInformation">
    <header>
      <h2 v-if="$route.query.title=='项目库'||$route.query.title=='Project'">
        {{ $t('tankInformation.projectTitle') }}
      </h2>
      <h2 v-if="$route.query.title=='企业库'||$route.query.title=='Company'">
        {{ $t('tankInformation.companyTitle') }}
      </h2>
      <sou-suo
        class="sousuo"
        @searchKeyup="searchKey"
      />
    </header>
    <main>
      <div class="contentNav">
        <span
          v-for="(navItem,index) in navList"
          :class="{classActive:navItem.active}"
          @click="clickContentNav(navItem)"
        >{{ navItem.title }}</span>
      </div>
      <div class="selectBox">
        <div
          class="address"
          :class="{DivDrops:addressGenduo.isopen}"
        >
          <h2>注册地点:</h2>
          <ul class="province">
            <li
              v-for="(provinceItem,index) in province"
              :class="{active:addressCurrentIndex==index}"
              @click="clickAddres(provinceItem,index)"
            >
              {{ provinceItem }}
            </li>
          </ul>
          <div
            class="genduo"
            @click="clickGenduoAddres"
          >
            <p>{{ addressGenduo.text }}</p>
            <div :class="{drop:addressGenduo.isopen}" />
          </div>
        </div>
        <div
          v-if="$route.query.title=='项目库'||$route.query.title=='Project'"
          class="Line"
        />
        <div
          v-if="$route.query.title=='项目库'||$route.query.title=='Project'"
          class="format"
          :class="{DivDrops:formatGenduo.isopen}"
        >
          <h2>业态:</h2>
          <ul>
            <li
              v-for="(dictionaryItem,index2) in dictionaryTagList"
              :key="dictionaryItem.tagCode"
              :class="{active:formatCurrentIndex==index2}"
              @click="clickFormat(dictionaryItem,index2)"
            >
              {{ dictionaryItem.name }}
            </li>
          </ul>
          <!-- <div
            class="genduo"
            @click="clickGenduoFormat"
          >
            <p>{{ formatGenduo.text }}</p>
            <div :class="{drop:formatGenduo.isopen}" />
          </div> -->
        </div>
        <!-- <div
          v-if="$route.query.title=='项目库'||$route.query.title=='Project'"
          class="Line"
        /> -->
       <!-- <div
          v-if="$route.query.title=='项目库'||$route.query.title=='Project'"
          class="operate"
        >
          <h2>运营状态:</h2>
          <ul>
            <li
              v-for="(operateItem,index3) in operateList"
              :class="{active:operateCurrentIndex==index3}"
              @click="clickOperate(operateItem,index3)"
            >
              {{ operateItem }}
            </li>
          </ul>
        </div> -->
        <!-- <div class="Line"  v-if="$route.query.title=='项目库'"></div> -->
        <!-- <div class="registrationTime"  v-if="$route.query.title=='项目库'">
					<h2>注册时间:</h2>
					<ul>
						<li @click="clickTime(rigistItem,index4)" :class="{active:timeCurrentIndex==index4}"
							v-for="(rigistItem,index4) in registrationTimeList">{{rigistItem}}</li>
					</ul>
				</div> -->
      </div>
      <!-- <filter-bar></filter-bar> -->
      <!-- 项目库 -->
      <el-table
        v-if="$route.query.title=='项目库'||$route.query.title=='Project'"
        :data="tableData"
        max-height="1040px"
        :row-style="{height:'80px'}"
        style="width: 75%"
        :row-class-name="tableRowClassName"
        :header-row-style="{height: '80px',background: '#EEF6FF',borderRadius: '10px 10px 0px 0px'}"
        @row-click="rowClick"
      >
        <el-table-column
          prop="name"
          :label="$t('tankInformation.tableName')"
          width="220"
          align="center"
        />
       <el-table-column
          prop="icpNo"
          :label="$t('tankInformation.tableAbbreviation')"
          width="240"
          align="center"
        />
        <el-table-column
          prop="status"
          :label="$t('tankInformation.tableStatus')"
          align="center"
        />
        <el-table-column
          prop="onlineDate"
          :label="$t('tankInformation.tableOnlineTime')"
          align="center"
        />
        <el-table-column
          prop="companyName"
          :label="$t('tankInformation.companyName')"
          align="center"
        />
      </el-table>
      <!-- 企业库 -->
      <el-table
        v-if="$route.query.title=='企业库'||$route.query.title=='Company'"
        :data="companyData"
        max-height="1040px"
        :row-class-name="tableRowClassName"
        :row-style="{height:'80px'}"
        style="width: 75%"
        :header-row-style="{height: '80px',background: '#EEF6FF',borderRadius: '10px 10px 0px 0px'}"
        @row-click="rowClick"
      >
        <el-table-column
          prop="companyName"
          :label="$t('tankInformation.tableName')"
          width="220"
          align="center"
        />
        <el-table-column
          prop="legalPerson"
          :label="$t('tankInformation.legalPerson')"
          width="180"
          align="center"
        />
        <el-table-column
          prop="status"
          :label="$t('tankInformation.tableStatus')"
          align="center"
        />
        <el-table-column
          prop="createTime"
          :label="$t('tankInformation.Time')"
          align="center"
        />
        <el-table-column
          prop="registeredFund"
          :label="$t('tankInformation.registeredCapital')"
          align="center"
        />
        <el-table-column
          prop="city"
          :label="$t('tankInformation.tableAddress')"
          align="center"
        />
      </el-table>

      <div class="totalRecords">
        {{ $t('tankInformation.total') }}: {{ totalRecords>1000?'1000+':totalRecords }}
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="CurrentPage"
        :page-size="12"
        :page-count="totalPages"
        :hide-on-single-page="true"
        @current-change="currentChange"
      />
    </main>
    <other-footer :type="pageType" />
  </div>
</template>

<script>
	import souSuo from '../components/sousuo.vue'
	import otherFooter from '../components/footer.vue'
	import api from '../request/api.js'
	import filterBar from '../components/filterBarPhone.vue'
	import {
		msFormatDateYear
	} from '../utils/formatDate.js'
	let that = this
	
	export default {
		components: {
			souSuo,
			otherFooter,
			filterBar
		},
		data() {
			return {
				title:'',
				addressCurrentIndex: 0,
				formatCurrentIndex: 0,
				operateCurrentIndex: 0,
				timeCurrentIndex: 0,
				CurrentPage:1,
				pageType: 'other',
				navList: [{
						title: this.$t('tankInformation.projectTitle'),
						active: false
					},
					{
						title: this.$t('tankInformation.companyTitle'),
						active: false
					}
				],
				addressGenduo: {
					text: '更多',
					isopen: false
				},
				formatGenduo: {
					text: '更多',
					isopen: false
				},
				//地区
				province: [
					'不限',
					'北京',
					'天津',
					'上海',
					'重庆',
					'河北',
					'山西',
					'辽宁',
					'吉林',
					'黑龙江',
					'江苏',
					'浙江',
					'安微',
					'福建',
					'江西',
					'山东',
					'河南',
					'湖南',
					'湖北',
					'广东',
					'海南',
					'四川',
					'贵州',
					'云南',
					'陕西',
					'甘肃',
					'青海',
					'台湾',
					'内蒙古',
					'广西',
					'西藏',
					'宁夏',
					'新疆',
					'香港',
					'澳门'
				],
				//业态列表
				dictionaryTagList: [],
				//运营状态
				operateList: [
					'不限',
					'在业',
					'存续',
					'注销',
					'吊销',
					'迁出',
				],
				registrationTimeList: [
					'2015年以前',
					'2016年',
					'2017年',
					'2018年',
					'2019年',
					'2020年',
					'2021年'
				],
				//项目数据
				tableData: [],
				//企业数据
				companyData: [],
				totalPages: 0,
				totalRecords: 0,
				selectProvince:'',
				selectKeyword:'',
				selectTagcode:'',
				// selectProject: {
				// 	"province": "",
				// 	"onlineTime": "",
				// 	"keyword": "",
				// 	"status": "",
				// 	"name": "",
				// 	"regtime": "",
				// 	"endtime": "",
				// 	"tagCode": ""
				// },
				selectCompanyProvince:'',
				selectCompanyKeyword:'',
				//深克隆出来的项目列表
				deepClonePorject:[],
				//深克隆出来的企业列表
				deepCloneCompany:[]
				
			}
		},
		watch:{
			$route(to,from){
				this.title=this.$route.query.title
				
			},
			title(){
				if (this.title == '项目库'||this.title=='Project') {
					this.getProjectSearchList(this.selectProject)
					this.CurrentPage=1
				} else if (this.title == '企业库'||this.title=="Company") {
					this.postCompanySearchList(this.selectCompany)
					this.CurrentPage=1
				}
				this.navList.forEach(item => {
					item.active = false
					if (item.title == this.title) {
						item.active = true
					}
				})
			}
		},
		created() {
			that = this
			this.title=this.$route.query.title
			this.navList.forEach(item => {
				item.active = false
				if (item.title == this.title) {
					item.active = true
				}
			})
			this.getDictionaryTagList();
			if (this.$route.query.title == '项目库'||this.title=="Project") {
				this.getProjectSearchList()
			} else if (this.$route.query.title == '企业库'||this.title=="Company") {
				this.postCompanySearchList(this.selectCompany)
			}
			
		},
		methods: {
			//获取业态
			getDictionaryTagList() {
				api.getDictionaryTagList().then(res => {
					this.dictionaryTagList = res.data
					
					this.dictionaryTagList.unshift({
						name: '全部',
						code: ''
					})
					
				})
			},
			tableRowClassName ({row, rowIndex}) {
				//把每一行的索引放进row
				row.index = rowIndex;
			},
			//点击行
			rowClick(row) {
				
				let index=row.index
				if(this.$route.query.title=='项目库'||this.$route.query.title=='Project'){
					this.$router.push({
						name: 'tankInformationItem',
						params: this.deepClonePorject[index]
					})
				}else{
					this.$router.push({
						name: 'tankInformationItemCompany',
						params: this.deepCloneCompany[index]
					})
				}
				
				
			},
			//查询项目方法
			getProjectSearchList(province,keyword,tagCode,pageNum = 1, pageSize = 12) {
				api.getProjectSearchList({
					pageNum,
					pageSize,
					province,
					keyword,
					tagCode
				}).then(res => {
					this.totalPages = res.totalPages
					this.totalRecords = res.totalRecords
					this.tableData = res.data
					this.tableData.forEach(item=>{
						if(!item.icpNo||item.icpNo=='None'){
							item.icpNo='-'
						}
					})
					
					//深克隆一下
					this.deepClonePorject=JSON.parse(JSON.stringify(this.tableData))
					this.tableData.forEach(item=>{
						item.onlineTime=item.onlineTime?msFormatDateYear(item.onlineTime):''
					})
					this.$zh_tran(sessionStorage.getItem('lang'))
				})
			},
			//查询企业方法
			postCompanySearchList(province,keyword,pageNum = 1, pageSize = 12, sort = 'SCOREDESC') {
				api.postCompanySearchList({
					pageNum,
					pageSize,
					sort,
					province,
					keyword
				}).then(res => {
					if(res.totalPages>125){
						this.totalPages=125
					}else{
						this.totalPages=res.totalPages
					}
					
					this.totalRecords = res.totalRecords
					this.companyData = res.data
				
					//深克隆一下
					this.deepCloneCompany=JSON.parse(JSON.stringify(this.companyData))
					this.companyData.forEach(item=>{
						item.createTime=item.createTime?msFormatDateYear(item.createTime):''
					})
				})
			},
			//更多地址操作
			clickGenduoAddres() {
				if (!this.addressGenduo.isopen) {
					this.addressGenduo.isopen = true
					this.addressGenduo.text = '收起'
				} else if (this.addressGenduo.isopen) {
					this.addressGenduo.isopen = false
					this.addressGenduo.text = '更多'

				}
			},
			//更多业态操作
			clickGenduoFormat() {
				if (!this.formatGenduo.isopen) {
					this.formatGenduo.isopen = true
					this.formatGenduo.text = '收起'
				} else if (this.formatGenduo.isopen) {
					this.formatGenduo.isopen = false
					this.formatGenduo.text = '更多'
				}
			},
			searchKey(val){
				if(this.$route.query.title=='项目库'||this.$route.query.title=='Project'){
					this.selectKeyword=val
					this.getProjectSearchList(this.selectProvince,this.selectKeyword,this.selectTagcode)
					this.CurrentPage=1
				}else{
					this.selectCompanyKeyword=val
					this.postCompanySearchList(this.selectCompanyProvince,this.selectCompanyKeyword)
					this.CurrentPage=1
				}
			},
			//点击某一个地址重新查询
			clickAddres(provinceItem, index) {
				this.addressCurrentIndex = index
				if(this.$route.query.title=='项目库'||this.$route.query.title=='Project'){
					this.selectProvince=provinceItem=='不限'?'':provinceItem
					this.getProjectSearchList(this.selectProvince,this.selectKeyword,this.selectTagcode)
					this.CurrentPage=1
				}else{
					this.selectCompanyProvince=provinceItem=='不限'?'':provinceItem
					this.postCompanySearchList(this.selectCompanyProvince,this.selectCompanyKeyword)
					this.CurrentPage=1
				}
			},
			//点击某一个业态重新查询
			clickFormat(dictionaryItem, index2) {
				this.formatCurrentIndex = index2
				if(this.$route.query.title=='项目库'||this.$route.query.title=='Project'){
					this.selectTagcode=dictionaryItem.code
					this.getProjectSearchList(this.selectProvince,this.selectKeyword,this.selectTagcode)
					this.CurrentPage=1
				}
			},
			//点击某一个运营状态重新查询
			// clickOperate(operateItem, index3) {
			// 	this.operateCurrentIndex = index3
			// 	if(this.$route.query.title=='项目库'||this.$route.query.title=='Project'){
			// 		this.selectProject.status=operateItem=='不限'?'':operateItem
			// 		this.postProjectSearchList(this.selectProject)
			// 		this.CurrentPage=1
			// 	}
			// },
			//点击某一个注册时间
			clickTime(rigistItem, index4) {
				this.timeCurrentIndex = index4
				if(this.$route.query.title=='项目库'||this.$route.query.title=='Project'){
					this.selectProject.regtime=rigistItem
					this.postProjectSearchList(this.selectProject)
					this.CurrentPage=1
				}
			},
			currentChange: (index) => {
				if (that.$route.query.title == '项目库'||that.$route.query.title=='Project') {
					that.getProjectSearchList(that.selectProvince,that.selectKeyword,that.selectTagcode, index)
				
				}else{
					
					that.postCompanySearchList(that.selectCompanyProvince,that.selectCompanyKeyword,index)
				}
			},
			//项目库企业库切换
			clickContentNav(Navitem){
				this.navList.forEach(item=>{
					item.active=false
				})
				Navitem.active=true
				this.$emit('updateTankInfomation',Navitem.title)
			}
		}
	}
</script>

<style lang="less" scoped>
	.totalRecords {
		font-size: 0.45rem;//18
		font-family: Source Han Sans SC;
		font-weight: 500;
		color: #2459D9;
		line-height: 0.6rem;//24
		margin: 0.75rem 0 0 6rem;//30 240
	}
	
	.el-table {
		width: 36rem !important;//1440
		margin: 1.65rem auto 0;//66
	}
	/deep/ .el-table-column{
		width: 80px !important;
	}

	.el-pagination {
		// margin: 20px auto 0;
		display: flex;
		justify-content: center;
		margin-top: 0.5rem;//20
	}

	li {
		list-style: none;
		display: inline-block;
		font-size: 0.4rem;//16
		font-family: Source Han Sans SC;
		font-weight: 400;
		color: #666666;
		cursor: pointer;
	}

	.tankInformation {
		width: 100%;

		header {
			overflow: hidden;
			width: 100%;
			height: 13.75rem;//550
			background: url(../assets/static/zkbanner.png);

			>h2 {
				font-size: 1.2rem;//48
				font-family: Source Han Sans SC;
				font-weight: bold;
				color: #FFFFFF;
				width: max-content;
				margin: 4.25rem auto 0 auto;//170
			}

			.sousuo {
				margin: 1.875rem auto 0 auto;//75
			}
		}

		main {
			width: 100%;
			padding: 1px 1px 30px 1px;
			box-sizing: border-box;
			background-color: #F8F8F8;
			position: relative;

			.contentNav {
				position: absolute;
				top: -1rem;//40
				left: 50%;
				transform: translateX(-50%);
				width: 36rem;//1440
				height: 2rem;//80
				line-height: 2rem;//80
				background-color: #FFFFFF;
				box-shadow: 0px 0px 18px 0px rgba(184, 196, 204, 0.15);
				text-align: center;

				>span {
					cursor: pointer;
					margin: 0 5%;
					padding: 0 0.125rem;//5
					font-size: 0.45rem;//18
					font-family: PingFang SC;
					font-weight: bold;
					padding-bottom: 0.575rem;//23
					color: #999999;
					width: 1rem;//40
					text-align: left;
				}

				.classActive {
					position: relative;
					color: #2459D9 !important;
				}

				.classActive::after {
					content: '';
					position: absolute;
					background-color: #2459D9;
					bottom: 0;
					left: 50%;
					width: 50%;
					transform: translateX(-50%);
					height: 0.075rem;
				}

			}

			.selectBox {
				width: 36rem;//1440
				padding: 0.025rem 0 0.75rem 0;//1 30
				margin: 2.5rem auto 0 auto;//100
				background: #FFFFFF;
				box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
				border-radius: 10px;

				.active {
					color: #2459D9;
				}

				.Line {
					width: 83%;
					height: 1px;
					margin: 0 auto 0 auto;
					clear: both;
					box-sizing: border-box;
					border-bottom: 2px solid #E6E6E6;
				}

				.Line:nth-child(2) {
					padding-top: 0.75rem;//30
				}

				.Line:nth-child(4) {
					padding-top: 0.75rem;
				}

				h2 {
					font-size: 0.4rem;//16
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
				}

				.genduo {
					float: right;
					display: flex;
					justify-content: space-between;
					width: 40px;//40
					height: 0.35rem;//14
					cursor: pointer;
					>p {
						font-size: 0.3rem;//12
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #5C6266;

					}

					>div {
						width: 16px;//16
						height: 16px;//16
						background: url(../assets/static/xyy.png);
						background-repeat: no-repeat;
						transform: scale(0.6) rotate(270deg);
						background-position: center;
					}

					.drop {
						transform: scale(0.6) rotate(90deg) !important;
						transition: 0.5s;
					}
				}

				.DivDrops {
					height: max-content !important;
				}

				.address {
					width: 95%;
					height: 20px;
					transition: 0.5s;
					overflow: hidden;
					margin: 44px auto 0 auto;

					>h2 {
						float: left;
						width: max-content;
					}

					>ul {
						float: left;
						width: 85%;
						margin-left: 10px;
						line-height: 0.58rem;
						//margin-top: 0.01rem;
						>li {
							margin-right: 20px;

						}
					}
				}

				.format {
					width: 95%;
					height: 22px;
					line-height: 20px;
					margin: 30px auto 0 auto;
					overflow: hidden;

					>h2 {
						float: left;
						width: max-content;
					}

					>ul {
						float: left;
						width: 85%;
						margin-left: 34px;//34
						>li {
							margin-right: 20px;
						}
					}
				}

				.operate {
					width: 95%;
					height: 50px;
					margin: 30px auto 0 auto;
					
					>h2 {
						float: left;
						width: max-content;
					}

					>ul {
						float: left;
						width: 85%;
						margin-left: 10px;
						line-height: 0.58rem;
						margin-top: 0.01rem;
						
						>li {
							margin-right: 20px;
						}
					}
				}

				.registrationTime {
					width: 95%;
					height: 50px;
					margin: 30px auto 0 auto;

					>h2 {
						float: left;
						width: max-content;
					}

					>ul {
						float: left;
						width: 85%;
						margin-left: 10px;
						line-height: 16px;
						>li {
							margin-right: 20px;
						}
					}
				}

			}

		}
	}
	
	
	
	@import url("../utils/mediaCss/tankInformationPhone.less");
</style>
