<template>
  <div class="centerIntroduction">
    <header>
      <h2>{{ $t('test.title') }}</h2>
      <h3 :class="{disno:lang=='e'}">Center is introduced</h3>
    </header>
    <main>
      <div class="Mains">
        <h2>{{ $t('test.background') }}</h2>
        <p class="clContext">
          {{ $t('test.backgroundRemark') }}
        </p>
        <div class="clImg">
          <img src="../assets/static/center1.png">
          <img src="../assets/static/center2.png">
        </div>
        <div class="centers">
          <div class="centerLeft">
            <h2>{{ $t('test.centerIntroductionTitle') }}</h2>
            <p class="fist">
              {{ $t('test.centerIntroduction1') }}
            </p>
            <p class="fist2">
              {{ $t('test.centerIntroduction2') }}
            </p>
            <p class="fist3">
              {{ $t('test.centerIntroduction3') }}
            </p>
          </div>
          <div class="centerRight">
            <img src="../assets/static/center3.png">
          </div>
        </div>
      </div>
    </main>
    <other-footer />
  </div>
</template>

<script>
	import otherFooter from '../components/footer.vue'
	export default{
		components:{
			otherFooter
		},
		data() {
			return{
				lang:sessionStorage.getItem('lang')||'s'
			}
		},
		methods:{
			
		}
		
	}
</script>

<style lang="less" scoped>
	.disno{
		display: none;
	}
	.centerIntroduction{
		width: 100%;
		header{
			width: 100%;
			height: 13.75rem;//550
			overflow: hidden;
			background: url(../assets/static/bj.png);
			background-size: cover;
			>h2{
				width: max-content;
				margin: 5rem auto 0;//200
				font-size: 1.3rem;
				font-family: Source Han Sans SC;
				font-weight: bold;
				color: #FFFFFF;
			}
			>h3{
				width: max-content;
				text-transform: uppercase;
				margin: 0.25rem auto 0;
				font-size: 0.6rem;
				font-family: Roboto;
				font-weight: 400;
				color: #FFFFFF;
			}
		}
		main{
			width: 100%;
			background-color: #FFFFFF;
			overflow: hidden;
			padding-bottom: 2.5rem;
			.Mains{
				width: 36rem;//1440
				overflow: hidden;
				margin: 0 auto;
				h2{
					width: max-content;
					position: relative;
					font-size: 0.6rem;
					margin: 1.25rem 0 0 1rem;
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
					line-height: 0.6rem;
					&:before{
						content: '';
						position: absolute;
						width: 0.5rem;
						height: 0.5rem;
						background: #235FD6;
						top: 0.1rem;
						left: -1rem;
					}
				}
				p{
					font-size: 0.45rem;
					font-family: Source Han Sans SC;
					font-weight: 400;
					text-align: justify;
					color: #666666;
					line-height: 0.85rem;
					margin-top: 1.25rem;
				}
				.clContext{
					width: 100%;
					margin-top: .5rem;
					text-indent:35px;
				}
				.clImg{
					margin-top: 1.25rem;
					display: flex;
					justify-content: center;
					>img{
						width: 13.5rem;
						height: 8rem;
						margin-left: 0.75rem;
					}
				}
				.centers{
					margin-top: 2rem;//80
					display: flex;
					justify-content: space-between;
					align-items: center;
					.centerLeft{
						width: 22.95rem;//918
						
							.fist{
								margin-top: .5rem !important;
								text-indent:35px;
							}
							.fist2{
								text-indent:35px;
							}
							.fist3{
								text-indent:35px;
							}
					}
					.centerRight{
						>img{
							width: 9.5rem;//380
							height: 14.5rem;//580
						}
					}
					
				}
			}
		}
	}
	@import  url("../utils/mediaCss/testPhone.less");
</style>
