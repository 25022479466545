module.exports={
	routerList:{
		title1:'首页',
		title2:'服务类型',
		title3:'证书查询',
		title4:'智库资讯',
		title5:'新闻动态',
		title6:'关于我们',
		title2child1:'',
		title2child2:'',
		title2child3:'',
		title2child4:'',
		title4child1:'项目库',
		title4child2:'企业库',
		title5child1:'新闻公告',
		title5child2:'热点舆情',
		title5child3:'行业报告',
		title5child4:'专家专栏',
		title5child5:'漏洞库',
		title6child1:'中心介绍',
		title6child2:'联系我们',
		childName:'{childName}'
	},
	index:{
		headerText:'东方区块链安全技术检测中心',
		headerText2:'公平 公正 权威 开放',
		fuwuTitle:'服务类型',
		serviceTypeTitle1:'软件产品测评',
		serviceTypeTitle2:'区块链产品测评',
		serviceTypeTitle3:'区块链安全评估',
		serviceTypeTitle4:'区块链行业安全监测',
		serviceTypeContent1:'依据被测系统采购合同、建设方案或需求规格说明书等规定的技术要求，对测试需求中规定的被测系统的各项特性进行测试，测试类型支持委托测试、验收测试、鉴定测试、成本评估等，可出具国家认可的CMA第三方测试报告。 支持的产品类型：移动APP、桌面软件、WEB网站、小程序、微信公众号、数据库、操作系统等 支持的测评类型：文档集、功能性、性能、兼容性、可靠性、易用性、维护性、可移植性、安全性检测（源代码安全审计、黑盒渗透测试、主机安全扫描）。',
		serviceTypeContent2:'围绕区块链的网络安全、底层技术、BAAS平台以及上层应用的功能、性能及安全，面向行业提供区块链项目的检测服务，可出具国家CMA认证认可资质的第三方检测。目前已完成蚂蚁区块链BaaS平台、杭州趣链区块链平台、中电云网区块链、复杂美区块链平台、腾讯区块链平台以及浪潮集团质量链等国内多个主流区块链的项目检测并发放了检测证书。 支持的产品类型：BAAS平台、公链/联盟链、智能合约、钱包等 支持的测评类型：功能性检测 、性能检测、安全性检测（源代码安全审计、黑盒渗透测试、节点主机安全扫描）。',
		serviceTypeContent3:'安全评估：在招商引资或投资过程中很难鉴别区块链企业或项目的真假，可能存在较大安全隐患。我们从企业和项目的多个维度进行全面的风险评估，并出具风险评估报告，提前发现项目的风险情况。目前已为数百个企业、项目进行了安全评估，得到了政府领导的高度认可。 安全检查：配合有关部门对区块链项目进行安全检查工作，并出具检查结果报告，一方面可以帮助企业发现安全漏洞防止经济损失，另一方面通过检查可了解项目真实的情况，包括功能点、业务模式等，以规范和促进区块链技术服务健康发展，为区块链信息服务的提供、使用和管理等提供有效的法律依据。',
		serviceTypeContent4:'平台对全国区块链企业和全球区块链项目实行7*24小时全方位、高动态的风险监测，发现风险及时预警；并对主流公链进行实时交易和节点监控；满足地方政府、各省市网信办、金融监管部门、执法机构以及区块链产业园区等对区块链行业进行监管的需求，确保区块链产业发展有序，业务范围可定制。',
		processTitle:'检测流程',
		process1:'需求确认',
		process2:'方案确认',
		process3:'报价确认',
		process4:'合同签订',
		process5:'技术检测',
		process6:'报告出具',
		process7:'证书颁发',
		certificateTitle:'证书查询',
		seeMore:'查看更多',
		placeholder:'请输入关键字',
		daimasj:'代码审计',
		yewufw:'业务范围',
		daimaContent:'代码审计是指安全代码评估者尽可能的通过阅读应用系统的源代码来发现潜在的安全漏洞及隐患的技术手段。代码审计的技术手段，可以弥补黑盒渗透测试的未能完全覆盖的漏洞环节与安全隐患，是一种可靠性、安全性最高的修补漏洞的方法。 区块链代码审计云平台目前提供审计的类型为数字货币、智能合约。',
		yewuContent:'围绕网络安全、区块链底层技术和上层应用的安全，面向行业开展区块链安全检测工作，其中项目检测包括7个方面：公链联盟链安全检测、智能合约安全检测、钱包安全检测、APP安全检测、Web网站安全检测、系统渗透、日常监测。',
		newsTitle:'新闻公告',
		IndustryTitle:'行业动态',
		PartnersTitle:'合作伙伴',
		contactUs:'联系我们',
		workHours:'工作时间：(周一至周五9:00-17:00)',
		dizhi:'联系地址：湖南省长沙市天华北路260号星沙区块链产业园',
		phone:'联系电话',
		email:'电子邮箱',
		guanfang:'【官方声明】作为区块链生态中的第三方独立安全机构，专注区块链生态安全，不为任何项目背书或站台。一切背书或站台旗号的项目，都不是官方授权，我们将保留法律追究的权利！',
		banquan:'湖南省东方区块链安全技术检测中心版权所有',
		lianxiEmail:'联系邮箱：info@eastsec.org.cn',
		zuixingongao:'最新公告',
		weixin2wm:'微信公众号',
		beian:'湘ICP备2021014390号-1'
	},
	webDetection:{
		name:'{name}',
		remark:'{remark}',
		categoryName:'{categoryName}',
		itemRemark:'{itemRemark}',
		childName:'{childName}',
		parentName:'{parentName}',
		detail:'详细'
	},
	certificateQuery:{
		title:'检测证书查询',
		title2:'最新审计展示',
		showZS:'查看证书'
	},
	certificateView:{
		weizhi:'您的位置',
		zscx:'证书查询',
		ckzs:'查看证书',
		result:'查询结果',
		guanwang:'官网'
	},
	tankInformation:{
		title:'{title}',
		projectTitle:'项目库',
		companyTitle:'企业库',
		placeholder:'请输入关键字查询',
		tableName:'名称',
		tableAbbreviation:'备案号',
		tableStatus:'状态',
		tableOnlineTime:'上线时间',
		companyName:'公司名称',
		legalPerson:'法人',
		Time:'成立时间',
		registeredCapital:'注册资金',
		tableAddress:'地址',
		total:'统计总数'
	},
	tankInformationItem:{
		onlineTime:'上线时间',
		Status:'状态',
		projectDetails:'项目详情',
		icpNo:'备案号',
		website:'项目网址',
		projectDescription:'项目描述',
		belongTo:'所属企业',
		companyName:'企业名称',
		legalPerson:'企业法人',
		address:'注册地点',
		capital:'注册资本',
		companyStatus:'企业状态',
		companyWebsite:'公司网址'
	},
	tankInformationItemCompany:{
		clTime:'成立时间',
		Status:'状态',
		NatureBusiness:'经营范围',
		BusinessDetails:'企业详情',
		legalPerson:'企业法人',
		address:'注册地点',
		capital:'注册资本',
		companyStatus:'企业状态',
		companyWebsite:'公司网址'
	},
	newsBulletin:{
		title:'{zhTitle}',
		nodata:'暂无数据',
		newsDetail:'新闻详情',
		ljgd:'了解更多'
	},
	test:{
		title:'中心介绍',
		background:'成立背景',
		backgroundRemark:'  针对区块链领域安全问题日益突出的现状，国家互联网应急中心作为我国网络安全应急体系的核心协调机构，于2018年10月与长沙经济技术开发区管理委员会联合发起成立了“区块链安全技术检测中心”，2020年3月在湖南省民政厅正式注册为民办非企业单位（社会服务机构），并冠名“湖南省东方区块链安全技术检测中心”（简称“东方链安”或“检测中心”），业务主管单位为中共湖南省委网络安全和信息化委员会办公室。',
		centerIntroductionTitle:'中心介绍',
		centerIntroduction1:'  东方链安是国内首家也是目前唯一一家获得区块链安全测评CMA认证认可资质的第三方检测机构。主要业务范围涵盖软件产品测评、区块链产品测评、区块链安全评估等，同时针对区块链安全事件开展预防、监测、预警和协调处置等工作，确保区块链产业安全有序发展。',
		centerIntroduction2:'  东方链安先后完成了蚂蚁区块链平台、趣链区块链平台、浪潮质量链等项目的检测，取得了良好的社会影响，得到了市场的广泛认可。由东方链安联合起草的6项湖南省区块链安全技术测评标准已正式发布实施，并正在推动成为国家标准。',
		centerIntroduction3:'  东方链安的发展得到了各级政府的高度关注和大力支持，湖南省省长毛伟明、省委统战部部长黄兰香、常务副省长谢建辉、长沙市委书记吴桂英、市长郑建新以及长沙市委原书记胡衡华、原市长胡忠雄等省市领导多次莅临考察指导，给予高度评价并寄予厚望。'
	},
	programTestingItem:{
		znhy:'智能合约检测',
		gllm:'公链/联盟链检测',
		qb:'钱包检测',
		jclm:'检测栏目',
		tj:'推荐',
		zqjc:'增强检测',
		qmjc:'全面检测',
		jcjc:'基础检测',
		item1:'项增强检测',
		item2:'项全面检测',
		item3:'项基础检测'
	}
}