module.exports={
	routerList:{
		title1:'首頁',
		title2:'服務類型',
		title3:'證書查詢',
		title4:'智庫資訊',
		title5:'新聞動態',
		title6:'關于我們',
		title2child1:'',
		title2child2:'',
		title2child3:'',
		title2child4:'',
		title4child1:'項目庫',
		title4child2:'企業庫',
		title5child1:'新聞公告',
		title5child2:'熱點輿情',
		title5child3:'行業報告',
		title5child4:'專家專欄',
		title5child5:'漏洞庫',
		title6child1:'中心介紹',
		title6child2:'聯系我們',
		childName:'{childTCName}'
	},
	index:{
		headerText:'東方區塊鏈安全技術檢測中心',
		headerText2:'公平 公正 權威 開放',
		fuwuTitle:'服務類型',
		serviceTypeTitle1:'軟件産品測評',
		serviceTypeTitle2:'區塊鏈産品測評',
		serviceTypeTitle3:'區塊鏈安全評估',
		serviceTypeTitle4:'區塊鏈行業安全監測',
		serviceTypeContent1:'依據被測系統采購合同、建設方案或需求規格說明書等規定的技術要求，對測試需求中規定的被測系統的各項特性進行測試，測試類型支持委托測試、驗收測試、鑒定測試、成本評估等，可出具國家認可的CMA第三方測試報告。 支持的産品類型：移動APP、桌面軟件、WEB網站、小程序、微信公衆號、數據庫、操作系統等 支持的測評類型：文檔集、功能性、性能、兼容性、可靠性、易用性、維護性、可移植性、安全性檢測（源代碼安全審計、黑盒滲透測試、主機安全掃描）。',
		serviceTypeContent2:'圍繞區塊鏈的網絡安全、底層技術、BAAS平台以及上層應用的功能、性能及安全，面向行業提供區塊鏈項目的檢測服務，可出具國家CMA認證認可資質的第三方檢測。目前已完成螞蟻區塊鏈BaaS平台、杭州趣鏈區塊鏈平台、中電雲網區塊鏈、複雜美區塊鏈平台、騰訊區塊鏈平台以及浪潮集團質量鏈等國內多個主流區塊鏈的項目檢測並發放了檢測證書。 支持的産品類型：BAAS平台、公鏈/聯盟鏈、智能合約、錢包等 支持的測評類型：功能性檢測 、性能檢測、安全性檢測（源代碼安全審計、黑盒滲透測試、節點主機安全掃描）。',
		serviceTypeContent3:'安全評估：在招商引資或投資過程中很難鑒別區塊鏈企業或項目的真假，可能存在較大安全隱患。我們從企業和項目的多個維度進行全面的風險評估，並出具風險評估報告，提前發現項目的風險情況。目前已爲數百個企業、項目進行了安全評估，得到了政府領導的高度認可。 安全檢查：配合有關部門對區塊鏈項目進行安全檢查工作，並出具檢查結果報告，一方面可以幫助企業發現安全漏洞防止經濟損失，另一方面通過檢查可了解項目真實的情況，包括功能點、業務模式等，以規範和促進區塊鏈技術服務健康發展，爲區塊鏈信息服務的提供、使用和管理等提供有效的法律依據。',
		serviceTypeContent4:'平台對全國區塊鏈企業和全球區塊鏈項目實行7*24小時全方位、高動態的風險監測，發現風險及時預警；並對主流公鏈進行實時交易和節點監控；滿足地方政府、各省市網信辦、金融監管部門、執法機構以及區塊鏈産業園區等對區塊鏈行業進行監管的需求，確保區塊鏈産業發展有序，業務範圍可定制。',
		processTitle:'檢測流程',
		process1:'需求確認',
		process2:'方案確認',
		process3:'報價確認',
		process4:'合同簽訂',
		process5:'技術檢測',
		process6:'報告出具',
		process7:'證書頒發',
		certificateTitle:'證書查詢',
		seeMore:'查看更多',
		placeholder:'請輸入關鍵字',
		daimasj:'代碼審計',
		yewufw:'業務範圍',
		daimaContent:'代碼審計是指安全代碼評估者盡可能的通過閱讀應用系統的源代碼來發現潛在的安全漏洞及隱患的技術手段。代碼審計的技術手段，可以彌補黑盒滲透測試的未能完全覆蓋的漏洞環節與安全隱患，是一種可靠性、安全性最高的修補漏洞的方法。 區塊鏈代碼審計雲平台目前提供審計的類型爲數字貨幣、智能合約。',
		yewuContent:'圍繞網絡安全、區塊鏈底層技術和上層應用的安全，面向行業開展區塊鏈安全檢測工作，其中項目檢測包括7個方面：公鏈聯盟鏈安全檢測、智能合約安全檢測、錢包安全檢測、APP安全檢測、Web網站安全檢測、系統滲透、日常監測。',
		newsTitle:'新聞公告',
		IndustryTitle:'行業動態',
		PartnersTitle:'合作夥伴',
		contactUs:'聯系我們',
		workHours:'工作時間：(周一至周五9:00-17:00)',
		dizhi:'聯系地址：湖南省長沙市天華北路260號星沙區塊鏈産業園',
		phone:'聯系電話',
		email:'電子郵箱',
		guanfang:'【官方聲明】作爲區塊鏈生態中的第三方獨立安全機構，專注區塊鏈生態安全，不爲任何項目背書或站台。一切背書或站台旗號的項目，都不是官方授權，我們將保留法律追究的權利！',
		banquan:'湖南省東方區塊鏈安全技術檢測中心版權所有',
		lianxiEmail:'聯系郵箱：info@eastsec.org.cn',
		zuixingongao:'最新公告',
		weixin2wm:'微信公衆號',
		beian:'湘ICP備2021014390號-1'
	},
	webDetection:{
		name:'{TCname}',
		remark:'{tcRemark}',
		categoryName:'{tcCategoryName}',
		itemRemark:'{tcItemRemark}',
		childName:'{tcChildName}',
		parentName:'{tcParentName}',
		detail:'詳細'
	},
	certificateQuery:{
		title:'檢測證書查詢',
		title2:'最新審計展示',
		showZS:'查看證書'
	},
	tankInformation:{
		title:'{title}',
		projectTitle:'項目庫',
		companyTitle:'企業庫',
		placeholder:'請輸入關鍵字查詢',
		tableName:'名稱',
		tableAbbreviation:'備案號',
		tableStatus:'狀態',
		tableOnlineTime:'上線時間',
		companyName:'公司名稱',
		legalPerson:'法人',
		Time:'成立時間',
		registeredCapital:'注冊資金',
		tableAddress:'地址',
		total:'統計總數'
	},
	tankInformationItem:{
		onlineTime:'上線時間',
		Status:'狀態',
		projectDetails:'項目詳情',
		icpNo:'備案號',
		website:'項目網址',
		projectDescription:'項目描述',
		belongTo:'所屬企業',
		companyName:'企業名稱',
		legalPerson:'企業法人',
		address:'註冊地點',
		capital:'註冊資本',
		companyStatus:'企業狀態',
		companyWebsite:'公司網址'
	},
	tankInformationItemCompany:{
		clTime:'成立時間',
		Status:'狀態',
		NatureBusiness:'經營範圍',
		BusinessDetails:'企業詳情',
		legalPerson:'企業法人',
		address:'註冊地點',
		capital:'註冊資本',
		companyStatus:'企業狀態',
		companyWebsite:'公司網址'
	},
	newsBulletin:{
		title:'{zhTitle}',
		nodata:'暫無數據',
		newsDetail:'新聞詳情',
		ljgd:'了解更多'
	},
	test:{
		title:'中心介紹',
		background:'成立背景',
		backgroundRemark:'針對區塊鏈領域安全問題日益突出的現狀，國家互聯網應急中心作爲我國網絡安全應急體系的核心協調機構，于2018年10月與長沙經濟技術開發區管理委員會聯合發起成立了“區塊鏈安全技術檢測中心”，2020年3月在湖南省民政廳正式注冊爲民辦非企業單位（社會服務機構），並冠名“湖南省東方區塊鏈安全技術檢測中心”（簡稱“東方鏈安”或“檢測中心”），業務主管單位爲中共湖南省委網絡安全和信息化委員會辦公室。',
		centerIntroductionTitle:'中心介紹',
		centerIntroduction1:'  東方鏈安是國內首家也是目前唯一一家獲得區塊鏈安全測評CMA認證認可資質的第三方檢測機構。主要業務範圍涵蓋軟件産品測評、區塊鏈産品測評、區塊鏈安全評估等，同時針對區塊鏈安全事件開展預防、監測、預警和協調處置等工作，確保區塊鏈産業安全有序發展。',
		centerIntroduction2:'  東方鏈安先後完成了螞蟻區塊鏈平台、趣鏈區塊鏈平台、浪潮質量鏈等項目的檢測，取得了良好的社會影響，得到了市場的廣泛認可。由東方鏈安聯合起草的6項湖南省區塊鏈安全技術測評標准已正式發布實施，並正在推動成爲國家標准。',
		centerIntroduction3:'  東方鏈安的發展得到了各級政府的高度關注和大力支持，湖南省省長毛偉明、省委統戰部部長黃蘭香、常務副省長謝建輝、長沙市委書記吳桂英、市長鄭建新以及長沙市委原書記胡衡華、原市長胡忠雄等省市領導多次莅臨考察指導，給予高度評價並寄予厚望。'
	},
	programTestingItem:{
		znhy:'智能合約檢測',
		gllm:'公鏈/聯盟鏈檢測',
		qb:'錢包檢測',
		jclm:'檢測欄目',
		tj:'推薦',
		zqjc:'增強檢測',
		qmjc:'全面檢測',
		jcjc:'基礎檢測',
		item1:'項增強檢測',
		item2:'項全面檢測',
		item3:'項基礎檢測'
	}
}