<template>
  <div class="programTestingItem">
    <div
      v-if="$route.query.categoryCode=='1'"
      class="programTestHeader"
    >
      <h2>{{$t('programTestingItem.znhy')}}</h2>
      <h3 :class="{disno:lang=='e'}">Smart contract detection</h3>
    </div>
    <div
      v-if="$route.query.categoryCode=='2'"
      class="programTestHeader"
    >
      <h2>{{$t('programTestingItem.gllm')}}</h2>
      <h3 :class="{disno:lang=='e'}">Public chain/alliance chain detection</h3>
    </div>
    <div
      v-if="$route.query.categoryCode=='3'"
      class="programTestHeader"
    >
      <h2>{{$t('programTestingItem.qb')}}</h2>
      <h3 :class="{disno:lang=='e'}">Wallet detection</h3>
    </div>
    <div class="programTestMain">
      <table
        border="0"
        cellpadding="0"
        cellspacing="0"
      >
        <tr class="hang">
          <th>{{$t('programTestingItem.jclm')}}</th>
          <th>
            <div class="tuijian">
              <h2>{{$t('programTestingItem.tj')}}</h2>
            </div>
            <img src="../assets/static/jiance.png">
            <h4 class="zq">
              {{$t('programTestingItem.zqjc')}}
            </h4>
            <p>{{ zqTestNum }}{{$t('programTestingItem.item1')}}</p>
          </th>
          <th>
            <img src="../assets/static/jiance2.png">
            <h4 class="qm">
              {{$t('programTestingItem.qmjc')}}
            </h4>
            <p>{{ qmTestNum }}{{$t('programTestingItem.item2')}}</p>
          </th>
          <th>
            <img src="../assets/static/jiance3.png">
            <h4 class="jc">
              {{$t('programTestingItem.jcjc')}}
            </h4>
            <p>{{ jcTestNum }}{{$t('programTestingItem.item3')}}</p>
          </th>
        </tr>
        <tr v-for="(item,index) in SchemeList[0].projectAndIsBelongDataDoList">
          <td>{{ item.name }}</td>
          <td>
            <img
              v-if="SchemeList[2].projectAndIsBelongDataDoList[index].isBelong!='0'"
              src="../assets/static/gou.png"
            >
          </td>
          <td>
            <img
              v-if="SchemeList[1].projectAndIsBelongDataDoList[index].isBelong!='0'"
              src="../assets/static/gou.png"
            >
          </td>
          <td>
            <img
              v-if="SchemeList[0].projectAndIsBelongDataDoList[index].isBelong!='0'"
              src="../assets/static/gou.png"
            >
          </td>
        </tr>
      </table>
    </div>
    <other-footer :type="pageType" />
  </div>
</template>

<script>
	import otherFooter from '../components/footer.vue'
	import api from '../request/api.js'
	
	export default{
		components:{
			otherFooter
		},
		data() {
			return{
				pageType:'other',
				SchemeList:[],
				zqTestNum:0,
				qmTestNum:0,
				jcTestNum:0,
				lang:sessionStorage.getItem('lang')||'s'
			}
		},
		created(){
			this.getSchemeList(this.$route.query.categoryCode)
		},
		methods:{
			getSchemeList(categoryCode){
				api.getSchemeList(categoryCode).then(res=>{
					this.SchemeList=res.schemeAndProjectDataDoList
					console.log(this.SchemeList)
					this.SchemeList[0].projectAndIsBelongDataDoList.forEach(item=>{
						if(item.isBelong!='0'){
							this.jcTestNum++
						}
					})
					this.SchemeList[1].projectAndIsBelongDataDoList.forEach(item=>{
						if(item.isBelong!='0'){
							this.qmTestNum++
						}
					})
					this.SchemeList[2].projectAndIsBelongDataDoList.forEach(item=>{
						if(item.isBelong!='0'){
							this.zqTestNum++
						}
					})
				})
			}
		}
		
	}
</script>

<style lang="less" scoped>
	.disno{
		display: none;
	}
	
	th{
		border-bottom: 2px solid #EEEEEE;
		padding: 20px 0;
	}
	td{
		border-bottom: 2px solid #EEEEEE;
	}
	.programTestingItem{
		width: 100%;
		.programTestHeader{
			width: 100%;
			height: 13.75rem;
			overflow: hidden;
			background: url(../assets/static/banner_1.png);
			>h2{
				font-size: 1.2rem;//48
				font-family: Source Han Sans SC;
				font-weight: bold;
				color: #FFFFFF;
				width: max-content;
				margin: 5.825rem auto 0 auto;//23
			}
			>h3{
				font-size: 0.6rem;//24
				font-family: Roboto;
				font-weight: 400;
				color: #FFFFFF;
				width: max-content;
				margin: 0.5rem auto 0 auto;//20
				text-transform: uppercase;
			}
		}
	}
	.programTestMain{
		box-sizing: border-box;
		margin: 0 auto;
		position: relative;
		top: -1rem;//40
		width: 36rem;//1440
		background: #FFFFFF;
		box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
		border-radius: 5px;
		table{
			width: 100%;
			.hang{
				padding: 5px 0;
				.tuijian{
					position: absolute;
					top: 0;
					width: 1.875rem;//75
					height: 2.2rem;//88
					overflow: hidden;
					>h2{
						width: 90px;//90
						height: 20px;//20
						font-size: 0.4rem;//16
						font-family: Source Han Sans SC;
						text-align: center;
						font-weight: 500;
						color: #FFFFFF;
						background: #F97C30;
						line-height: 20px;
						transform: rotate(-45deg) translate(-30%,-40%);
					}
				}
				th:first-of-type{
					font-size: 0.6rem;//24
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #4D4D4D;
					width: 12.5rem;//500
				}
				p{
					font-size: 0.4rem;//16
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #999999;
				}
				h4{
					font-size: 0.6rem;//24
					font-family: Source Han Sans SC;
					font-weight: 500;	
				}
				.zq{
					color: #F97C30;
				}
				.qm{	
					color: #2459D9;
				}
				.jc{
					color: #6391FF;
				}
				th:nth-of-type(2){
					
					
				}
			}
			td{
				text-align: center;
				padding: 0.5rem;//20
				font-size: 0.45rem;
				>img{
					width: 0.6rem;//24
					height: 0.4rem;//16
				}
			}
		}
	}
	@import url(../utils/mediaCss/programTestingItemPhone.less);
</style>
