<template>
  <div id="app">
    <div class="header">
      <div
        class="logoAndRouter"
        :class="{bagd:activeIndex==1||activeIndex==5||activeIndex==3||activeIndex==4||activeIndex==7||routerPath=='/certificateView'||bag}"
      >
        <div
		  :class="{logod:activeIndex==1||activeIndex==5||activeIndex==3||activeIndex==4||activeIndex==7||routerPath=='/certificateView'||bag,logo:!(activeIndex==1||activeIndex==5||activeIndex==3||activeIndex==4||activeIndex==7||routerPath=='/certificateView'||bag),enLogod:lang=='e'&&(activeIndex==1||activeIndex==5||activeIndex==3||activeIndex==4||activeIndex==7||routerPath=='/certificateView'||bag),enLogo:lang=='e'&&(activeIndex!==1||activeIndex!==5||activeIndex!==3||activeIndex!==4||activeIndex!==7||routerPath!=='/certificateView'||!bag)}"
          @click="toIndex"
        />
        <img
		  @click="toIndex"
          class="phoneLogo"
          :src="lang=='e'?imgphone:imgdiannao"
		  
        >
        <label
          class="label"
          for="toggle-nav"
        />
        <input
          id="toggle-nav"
          v-model="check"
          type="checkbox"
        >
        <ul class="router">
          <li
            v-for="(item,index) in routerList"
            :key="index"
            class="item"
            @mouseenter.stop="dropHandle(item.isopen,index)"
          >
            <router-link
              :to="item.children?'#':{path:item.path,query:item.query||null}"
              class="routerlink"
              :class="{textActive:activeIndex==1||activeIndex==5||activeIndex==3||activeIndex==4||activeIndex==7||routerPath=='/certificateView'||bag,disabled:item.children,resetTextActive:routerPath!=='/index'||activeIndex==0,routerActive:item.path==routerPath,routerActiveHei:item.path==routerPath&&(activeIndex==1||activeIndex==5||activeIndex==3||activeIndex==4||activeIndex==7||routerPath=='/certificateView'||bag)}"
            >
              {{ item.title }}
            </router-link>
            <!-- <span v-if="item.dropBollean"
							:class="{dropIcon:activeIndex==0||activeIndex==5,dropIconH:activeIndex==1||activeIndex==2||activeIndex==3||activeIndex==4||activeIndex==6||routerPath=='/certificateView'||bag,upIcon:item.isopen==true&&(activeIndex==0||activeIndex==5),upIconH:item.isopen==true&&(activeIndex==1||activeIndex==2||activeIndex==3||activeIndex==4||activeIndex==6||routerPath=='/certificateView'||bag)}"></span> -->
            <img
              v-if="item.ishot"
              src="./assets/static/hot1.png"
			  :class="{hotRight:lang=='e'}"
			  
            >
            <div
              v-if="item.dropBollean&&item.isopen"
              class="dropTrue"
              :class="{miniDrop:item.children.length==2}"
              @mouseleave="handleMouseover($event)"
            >
			 <span :class="[bag||activeIndex==1||activeIndex==5||activeIndex==3||activeIndex==4||activeIndex==7||routerPath=='/certificateView'?'out':'out1']"></span>
			 <span :class="[bag||activeIndex==1||activeIndex==5||activeIndex==3||activeIndex==4||activeIndex==7||routerPath=='/certificateView'?'iner':'out1']"></span>
              <a
                v-for="(item2,index2) in item.children"
                v-show="item.id==2"
				:href="'https://www.eastsec.org.cn'+item2.path+'?categoryId='+item2.categoryId+'&categoryCode='+item2.categoryCode+'&name='+item2.name+'&remark='+item2.remark+'&englishName='+item2.englishName+'&englishRemark='+item2.englishRemark+'&TCname='+item2.tcName+'&TCremark='+item2.tcRemark"
                class="childrenName4"
				:class="{child4En:lang=='e'}"
              >
			  <!-- :href="'http://localhost:8080'+item2.path+'?categoryId='+item2.categoryId+'&categoryCode='+item2.categoryCode+'&name='+item2.name+'&remark='+item2.remark+'&englishName='+item2.englishName+'&englishRemark='+item2.englishRemark+'&TCname='+item2.tcName+'&TCremark='+item2.tcRemark" -->
			  
                {{ $t('routerList.childName',{childName:item2.name,childEnglishName:item2.englishName,childTCName:item2.tcName}) }}
              </a>
              <!-- @click.stop="jumpTest(item2.categoryId,item2.categoryCode,item2.name,item2.remark,index)" -->
              <a
                v-for="(item2,index2) in item.children"
                v-show="item.id==5"
                :href="'https://www.eastsec.org.cn'+item2.path+'?catalogueId='+item2.catalogueId+'&title='+item2.title+'&EnglishTitle='+item2.EnglishTitle"
                class="childrenName"
              >
                {{ item2.zstitle }}
              </a>
              <!-- @click.stop="jumpNewsBulletin(item2,index)" -->
              <a
                v-for="(item2,index2) in item.children"
                v-show="item.id==4"
                :href="'https://www.eastsec.org.cn'+item2.path+'?title='+item2.title"
                class="childrenName3"
              >
                {{ item2.title }}
              </a>
              <!-- @click.stop="jumpTankInformation(item2.title,index)" -->
              <a
                v-for="(item2,index2) in item.children"
                v-show="item.id==6"
                class="childrenName2"
				:class="{child6En:lang=='e'}"
                @click.stop="toDitu(item2.query,index2)"
              >
                {{ item2.title }}
              </a>
            </div>
          </li>
          <li
            class="languages"
            :class="{activeLanguages:activeIndex==1||activeIndex==5||activeIndex==3||activeIndex==4||activeIndex==7||routerPath=='/certificateView'||bag}"
            @click.stop="showLanguagesMenu"
          >
            {{ languageTitle }} <span class="el-icon-arrow-down el-icon--right" />
            <div
              v-show="languageMenuBollen"
              class="languagesItem"
            >
              <li
                v-for="languageItem in languageList"
                @click.stop="selectLanguage(languageItem)"
              >
                {{ languageItem.title }}
              </li>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div :class="{phoneMenu:true,disblock:check}">
      <li
        v-for="(item,index) in routerList"
        :key="index"
        class="menuItem"
        @click="phoneClickMenu(item.isopen,index)"
      >
        <router-link
          :to="item.children?'#':{path:item.path,query:item.query||null}"
          class="routerlink"
          @click="aa"
        >
          {{ item.title }}
        </router-link>
        <span
          v-if="item.dropBollean"
          :class="{dropIcon:true,upIcon:item.isopen==true,rightMove:lang=='e'}"
        />
        <div
          v-if="item.dropBollean&&item.isopen"
          class="dropTrue"
        >
          <p
            v-for="(item2,index2) in item.children"
            v-show="item.id==2"
            class="childrenName"
            @click.stop="jumpTest(item2.categoryId,item2.categoryCode,item2.name,item2.remark,item2.englishName,item2.englishRemark,item2.tcName,item2.tcRemark,index)"
          >
            {{ $t('routerList.childName',{childName:item2.name,childEnglishName:item2.englishName,childTCName:item2.tcName}) }}
          </p>
          <p
            v-for="(item2,index2) in item.children"
            v-show="item.id==5"
            class="childrenName"
            @click.stop="jumpNewsBulletin(item2,index)"
          >
            {{ item2.zstitle }}
          </p>
          <p
            v-for="(item2,index2) in item.children"
            v-show="item.id==4"
            class="childrenName"
            @click.stop="jumpTankInformation(item2.title,index)"
          >
            {{ item2.title }}
          </p>
          <p
            v-for="(item2,index2) in item.children"
            v-show="item.id==6"
            class="childrenName"
            @click.stop="toDitu(item2.query,index2)"
          >
            {{ item2.title }}
          </p>
        </div>
      </li>
      <li
        class="menuItem"
        :class="{activeLanguages:activeIndex==1||activeIndex==5||activeIndex==3||activeIndex==4||activeIndex==7||routerPath=='/certificateView'||bag}"
        @click.stop="showLanguagesMenu"
      >
        <a class="routerlink">{{ languageTitle }}</a> <span :class="{dropIcon:true,rightMove:lang=='e'}" />
        <div
          v-show="languageMenuBollen"
          class="languagesItem"
        >
          <li
            v-for="languageItem in languageList"
            @click.stop="selectLanguage(languageItem)"
          >
            {{ languageItem.title }}
          </li>
        </div>
      </li>
    </div>
    <router-view
      :active="activeIndex"
      class="routerview"
      :language="language"
      @changeActiveStep="changeActiveIndex"
      @updateCertificateView="updateCertificateView"
      @updateTankInfomation="updateTankInfomation"
      @updateCategoryCode="updateCategoryCoded"
      @newsClassify="updateNewsClassify"
    />
  </div>
</template>

<script>
	import api from './request/api.js'
	export default {
		components: {

		},
		data() {
			return {
				imgphone:require('./assets/static/logod_phone.png'),
				imgdiannao:require('./assets/static/logo_1.png'),
				check: true,
				routerList: [{
						title: this.$t('routerList.title1'),
						path: '/index',
						dropBollean: false,
						id: 1
					},
					{
						title: this.$t('routerList.title2'),
						path: '#',
						dropBollean: true,
						isopen: false,
						ishot: true,
						children: [],
						id: 2
					},
					{
						title: this.$t('routerList.title3'),
						path: '/certificateQuery',
						dropBollean: false,
						id: 3
					},
					{
						title: this.$t('routerList.title4'),
						path: '#',
						dropBollean: true,
						isopen: false,
						id: 4,
						children: [{
								title: this.$t('routerList.title4child1'),
								path: '/tankInformation'
							},
							{
								title: this.$t('routerList.title4child2'),
								path: '/tankInformation'
							}
						]
					},
					{
						title: this.$t('routerList.title5'),
						path: '#',
						id: 5,
						dropBollean: true,
						isopen: false,
						children: [{
								EnglishTitle: 'NEWS',
								zstitle: this.$t('routerList.title5child1'),
								title:'新闻公告',
								catalogueId: '07310220181206090100000098',
								path: '/newsBulletin'
							},
							{
								EnglishTitle: 'PUBLIC OPINTION',
								zstitle:this.$t('routerList.title5child2'),
								title: '热点舆情',
								catalogueId: '07310220200113114700000106',
								path: '/newsBulletin'
							},
							{
								EnglishTitle: 'INDUSTRY',
								zstitle: this.$t('routerList.title5child3'),
								title: '行业报告' ,
								catalogueId: '07310220181206090100000097',
								path: '/newsBulletin'
							},
							{
								EnglishTitle: 'EXPERT',
								zstitle: this.$t('routerList.title5child4'),
								title: '专家专栏' ,
								catalogueId: '07310220200113114700000105',
								path: '/newsBulletin'
							},
							{
								EnglishTitle: 'VULNERABILITY',
								zstitle: this.$t('routerList.title5child5'),
								title: '漏洞库' ,
								catalogueId: '07310220190225153200000104',
								path: '/newsBulletin'
							}
						]
					},
					{
						title: this.$t('routerList.title6'),
						path: '#',
						dropBollean: true,
						isopen: false,
						id: 6,
						children: [{
								title: this.$t('routerList.title6child1'),
								path: '#'
							},
							{
								title: this.$t('routerList.title6child2'),
								path: '/index',
								query: {
									active: 7
								},
							}
						]
					},
				],
				bag: false,
				activeIndex: 0,
				logoSrc: require('./assets/static/logo.png'),
				languageMenuBollen: false,
				language: 0,
				lang:sessionStorage.getItem('lang')||'s',
				languageTitle: sessionStorage.getItem('languageTitle')||'CN',
				languageList: [{
						title: 'CN',
						language: 0
					},
					{
						title: 'EN',
						language: 1
					},
					{
						title: 'TC',
						language: 2
					}
				]
			}
		},
		computed: {
			//获取当前路由path
			routerPath() {
				let router_path = this.$route.path

				return router_path
			},
			// routerList() {
			// 	return [{
			// 			title: "首页",
			// 			path: '/index',
			// 			dropBollean: false
			// 		},
			// 		{
			// 			title: "服务类型",
			// 			path: '#',
			// 			dropBollean: true,
			// 			isopen: false,
			// 			ishot: true,
			// 			children: []
			// 		},
			// 		{
			// 			title: "证书查询",
			// 			path: '/certificateQuery',
			// 			dropBollean: false
			// 		},
			// 		{
			// 			title: "智库资讯",
			// 			path: '#',
			// 			dropBollean: true,
			// 			isopen: false,
			// 			children: [{
			// 					title: '项目库',
			// 					path: '/tankInformation'
			// 				},
			// 				{
			// 					title: '企业库',
			// 					path: '/tankInformation'
			// 				}
			// 			]
			// 		},
			// 		{
			// 			title: "新闻动态",
			// 			path: '#',
			// 			dropBollean: true,
			// 			isopen: false,
			// 			children: [{
			// 					EnglishTitle: 'NEWS',
			// 					title: '新闻公告',
			// 					catalogueId: '07310220181206090100000098',
			// 					path: '/newsBulletin'
			// 				},
			// 				{
			// 					EnglishTitle: 'PUBLIC OPINTION',
			// 					title: '热点舆情',
			// 					catalogueId: '07310220200113114700000106',
			// 					path: '/newsBulletin'
			// 				},
			// 				{
			// 					EnglishTitle: 'INDUSTRY',
			// 					title: '行业报告',
			// 					catalogueId: '07310220181206090100000097',
			// 					path: '/newsBulletin'
			// 				},
			// 				{
			// 					EnglishTitle: 'EXPERT',
			// 					title: '专家专栏',
			// 					catalogueId: '07310220200113114700000105',
			// 					path: '/newsBulletin'
			// 				},
			// 				{
			// 					EnglishTitle: 'VULNERABILITY',
			// 					title: '漏洞库',
			// 					catalogueId: '07310220190225153200000104',
			// 					path: '/newsBulletin'
			// 				}
			// 			]
			// 		},
			// 		{
			// 			title: "关于我们",
			// 			path: '#',
			// 			dropBollean: true,
			// 			isopen: false,
			// 			children: [{
			// 					title: '中心介绍',
			// 					path: '#'
			// 				},
			// 				{
			// 					title: '联系我们',
			// 					path: '/index',
			// 					query: {
			// 						active: 7
			// 					},
			// 				}
			// 			]
			// 		},
			// 	]
			// }
		},
		watch: {
			$route(to, from) {
				// this.activeIndex=this.$route.query.active


				if (to.path !== from.path) {
					this.check = false
				}
				this.$nextTick(() => {
					console.log(sessionStorage.getItem('lang'))
					this.$zh_tran(sessionStorage.getItem('lang'))
				})
				if (to.path !== '/index') {
					this.activeIndex = 0
				}

			},

		},
		created() {
			this.getDetectionClassify()
			this.listenerFunction()
			this.check = false

			//sessionStorage.setItem('lang', 's')
		},
		mounted() {
			//window.addEventListener('mouseover',this.handleMouseover)
		},
		beforeDestroy() {
			//window.removeEventListener('mouseover',this.handleMouseover)
			document.removeEventListener("scroll", this.listenerFunction)
		},
		methods: {
			getDetectionClassify() {
				api.getDetectionClassify().then(res => {
					res.forEach(item=>{
						item.path='/webDetection'
					})
					this.routerList[1].children = res
					console.log(this.routerList[1])
				})
			},

			//展开菜单
			showLanguagesMenu() {
				console.log(this.languageMenuBollen)
				this.languageMenuBollen = !this.languageMenuBollen

			},
			selectLanguage(item) {
				this.language = item.language
				console.log(this.language)
				
				
					if (this.language == 0) {
						location.reload();
						sessionStorage.setItem('lang', 's')
						sessionStorage.setItem('languageTitle','CN')
						this.$i18n.locale = 's'
						
					} else if (this.language == 2) {
						location.reload();
						sessionStorage.setItem('lang', 't')
						sessionStorage.setItem('languageTitle','TC')
						this.$i18n.locale = 't'
						
						
						
						//this.$zh_tran(sessionStorage.getItem('lang'))
						
						
					} else if (this.language == 1) {
						location.reload();
						sessionStorage.setItem('lang', 'e')
						sessionStorage.setItem('languageTitle','EN')
						this.$i18n.locale = 'e'	
						console.log('ens')
					}
				//let session=sessionStorage.getItem('lang')||''
				
				// if (this.language == 0) {
				// 	sessionStorage.setItem('lang', 's')
				// 	this.$i18n.locale = 's'
				// 	location.reload();
				// } else if (this.language == 2) {
				// 	sessionStorage.setItem('lang', 't')
				// 	this.$zh_tran(sessionStorage.getItem('lang'))
				// } else if (this.language == 1) {
				// 	location.reload();
				// 	sessionStorage.setItem('lang', 'e')
				// 	this.$i18n.locale = 'e'
					
				// }
				this.languageTitle = item.title
				
				
				this.languageMenuBollen = false
			},
			aa() {
				this.check = !this.check
			},
			toDitu(query, index2) {
				if (index2 == 1) {
					this.$router.push({
						path: '/index',
						query
					})
					this.activeIndex = 7
				} else {
					this.$router.push({
						path: '/test',
						query: {

						}
					})
				}
				this.check = false
			},
			handleMouseover(e) {
				setTimeout(() => {
					this.routerList.forEach(item => {
						item.isopen = false
					})
				}, 200)

			},
			toIndex() {
				this.$router.push('/index')
			},
			listenerFunction(e) {
				document.addEventListener('scroll', this.handleScroll, true)
			},
			//控制滚动条
			handleScroll() {
				if (window.pageYOffset > 100) {
					this.routerList[1].ishot = false
					this.bag = true
				} else if (window.pageYOffset <= 100) {
					this.routerList[1].ishot = true
					this.bag = false
				}
			},
			dropHandle(isopen, index) {
				// if (index == 5) {
				// 	this.activeIndex = 6
				// 	console.log(this.activeIndex)
				// }
				this.routerList.forEach((item) => {
					item.isopen = false
				})
				this.routerList[index].isopen = true
			},
			phoneClickMenu(isopen, index) {
				this.routerList.forEach((item, index1) => {
					item.isopen = false
					if (index1 == index) {
						if (isopen) {
							item.isopen = true
						}
						item.isopen = !item.isopen
					}

				})
			},
			changeActiveIndex(val) {
				this.activeIndex = val;
			},
			jumpTest(categoryId, categoryCode, name, remark,englishName,englishRemark,TCname,TCremark, index) {
				if (categoryId) {
					this.$router.push({
						path: '/webDetection',
						query: {
							categoryId,
							categoryCode,
							name,
							remark,
							englishName,
							englishRemark,
							TCname,
							TCremark
						}
					})
				}
				this.routerList[index].path = '/webDetection'
				this.routerList[index].isopen = false
				this.check = false
			},
			jumpNewsBulletin(item, index) {
				this.$router.push({
					path: '/newsBulletin',
					query: {
						catalogueId: item.catalogueId,
						title: item.title,
						EnglishTitle: item.EnglishTitle
					}
				})
				this.routerList[index].path = '/newsBulletin'
				this.routerList[index].isopen = false
				this.check = false
			},
			jumpTankInformation(title, index) {
				this.$router.push({
					path: '/tankInformation',
					query: {
						title
					}
				})
				this.routerList[index].path = '/tankInformation'
				this.routerList[index].isopen = false
				this.check = false
			},
			updateCategoryCoded(categoryCode) {
				this.$router.push({
					path: '/webDetection',
					query: {
						categoryCode
					}
				})
			},
			updateNewsClassify(catalogueId) {
				let result = this.routerList[4].children.find(item => {
					return item.catalogueId == catalogueId
				})
				this.$router.push({
					path: '/newsBulletin',
					query: {
						catalogueId: result.catalogueId,
						title: result.title,
						EnglishTitle: result.EnglishTitle
					}
				})
			},
			updateTankInfomation(title) {
				this.$router.push({
					path: '/tankInformation',
					query: {
						title
					}
				})
			},
			updateCertificateView(itemName) {
				itemName = itemName.value || itemName
				this.$router.push({
					path: '/certificateView',
					query: {
						itemName
					}
				})
			},
			// changeLanguage(language) {
			// 	if (language == 0) {
			// 		sessionStorage.setItem('lang', 's')
			// 	} else if (language == 2) {
			// 		sessionStorage.setItem('lang', 't')
			// 	} else if (language == 1) {

			// 	}
			// 	this.routerList[6].isopen = false
			// 	this.$zh_tran(sessionStorage.getItem('lang'))
			// },
			moved(e) {
				console.log(e.targetTouches[0].clientX)
			}
		},
	}
</script>

<style lang="less">
	* {
		margin: 0;
		padding: 0;
	}
	.phoneLogo{
		display: none;
	}
	.phoneMenu {
		display: none;
	}

	.routerview {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
	}

	.languages {
		
		display: inline-flex !important;
		margin-left: 1.5rem;
		width: 50px;
		height: 18px;
		cursor: pointer;
		position: relative;
		color: #FFFFFF;
		font-size: 0.45rem;
		line-height: 18px;
		padding: 0.1rem;

		.languagesItem {
			width: 50px;
			position: absolute;
			background-color: #FFFFFF;
			border-radius: 5px;
			top: 25px;
			left: 0;
			box-shadow: 1px 1px 1px 1px #F3F3F3;

			>li {
				margin-top: 10px;
				font-size: 0.4rem;
				color: #333333;
				text-align: center;

				&:hover {
					background: #999999;
				}
			}
		}
	}

	.router {
		width: 25rem;
		height: 18px;

		font-family: Source Han Sans SC;
		font-weight: 500;
		>li{
			display: inline-block;
		}
		//display: flex;
		//justify-content: space-between;
		//align-self: center;
		align-items: center;
		list-style: none;

		.activeLanguages {
			color: #333333;
		}

		.item {
			width: max-content;
			height: 18px;
			//padding: 0 10px; //10px
			cursor: pointer;
			position: relative;
			line-height: 18px;
			margin-left: 1.75rem;
			.textActive {
				color: #333333 !important;
			}

			.routerActive {
				width: max-content;
				padding-bottom: 10px;
				border-bottom: 1px solid #FFFFFF;
			}

			.routerActiveHei {
				width: max-content;
				padding-bottom: 10px;
				border-bottom: 1px solid #333333 !important;
			}

			.routerlink {
				display: block;
				height: 100%;
				text-decoration: none;
				color: #FFFFFF;
				font-size: 0.45rem; //18

			}

			// >span {
			// 	position: absolute;
			// 	top: 10px;
			// 	width: 16px;
			// 	height: 9px;

			// 	@media (min-width:1440px) {
			// 		right: 26px !important;
			// 	}

			// 	@media (min-width:1680px) {
			// 		right: 20px !important;
			// 	}

			// 	@media (min-width:1920px) {
			// 		right: 14px !important;
			// 	}
			// }

			// .dropIcon {
			// 	width: 16px;
			// 	height: 9px;
			// 	background-image: url(./assets/static/gengduo2.png);
			// }

			// .dropIconH {

			// 	background-image: url(./assets/static/xiayiy.png);
			// }

			// .upIcon {
			// 	position: absolute;
			// 	width: 16px;
			// 	height: 9px;
			// 	background-image: url(./assets/static/gengduo.png);
			// }

			// .upIconH {
			// 	position: absolute;
			// 	width: 16px;
			// 	height: 9px;
			// 	transform: rotate(180deg);
			// 	background-image: url(./assets/static/xiayiy.png);
			// }

			>img {
				position: absolute;
				top: -25px;
				right: -0.7rem;

				//right: 5px;
				// @media (min-width:1440px) {
				// 	right: -22px !important;
				// }

				// @media (min-width:1680px) {
				// 	right: -24px !important;
				// }

				// @media (min-width:1920px) {
				// 	right: -28px !important;
				// }
			}
			// .hotRight{
			// 	right: -0.7rem;
			// }
			
			.miniDrop {
				width: 120px !important;
			}

			// .dropTrue::after {
			// 	content: '';
			// 	position: absolute;
			// 	top: -20px;
			// 	left: -4px;
			// 	//border-radius: 20px;
			// 	width: 0;
			// 	height: 0;
			// 	font-size: 0;
			// 	border: transparent 9px solid;
			// 	border-right-color: #FFFFFF;
				
			// }

			.dropTrue {
				position: absolute;
				top: 43px;
				width: 270px;
				padding-bottom: 20px;
				border: 1px solid #F3F3F3;
				background: #FFFFFF;
				border-radius: 5px;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				align-items: center;
				box-shadow: 1px 1px 1px 1px #F3F3F3;
				>span{
					position: absolute;  
					left: 15px;  
					height: 0px;  
					width: 0px; 
				}
				span.out {  
				line-height: 0;  
				border-width: 10px;  
				border-color: transparent transparent #Ccc transparent;  
				border-style: dashed dashed solid dashed;  
				top: -20px;  
				}  
				span.iner {  
				border-width: 10px;  
				border-color: #fff transparent #FFF transparent;  
				border-style: dashed dashed solid dashed;  
				top: -19px;  
				line-height: 0;  
				}  
				span.out1 {
				line-height: 0;  
				border-width: 10px;  
				border-color: transparent transparent #FFF transparent;  
				border-style: dashed dashed solid dashed;  
				top: -20px;  
				}  
				span.iner1 {
				border-width: 10px;  
				border-color: #fff transparent #FFF transparent;  
				border-style: dashed dashed solid dashed;  
				top: -19px;  
				line-height: 0;  
				}  

				.childrenName {
					text-decoration: none;
					cursor: pointer;
					margin-top: 20px;
					margin-left: 40px;
					width: 90px; //90
					height: 20px; //20
					font-size: 0.35rem;
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
				}

				.childrenName2 {
					text-decoration: none;
					cursor: pointer;
					text-align: center;
					margin-top: 20px;
					margin-left: 30px;
					width: 60px;
					height: 20px; //20
					font-size: 0.35rem;
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
					line-height: 20px;
				}
				

				.childrenName3 {
					text-decoration: none;
					cursor: pointer;
					margin-top: 20px;
					margin-left: 15px;
					text-align: center;
					width: 90px; //90
					height: 20px; //20
					font-size: 0.35rem;
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;

				}

				.childrenName4 {
					text-decoration: none;
					cursor: pointer;
					margin-top: 20px;
					margin-left: 10px;
					text-align: left;
					width: 120px; //90
					height: 20px; //20
					font-size: 0.25rem;
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
				}
				.child4En{
					width: 100%;
					//margin-left: 0px;
				}
				.child6En{
					width: 100%;
					margin-left: 0;
				}

				.childrenName4:hover {
					color: #2459D9;
				}

				.childrenName3:hover {
					color: #2459D9;
				}

				.childrenName2:hover {
					color: #2459D9;
				}

				.childrenName:hover {
					color: #2459D9;
				}
			}
		}
	}

	.logoAndRouter {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 999;
		//width: 100%;//100% 1920
		height: 2.5rem; //100

		display: flex;
		justify-content: space-around;
		align-items: center;

		.logo {
			width: 160px;
			height: 45px;
			background: url(assets/static/logo.png);
			background-position: center;
			background-repeat: no-repeat;
			cursor: pointer;
		}

		.logod {
			width: 160px;
			height: 45px;
			//background: url(assets/static/logo_1.png);
			background: url(assets/static/logod_2.png);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			cursor: pointer;
		}
		
		.enLogo{
			width: 160px;
			height: 45px;
			background:url(assets/static/logo_en.png) !important;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			cursor: pointer;
		}
		.enLogod{
			width: 160px;
			height: 45px;
			background: url(assets/static/logod_en.png) !important;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			cursor: pointer;
		}
		.label {
			display: none;
		}

		#toggle-nav {
			display: none;
		}

	}

	.bagd {
		background-color: #FFFFFF;
		box-shadow: 1px 1px 1px 1px #F8F8F8;
	}

	.disabled {
		pointer-events: none;
		cursor: default;
	}






	@import url('./utils/mediaCss/AppMedia.css');

	html {
		font-size: 40px;
	}

	body {
		font-size: 16px;
	}
</style>
