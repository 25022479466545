<template>
  <div class="footer">
    <div
      v-if="type=='index'"
      class="lunbo"
    >
      <p>【官方声明】作为区块链生态中的第三方独立安全公司，专注区块链生态安全，不为任何项目背书或站台。一切背书或站台旗号的项目，都不是官方授权，我们将保留法律追究的权利！</p>
    </div>
    <div class="footerMain">
      <div class="footerLeft">
        <div class="fuwuleixin">
          <h4>{{ $t('index.fuwuTitle') }}</h4>
          <p @click="tocpcp">
            {{ $t('index.serviceTypeTitle1') }}
          </p>
          <p @click="toqklcpcp">
            {{ $t('index.serviceTypeTitle2') }}
          </p>
          <p @click="toqklaqpg">
            {{ $t('index.serviceTypeTitle3') }}
          </p>
          <p @click="toqklaqjc">
            {{ $t('index.serviceTypeTitle4') }}
          </p>
        </div>
        <div class="xinwengongao">
          <h4>{{ $t('routerList.title5') }}</h4>
          <p @click="newsGG">
            {{ $t('index.newsTitle') }}
          </p>
          <p @click="redian">
            {{ $t('routerList.title5child2') }}
          </p>
          <p @click="hangyebg">
            {{ $t('routerList.title5child3') }}
          </p>
          <p @click="loudon">
            {{ $t('routerList.title5child4') }}
          </p>
          <p @click="zhuanjia">
            {{ $t('routerList.title5child5') }}
          </p>
        </div>
        <div class="lianxiwomen">
          <h4>{{ $t('routerList.title6') }}</h4>
          <p @click="zhonxin">
            {{ $t('routerList.title6child1') }}
          </p>
          <p @click="lianxiwomend">
            {{ $t('routerList.title6child2') }}
          </p>
          <h4>{{ $t('routerList.title4') }}</h4>
          <p @click="xmk">
            {{ $t('routerList.title4child1') }}
          </p>
          <p @click="qyk">
            {{ $t('routerList.title4child2') }}
          </p>
        </div>
      </div>
      <div class="logAndState">
        <div class="logo" :class="{logod:lang=='e'}">
          <!-- <img src="../assets/static/logod.png"> -->
        </div>
        <div class="state">
          <div class="stateLeft">
            <p :class="{tej:lang=='e'}">{{ $t('index.banquan') }}</p>
            <p @click="toBeian">
              {{$t('index.beian')}}
            </p>
            <p>{{ $t('index.lianxiEmail') }}</p>
          </div>
          <div class="stateRight">
            <img src="../assets/static/2wm.jpg">
            <p>{{$t('index.weixin2wm')}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	//页面尾部组件
	export default {
		props: ["type"],
		data() {
			return {
				lang:sessionStorage.getItem('lang')||'s'
			}
		},
		methods:{
			toBeian(){
				window.open("https://beian.miit.gov.cn")
			},
			tocpcp(){
				this.$router.push({
					path: '/webDetection',
					query: {
						categoryId:31,
						categoryCode:'rc',
						name:'软件产品测评',
						remark:'围绕区块链的网络安全、底层技术、BAAS平台以及上层应用的功能、性能及安全，面向行业提供区块链项目的检测服务。目前已完成蚂蚁区块链BaaS平台、杭州趣链区块链平台、中电云网区块链、复杂美区块链平台、腾讯区块链平台以及浪潮集团质量链等国内多个主流区块链的项目检测并发放了检测证书。支持的产品类型：公链/联盟链、智能合约、钱包等；支持的测评类型：功能性检测 、性能检测、安全性检测（源代码安全审计、黑盒渗透测试、节点主机安全扫描）。',
						englishName:'Software product evaluation',
						englishRemark:'According to the technical requirements specified in the system under test purchase contract, construction plan or requirements specification, test the various characteristics of the system under test specified in the test requirements. The test types support commissioned testing, acceptance testing, identification testing, cost evaluation, etc. , Can issue a nationally recognized CMA third-party test report.Supported product types: mobile APP, desktop software, WEB website, applet, WeChat official account, database, operating system, etc.Supported evaluation types: document set, functionality, performance, compatibility, reliability, ease of use, maintainability, portability, security testing (source code security audit, black box penetration testing, host security scanning).',
						TCname:'軟件產品服務',
						TCremark:'主要被測系統採購合同、建設方案或需求說明書等規定的技術要求，對測試需求中規定的被測系統的各項特性進行測試，測試類型支持委託測試、驗收測試、鑑定、測試成本評估等，可出具國家認可的 CMA 測試報告。支持的產品類型：移動APP、桌面軟件、WEB網站、小程序、微信公眾號、數據庫、硬件等支持的功能類型：文檔集、性能、擴展、性能、維護性、可移植性、安全性（源代碼安全審計、黑盒檢測測試、主機安全掃描）。'
					}
				})
			},
			toqklcpcp(){
				this.$router.push({
					path: '/webDetection',
					query: {
						categoryId:30,
						categoryCode:'bc',
						name:'区块链产品测评',
						remark:'围绕区块链的网络安全、底层技术、BAAS平台以及上层应用的功能、性能及安全，面向行业提供区块链项目的检测服务。目前已完成蚂蚁区块链BaaS平台、杭州趣链区块链平台、中电云网区块链、复杂美区块链平台、腾讯区块链平台以及浪潮集团质量链等国内多个主流区块链的项目检测并发放了检测证书。支持的产品类型：BAAS平台、公链/联盟链、智能合约、钱包等；支持的测评类型：功能性检测 、性能检测、安全性检测（源代码安全审计、黑盒渗透测试、节点主机安全扫描）。',
						englishName:'Blockchain product evaluation',
						englishRemark:'Focusing on the network security, underlying technology, baas platform and the functions, performance and security of upper applications of blockchain, it provides detection services for blockchain projects for the industry, and can issue third-party detection certificates with national CMA certification. At present, it has completed the project testing of many domestic mainstream blockchains such as ant blockchain baas platform, Hangzhou fun chain blockchain platform, CLP cloud blockchain, complex America blockchain platform, Tencent blockchain platform and Inspur Group quality chain, and issued testing certificates.Supported product types: baas platform, public chain / alliance chain, smart contract, wallet, etcSupported evaluation types: functional detection, performance detection, security detection (source code security audit, black box penetration test, node host security scanning).',
						TCname:'區塊鏈產品測評',
						TCremark:'圍繞區塊鏈的網路安全、底層科技、BAAS平臺以及上層應用的功能、效能及安全，面向行業提供區塊鏈項目的檢測服務，可出具國家CMA認證認可資質的協力廠商檢測。 現時已完成螞蟻區塊鏈BaaS平臺、杭州趣鏈區塊鏈平臺、中電雲網區塊鏈、複雜美區塊鏈平臺、騰訊區塊鏈平臺以及浪潮集團質量鏈等國內多個主流區塊鏈的項目檢測並發放了檢測證書。支持的產品類型：BAAS平臺、公鏈/聯盟鏈、智慧合約、錢包等支持的測評類型：功能性檢測、效能檢測、安全性檢測（原始程式碼安全稽核、黑盒滲透測試、節點主機安全掃描）。'
					}
				})
			},
			toqklaqpg(){
				this.$router.push({
					path: '/webDetection',
					query: {
						categoryId:32,
						categoryCode:'bg',
						name:'区块链安全评估',
						remark:'安全评估：在招商引资或投资过程中很难鉴别区块链企业或项目的真假，有很大的安全隐患。我们从企业和项目的多个维度进行全面的风险评估，并出具风险评估报告，提前发现项目的风险情况。目前已为数百个企业、以及包括趣步APP在内的众多项目进行了安全评估，得到了政府领导的高度认可。安全检查：配合有关部门对区块链项目进行安全检查工作，并出具检查结果报告，一方面可以帮助企业发现安全漏洞防止经济损失，另一方面通过检查可了解项目真实的情况，包括功能点、业务模式等，以规范和促进区块链技术服务健康发展，为区块链信息服务的提供、使用和管理等提供有效的法律依据。',
						englishName:'Blockchain security assessment',
						englishRemark:'Security assessment: It is difficult to identify the authenticity of blockchain companies or projects in the process of attracting investment or investment, and there may be greater security risks. We conduct a comprehensive risk assessment from multiple dimensions of the company and the project, and issue a risk assessment report to discover the risk of the project in advance. At present, safety assessments have been conducted for hundreds of enterprises and projects, and they have been highly recognized by government leaders.Security inspection: Cooperate with relevant departments to conduct security inspections on blockchain projects and issue inspection results reports. On the one hand, t can help companies discover security vulnerabilities and prevent economic losses. On the other h',
						TCname:'區塊鏈安全評估',
						TCremark:'安全評估：在招商引資或投資過程中很難鑑別區塊鏈企業或項目的真假，可能存在較大安全隱患。我們從企業和項目的多個維度進行全面的風險評估，並出具風險評估報告，提前發現項目的風險情況。目前已為數百個企業、項目進行了安全評估，得到了政府領導的高度認可。安全檢查：配合有關部門對區塊鏈項目進行安全檢查工作，並出具檢查結果報告，一方面可以幫助企業發現安全漏洞防止經濟損失，另一方面通過檢查可了解項目真實的情況，包括功能點、業務模式等，以規範和促進區塊鏈技術服務健康發展，為區塊鏈信息服務的提供、使用和管理等提供有效的法律依據。'
					}
				})
			},
			toqklaqjc(){
				this.$router.push({
					path: '/webDetection',
					query: {
						categoryId:33,
						categoryCode:'bj',
						name:'区块链行业安全监测',
						remark:'平台对全国区块链企业和全球区块链项目实行7*24小时全方位、高动态的风险监测，发现风险及时预警；并对主流公链进行实时交易和节点监控；满足地方政府、各省市网信办、金融监管部门、执法机构以及区块链产业园区等对区块链行业进行监管的需求，确保区块链产业发展有序，业务范围可定制。',
						englishName:'Blockchain industry security monitoring',
						englishRemark:'The platform implements 7*24 hours all-round, highly dynamic risk monitoring for national blockchain companies and global blockchain projects, and timely warning of risks is found; real-time transactions and node monitoring of mainstream public chains; satisfy local governments, provinces and cities The Cyberspace Administration of China, financial regulatory authorities, law enforcement agencies, and blockchain industry parks need to supervise the blockchain industry to ensure the orderly development of the blockchain industry and the scope of business can be customized.',
						TCname:'区块链行业安全监测',
						TCremark:'平台對全國區塊鏈企業和全球區塊鏈項目實行7*24小時全方位、高動態的風險監測，發現風險及時預警；並對主流公鏈進行實時交易和節點監控；滿足地方政府、各省市網信辦、金融監管部門、執法機構以及區塊鏈產業園區等對區塊鏈行業進行監管的需求，確保區塊鏈產業發展有序，業務範圍可定制。'
					}
				})
			},
			newsGG(){
				this.$router.push({
					path: '/newsBulletin',
					query: {
						catalogueId: '07310220181206090100000098',
						title: '新闻公告',
						EnglishTitle: 'NEWS'
					}
				})
			},
			redian(){
				this.$router.push({
					path: '/newsBulletin',
					query: {
						catalogueId: '07310220200113114700000106',
						title: '热点舆情',
						EnglishTitle: 'PUBLIC OPINTION'
					}
				})
			},
			hangyebg(){
				this.$router.push({
					path: '/newsBulletin',
					query: {
						catalogueId: '07310220181206090100000097',
						title: '行业报告',
						EnglishTitle: 'INDUSTRY'
					}
				})
			},
			loudon(){
				this.$router.push({
					path: '/newsBulletin',
					query: {
						catalogueId: '07310220190225153200000104',
						title: '漏洞库',
						EnglishTitle: 'VULNERABILITY'
					}
				})
			},
			zhuanjia(){
				this.$router.push({
					path: '/newsBulletin',
					query: {
						catalogueId: '07310220200113114700000105',
						title: '专家专栏',
						EnglishTitle: 'EXPERT'
					}
				})
			},
			xmk(){
				this.$router.push({
					path: '/tankInformation',
					query: {
						title:'项目库'
					}
				})
			},
			qyk(){
				this.$router.push({
					path: '/tankInformation',
					query: {
						title:'企业库'
					}
				})
			},
			lianxiwomend(){
				this.$router.push({
					path:'/index',
					query:{
						active:7
					}
				})
			},
			zhonxin(){
				this.$router.push({
					path:'/test'
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	p{
		cursor: pointer;
	}
	.footer {
		width: 100%;
		padding-bottom: 0.5rem;//20
		background-color: rgb(0 58 130);
		overflow: hidden;
	}

	.footerMain {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		width: 36rem; //1440
		margin: 50px auto 0 auto;

		//height: 200px;
		.footerLeft {
			display: flex;
			justify-content: space-around;
			width: 17.5rem;

			.fuwuleixin {
				display: flex;
				flex-direction: column;
				width: max-content;
				justify-content: space-between;
			}

			.xinwengongao {
				display: flex;
				flex-direction: column;
			}

			.lianxiwomen {
				display: flex;
				flex-direction: column;

				>h4:nth-of-type(2) {
					margin-top: 0.75rem; //30
				}
			}

			h4 {
				font-size: 0.45rem; //18
				font-family: Source Han Sans SC;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 0.75rem; //30
			}

			p {
				font-size: 0.4rem; //16
				font-family: Source Han Sans SC;
				font-weight: 400;
				color: #A1ACB3;
				margin: 0.25rem 0 0; //10
			}
		}

		.logAndState {

			flex: 1;
			margin: 0 auto;

			.logo {
				margin-left: 11.25rem;//450
				width: 6.8rem;
				height: 1.5rem;
				background-image: url(../assets/static/logod.png);
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				// >img {
				// 	width: 6.8rem;//272
				// 	height: 1.5rem;//60
				// }
			}
			.logod{
				background-size: contain;
				background-image: url(../assets/static/logo_en.png);
			}

			.state {
				display: flex;
				width: 100%;
				justify-content: space-between;
				align-items: center;
				padding: 1px;
				box-sizing: border-box;
				margin-top: .5rem;//40

				.stateLeft {
					font-size: 0.4rem;//16
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #A1ACB3;
					line-height: 0.8rem;//32
					>p{
						font-size: 0.4rem;//16
						font-family: Source Han Sans SC;
						font-weight: 400;
						color: #A1ACB3;
						line-height: 0.8rem;//32
						
					}
					>p:nth-of-type(2){
						cursor: pointer;
					}
				}

				.stateRight {
					display: flex;
					flex-direction: column;
					align-items: center;
					>img{
						width: 2.625rem;
						height: 2.625rem;
					}
					>p {
						font-size: 0.4rem;
						font-family: Source Han Sans SC;
						font-weight: 400;
						color: #A1ACB3;
						line-height: 35px;
						opacity: 0.5;
						white-space: nowrap;
					}
				}
			}
		}




	}
	@import url("../utils/mediaCss/footerPhone.less");
</style>
<style>
	/* @import url("../utils/mediaCss/footerPhone.css"); */
</style>
