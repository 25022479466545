<template>
  <div class="newsBulletin">
    <div class="nav">
      <div class="newsName">
        <h1>{{ $t('newsBulletin.title',{zhTitle:$route.query.title,enTitle:$route.query.EnglishTitle}) }}</h1>
        <span :class="{disno:lang=='e'}">{{ $route.query.EnglishTitle }}</span>
      </div>
    </div>
    <div class="newsContent">
      <div class="contentNav">
        <span
          v-for="(classItem,index) in newsClassify"
          :class="{classActive:classItem[2]}"
          @click="clickClassfiy(classItem[0],index)"
        >{{ classItem[1] }}</span>
      </div>
      <!-- <swiper :options="newsSwiperOption" class="swiper-no-swiping">
				<swiper-slide v-for="(lunboItem,lunboindex) in lunboList" :key="lunboItem.id">
					<div class="contentRecommend">
						<img @click.stop="toNewsBulletinItem(lunboItem.articleId,$route.query.catalogueId)" :src="'https://www.eastsec.org.cn/api'+lunboItem.homeImage" />
						<div class="recommendMain">
							<h3 @click.stop="toNewsBulletinItem(lunboItem.articleId)">{{lunboItem.title}}</h3>
							<p>{{lunboItem.digest}}</p>
							<span>{{lunboItem.gmtCreate}}</span>
							<div class="recommendGenduo" @click.stop="toNewsBulletinItem(lunboItem.articleId,$route.query.catalogueId)">
								<span>了解更多</span>
								<img src="../assets/static/xyy.png" />
							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
			<div class="rotation_nav" v-show="lunboList.length==3"></div>
			<div v-if="newsContentMainList.length!==0" class="contentMain" :class="{justify:newsContentMainList.length!==2&&newsContentMainList.length!==5}">
				<div class="contentMainItem" :class="{mR:newsContentMainList.length===2||newsContentMainList.length===5}"
					v-for="(item,index) in newsContentMainList" :key="index">
					<img @click="toNewsBulletinItem(item.articleId,$route.query.catalogueId)"
						:src="'https://www.eastsec.org.cn/api'+item.homeImage" />
					<p>{{item.title}}</p>
					<span>{{item.gmtCreate}}</span>
					<a @click="toNewsBulletinItem(item.articleId,$route.query.catalogueId)">了解更多</a>
				</div>
			</div> -->
      <div class="newsMains">
        <div
          v-for="(item,index) in newsContentMainList"
          :key="index"
          class="newsMainItem"
        >
          <img
            :src="'https://www.eastsec.org.cn/api'+item.homeImage"
            @click="toNewsBulletinItem(item.articleId,$route.query.catalogueId)"
          >
          <div class="titleAndText">
            <h4 @click="toNewsBulletinItem(item.articleId,$route.query.catalogueId)">
              {{ item.title }}
            </h4>
            <p>{{ item.digest }}</p>
          </div>
          <div class="timeAndgd">
            <span>{{ item.gmtCreate }}</span>
            <p @click="toNewsBulletinItem(item.articleId,$route.query.catalogueId)">
              {{$t('newsBulletin.ljgd')}}
            </p>
          </div>
        </div>
      </div>
      <!-- <pagi-nation></pagi-nation> -->
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="6"
        :page-count="totalPages"
        :hide-on-single-page="true"
        @current-change="currentChange"
      />
      <div
        v-if="newsContentMainList.length==0"
        class="noMore"
      >
        {{ $t('newsBulletin.nodata') }}
      </div>
    </div>
    <other-footer :type="pageType" />
  </div>
</template>

<script>
	import otherFooter from '../components/footer.vue'
	import pagiNation from '../components/pagination.vue'
	import scroll from '../utils/scroll.js'
	import api from '../request/api.js'
	import {
		formatDate
	} from '../utils/formatDate.js'

	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'
	let that = null
	export default {
		components: {
			otherFooter,
			Swiper,
			SwiperSlide
			// pagiNation
		},
		data() {
			return {
				newsSwiperOption: {
					autoplay: true,
					pagination: {
						el: '.rotation_nav',
						type: 'bullets',
						bulletElement: 'span',
						clickable: true
					}
				},
				lang:sessionStorage.getItem('lang')||'s',
				pageType: 'other',
				newsContentMainList: [],
				lunboList: [],
				newsClassify: [
					[
						"07310220181206090100000097",
						this.$t('routerList.title5child3'),
						false
					],
					[
						"07310220181206090100000098",
						this.$t('routerList.title5child1'),
						false
					],
					[
						"07310220190225153200000104",
						this.$t('routerList.title5child5'),
						false
					],
					[
						"07310220200113114700000105",
						this.$t('routerList.title5child4'),
						false
					],
					[
						"07310220200113114700000106",
						this.$t('routerList.title5child2'),
						false
					],
				],
				//上级目录id
				catalogueId: '',
				totalPages: 0,
				pageSize: 0
			}
		},
		watch:{
			$route(to,from){
				this.catalogueId=this.$route.query.catalogueId
			},
			catalogueId(){
				this.newsClassify.forEach(item=>{
					item[2]=false
					if(item[0]==this.catalogueId){
						item[2]=true
					}
				})
				this.getNewsContentMainList(this.catalogueId)
			}
		},
		created() {
			that = this
			//获取新闻动态的分类 中文
			
			//this.getNewsContentMainList(this.newsClassify[0])
		},
		mounted() {
			
			// this.getNewsClassify(0)
			this.catalogueId=this.$route.query.catalogueId
		},
		methods: {
			//获取新闻动态的分类
			// getNewsClassify(laguage) {
			// 	//0为中文
			// 	api.getAllCatalogues(laguage).then(res => {
			// 		console.log(res)
			// 		let arr = Object.keys(res.cataloguesTree[Object.keys(res.cataloguesTree)[0]]).map(item => {
			// 			item = item.split(':');
			// 			item[2] = false
			// 			item[1] = item[1].substring(0, item[1].length - 1)
			// 			item[0] = item[0].substring(1, item[0].length)
			// 			return item
			// 		})
			// 		let length = arr[0][0].length
			// 		arr = arr.sort((a, b) => a[0].substring(length - 3, length) - b[0].substring(length - 3,
			// 			length))
			// 		arr.forEach(item => {
			// 			if (item[0] == this.$route.query.catalogueId) {
			// 				item[2] = true
			// 				this.getNewsContentMainList(this.$route.query.catalogueId)
			// 			}
			// 		})
			// 		console.log(arr)
			// 		this.newsClassify = arr;

			// 	})
			// },
			clickClassfiy(id, index) {
				this.newsClassify.forEach(item=>{
					item[2]=false
					if(item[0]==id){
						item[2]=true
					}
				})
				this.$emit('newsClassify',id)
			},
			//分页查找某目录下的文章
			getNewsContentMainList(catalogueId,language=0,pageNum = 1, pageSize = 6, isDesc = true) {
				api.getAllArticles({
					pageNum,
					pageSize,
					catalogueId,
					language,
					isDesc
				}).then(res => {
					this.newsContentMainList = res.articleDOs
					this.totalPages = res.totalPages
					this.pageSize = res.pageNum
					this.newsContentMainList.forEach(item => {
						item.gmtCreate = formatDate(parseInt(item.gmtCreate))
					})
					if(this.newsContentMainList.length < 3){
						this.lunboList=[]
					}
					
					if (pageNum==1&&this.newsContentMainList.length >= 3) {
						
						this.lunboList = this.newsContentMainList.filter((item, index) => {
							return index < 3
						})
					}
				})
			},
			currentChange: (index) => {
				that.getNewsContentMainList(that.catalogueId,0,index)
			},
			toNewsBulletinItem(articleId,catalogueId) {
				this.$router.push({
					path: '/newsBulletinItem',
					query: {
						articleId,
						catalogueId
					}
				})
			}

		}
	}
</script>

<style lang="less" scoped>
	.disno{
		display: none;
	}
	.swiper-container{
		margin-top: 40px;
	}
	
	.newsBulletin {
		width: 100%;

		.nav {
			text-align: center;
			width: 100%;
			height: 11.25rem;//450
			background: url(../assets/static/bj.png);
			position: relative;

			.newsName {
				position: absolute;
				top: 50%;
				left: 50%;
				color: #FFFFFF;
				transform: translate(-50%, -50%);

				>h1 {
					font-size: 1.3rem;//52
					
					font-family: Source Han Sans SC;
					font-weight: bold;
					color: #FFFFFF;
				}

				span {
					font-size: 0.6rem;//24
					font-family: Roboto;
					font-weight: 400;
					color: #FFFFFF;
					letter-spacing: 3px
				}
			}
		}

		.newsContent {
			width: 100%;
			//height: 46rem;//1840
			min-height: 10rem;
			position: relative;
			background-color: #F8F8F8;
			padding: 1px 1px 50px 1px;
			box-sizing: border-box;
			
			.noMore{
				width: max-content;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				font-size: 0.75rem;//30
				font-family: Source Han Sans SC;
				font-weight: 600;
				color: #666666;
			}
			
			.contentNav {
				position: absolute;
				top: -1rem;//40
				left: 50%;
				transform: translate(-50%);
				width: 36rem;//1440px
				height: 2rem;//80
				line-height: 2rem;//80
				background-color: #FFFFFF;
				box-shadow: 0px 0px 18px 0px rgba(184, 196, 204, 0.15);
				text-align: center;

				>span {
					cursor: pointer;
					margin: 0 5%;
					padding: 0 5px;
					font-size: 0.4rem;//16
					font-family: PingFang SC;
					font-weight: bold;
					padding-bottom: 0.575rem;//23
					color: #999999;
					width: max-content;
					text-align: left;
				}

				.classActive {
					position: relative;
					color: #2459D9 !important;
				}

				.classActive::after {
					content: '';
					position: absolute;
					background-color: #2459D9;
					bottom: 0;
					left: 50%;
					width: 50%;
					transform: translateX(-50%);
					height: 0.075rem;//3
				}
			}

			.contentRecommend {
				width: 36rem;//1440
				height: 9rem;//360
				margin: 3.9rem auto 0 auto;//156
				background: #FFFFFF;
				position: relative;
				overflow: hidden;
				box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);

				>img {
					float: left;
					width: 40%;
					height: 9rem;//360
					cursor: pointer;
				}

				.recommendMain {
					>h3 {
						float: left;
						width: 17.5rem;//700
						margin-left: 1.275rem;//51
						margin-top: 1.325rem;//53
						font-size: 0.75rem;//30
						font-family: Source Han Sans SC;
						font-weight: 500;
						color: #333333;
						cursor: pointer;
					}

					>p {
						float: left;
						margin-left: 1.275rem;//51
						margin-top: 0.85rem;//34
						width: 17.5rem;//700
						font-size: 0.45rem;//18
						font-family: Source Han Sans SC;
						font-weight: 400;
						color: #666666;
						line-height: 30px;
					}

					>span {
						bottom: 0.725rem;//69
						left: 15.8rem;//632
						position: absolute;
						width: 7.5rem;//300
						font-size: 0.45rem;//18
						font-family: Roboto;
						font-weight: 400;
						color: #2459D9;
						line-height: 0.65rem;//26
					}

					.recommendGenduo {
						position: absolute;
						bottom: 0.55rem;//64
						right: 2.675rem;//107
						cursor: pointer;
						>span {
							font-size: 0.45rem;//18
							font-family: Source Han Sans SC;
							font-weight: 400;
							color: #2459D9;
							line-height: 1rem;//40
						}

						>img {
							float: right;
							margin-top: 0.075rem;//3
							transform: rotate(180deg) scale(0.5);
							@media (min-width: 1024px){
								transform: rotate(180deg) scale(0.2);
								margin-top: -3px;
							}
							@media (min-width: 1440px){
								transform: rotate(180deg) scale(0.3);
								margin-top: -1px;
							}
							@media (min-width: 1600px){
								transform: rotate(180deg) scale(0.4);
								margin-top: 0;
							}
							@media (min-width: 1680px){
								transform: rotate(180deg) scale(0.5);
								margin-top: 0.075rem;//3
							}
							
						}
					}
				}
			}


			.rotation_nav {
				width: max-content;
				height: 0.15rem;//6
				margin: 1.75rem auto 0 auto;//70

				/deep/.swiper-pagination-bullet {
					display: inline-block;
					width: 0.6rem;//24
					height: 0.15rem;//6
					margin-left: 0.25rem;//10
					background-color: #E5E5E5;
					border-radius: 3px;
					opacity: 1;
				}

				/deep/.swiper-pagination-bullet-active {
					background: #2459D9 !important;
					opacity: 1;
				}
			}

			// .justify {
			// 	justify-content: space-between;
			// }

			// .contentMain {
			// 	width: 36rem;//1440
			// 	//height: 21.875rem;//875
			// 	margin: 2.5rem auto 0 auto;//100
			// 	// display: flex;
			// 	// flex-wrap: wrap;

			// 	// .mR {
			// 	// 	margin-right: 0.5rem;//30
			// 	// }

			// 	.contentMainItem {
			// 		float: left;
			// 		margin-top: 0.75rem;
			// 		margin-left: 0.25rem;
			// 		margin-right: 0.25rem;
			// 		width: 11.5rem;//460
			// 		height: 9.925rem;//397
			// 		background: #FFFFFF;
			// 		position: relative;
			// 		box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);

			// 		>img {
			// 			width: 11.5rem;//460
			// 			height: 6.25rem;//250
			// 			cursor: pointer;
			// 		}

			// 		>p {
			// 			margin: 0.375rem auto 0 auto;//15
			// 			width: 10.5rem;//420
			// 			font-size: 0.45rem;//18
			// 			font-family: Source Han Sans SC;
			// 			font-weight: 500;
			// 			color: #333333;
			// 			line-height: 0.6rem;//24
			// 		}

			// 		>span {
			// 			position: absolute;
			// 			bottom: 0.675rem;//27
			// 			left: 0.575rem;//23
			// 			font-size: 0.35rem;//14
			// 			font-family: Roboto;
			// 			font-weight: 400;
			// 			color: #265BDA;
			// 		}

			// 		>a {
			// 			position: absolute;
			// 			right: 0.65rem;//26
			// 			bottom: 0.625rem;//25
			// 			font-size: 0.4rem;//16
			// 			font-family: Source Han Sans SC;
			// 			font-weight: 400;
			// 			color: #2459D9;
			// 			line-height: 0.6rem;//24
			// 			text-decoration: none;
			// 			cursor: pointer;
			// 		}
			// 	}
			// }
			
			
			.newsMains{
				width: 36rem;//1440
				margin: 2.5rem auto 0;
				
				.newsMainItem{
					width: 100%;
					margin-top: 1rem;
					padding-bottom: .75rem;
					
					display: flex;
					justify-content: space-between;
					&:not(:last-child){
						border-bottom: 1px solid #CDCDCD;
					}
					>img{
						border-radius: 2px;
						cursor: pointer;
						width: 7rem;//280
						height: 4.5rem;//180
					}
					.titleAndText{
						flex: 1;
						margin-left: 0.75rem;
						>h4{
							margin-top: 0.45rem;
							width: 18.575rem;//703
							font-size: 0.5rem;//24
							font-family: Source Han Sans SC;
							font-weight: 500;
							color: #333333;
							cursor: pointer;
							&:hover{
								color: #2459D9;
							}
						}
						>p{
							margin-top: 0.65rem;
							width: 18.575rem;//703
							font-size: 0.4rem;//18
							font-family: Source Han Sans SC;
							font-weight: 400;
							color: #999999;
							line-height: 0.75rem;//30
						}
					}
					.timeAndgd{
						
						>span{
							display: block;
							width: max-content;
							margin: 0.45rem auto 0;
							font-size: 0.45rem;//18
							font-family: Roboto;
							font-weight: 500;
							color: #333333;
						}
						>p{
							border-radius: 2px;
							cursor: pointer;
							margin-top: 1.75rem;
							width: 2.75rem;//130
							height: 1rem;//44
							font-size: 0.45rem;
							font-family: Source Han Sans SC;
							font-weight: 400;
							color: #333333;
							border: 1px solid #999999;
							padding-left: .3rem;
							box-sizing: border-box;
							line-height: 1rem;
							position: relative;
							&::after{
								content: '';
								position: absolute;
								z-index: 1;
								top: 42%;
								left: 2.2rem;
								transform:  rotate(270deg);
								width: 16px;
								height: 9px;
								background-repeat: no-repeat;
								background-size: cover;
								background: url(../assets/static/xiayiy.png);
							}
							&::before{
								content: '';
								position: absolute;
								z-index: 2;
								display: none;
								top: 45%;
								left: 2.2rem;
								transform:  rotate(270deg);
								width: 16px;
								height: 9px;
								background-repeat: no-repeat;
								background-size: cover;
								background: url(../assets/static/gengduo2.png);
							}
							&:hover{
								background: #2459D9;
								color: #FFFFFF;
							}
							&:hover::before{
								display: block;
							}
							&:hover::after{
								display: none;
							}
						}
					}
					
				}
			}


			.pagination {
				width: 12.125rem;//485

			}

			.el-pagination {
				display: flex;
				clear: both;
				justify-content: center;
				padding-top: 1rem;//40
			}
		}
	}
	@import url("../utils/mediaCss/newsBulletinPhone.less");
</style>
