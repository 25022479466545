import {get,post} from './http.js'

//文章相关接口前半部分
const articlePrefixUrl='https://www.eastsec.org.cn/api/ucsp-contents/'
//其余相关接口前半部分
const otherPrefixUrl='http://8.135.116.83:5623/api/ucsp/'

const otherPrefixUrls='https://www.eastsec.org.cn/api/ucsp/'

//智库资讯相关
const enterprisePrefixUrls='https://www.eastsec.org.cn/enterprise/api/'

export default{
	//文件下载接口
	// getUploadFile(data){
	//    return get(`${articlePrefixUrl}file`,{directory:data.directory,fileName:data.fileName})
	// }
	//查询新闻动态所有目录信息
	//language 语言 0：中文  1：英文
	getAllCatalogues(language){
		return get(`${articlePrefixUrl}front/${language}/allCatalogues`)
	},
	//查找目录下文章时不包括目录下的目录里面的文章, 上级目录为0时, 查找所有文章
	//分页查找某目录下的文章
	getAllArticles(data){
		return get(`${articlePrefixUrl}front/articles`,data)
	},
	//根据唯一标识符(articleId)和语言获取一篇文章
	getArticleById(articleId,language){
		return get(`${articlePrefixUrl}front/${language}/article/${articleId}`)
	},
	
	//检测方案分类列表查询
	getTestCategoryList(){
		return get(`${otherPrefixUrls}category/categoryList`)
	},
	//公链/联盟链检测   2
	//智能合约检测   1
	//钱包检测   3
	//APP检测    4
	//Web网站检测   5
	//系统渗透  7
	//日常监测   8
	//其他检测   6
	//检测分类下父级检测项的子检测项列表
	getTestCategoryChildData(categoryCode){
		return get(`${otherPrefixUrls}category/childOfParentProjectData/${categoryCode}`)
	},
	//查询检测分类下所有检测套餐各自包含的检测项数据
	// getSchemeContainData(categoryCode){
	// 	return get(`${otherPrefixUrls}scheme/schemeContainProjectData/${categoryCode}`)
	// },
	//查询检测分类下所有检测方案列表
	getSchemeList(categoryCode){
		return get(`${otherPrefixUrls}scheme/schemeContainProjectData/${categoryCode}`)
	},
	//检测站列表查询
	getStationList(){
		return get(`${otherPrefixUrls}station/stationList`)
	},
	//查询所有公布检测记录（分页）
	getPublishTestRecords(data){
		return get(`${otherPrefixUrls}item/pagePublicItemRecord.Action`,data)
	},
	//项目名称自动检索
	getItemNameListByName(data){
		return get(`${otherPrefixUrls}item/getItemNameListByName.Action`,data)
	},
	//项目证书查询
	getItemCertificateByName(data){
		return get(`${otherPrefixUrls}item/getItemCertificateByName.Action`,data)
	},
	//获取业态列表
	getDictionaryTagList(){
		return get(`https://www.eastsec.org.cn/enterprice/api/business/parentTagBusinessNum`)
	},
	//分页查询项目
	getProjectSearchList(data){
		return get(`https://www.eastsec.org.cn/enterprice/api/business/pageList`,data)
	},
	//分页查询企业
	postCompanySearchList(data){
		return get(`https://www.eastsec.org.cn/bcsm/company/blockChainHome/searchList`,data)
	},
	getProjectItem(id){
		return get(`https://www.eastsec.org.cn/bcsm/business/detail`,{id})
	},
	getCompanyItem(id){
		return get(`https://www.eastsec.org.cn/py/company/base_info`,{id})
	},
	formatDate(datetime) {
	   var date = new Date(datetime*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
	   var year = date.getFullYear(),
	      month = ("0" + (date.getMonth() + 1)).slice(-2),
	      sdate = ("0" + date.getDate()).slice(-2);
	      // hour = ("0" + date.getHours()).slice(-2),
	      // minute = ("0" + date.getMinutes()).slice(-2),
	      // second = ("0" + date.getSeconds()).slice(-2);
	   // 拼接
	   var result = year + "-"+ month +"-"+ sdate; //+" "+ hour +":"+ minute +":" + second;
	   // 返回
	   return result;
	},
	//获取服务类型分类及描述
	getDetectionClassify(){
		return get(`https://www.eastsec.org.cn/api/ucsp/category/levelOneCategoryList`)
	},
	getDetection(id){
		return get(`https://www.eastsec.org.cn/api/ucsp/category/levelTwoAllData/${id}`,)
	},
	//项目关联企业
	getProjectCompany(companyId){
		return get(`https://www.eastsec.org.cn/bcsm/company/get`,{companyId})
	},
	getRefCompany(id){
		return get(`https://www.eastsec.org.cn/py/company/base_info`,{id})
	}
	
}