<template>
  <div class="news">
    <div class="nav">
      <div class="newsName">
        <h1>{{ $t('newsBulletin.newsDetail') }}</h1>
        <span :class="{disno:lang=='e'}">NEWS AND TRENDS</span>
      </div>
    </div>
    <div class="context">
      <div class="contextMain">
        <div class="titleName">
          <h2>{{ ArticleList.title }}</h2>
        </div>
        <div class="timeAndsource">
          <span class="time">发布时间：{{ ArticleList.gmtCreate }} 来源：东方链安</span>
          <div class="division" />
        </div>
        <div
          class="newsContent"
          v-html="ArticleList.content"
        />
      </div>
    </div>
    <div
      v-if="newsContentMainList.length>=6"
      class="rotation"
    >
      <swiper
        :options="newsSwiperOption"
        :class="[windowWidth<=1024?'':'swiper-no-swiping']"
      >
        <swiper-slide>
          <div class="rotation_item">
            <div
              v-for="(item1,index1) in lunbo1List"
              class="item"
            >
              <img
                :src="'https://www.eastsec.org.cn/api'+item1.homeImage"
                class="img"
                @click="toNewsBulletin(item1.articleId)"
              >
              <div class="item_text">
                <div @click="toNewsBulletin(item1.articleId)">
                  {{ item1.title }}
                </div>
                <span>{{ item1.gmtCreate }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="rotation_item">
            <div
              v-for="(item2,index2) in lunbo2List"
              class="item"
            >
              <img
                :src="'https://www.eastsec.org.cn/api'+item2.homeImage"
                class="img"
                @click="toNewsBulletin(item2.articleId)"
              >
              <div class="item_text">
                <div @click="toNewsBulletin(item2.articleId)">
                  {{ item2.title }}
                </div>
                <span>{{ item2.gmtCreate }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="rotation_item">
            <div
              v-for="(item3,index3) in lunbo3List"
              class="item"
            >
              <img
                :src="'https://www.eastsec.org.cn/api'+item3.homeImage"
                class="img"
                @click="toNewsBulletin(item3.articleId)"
              >
              <div class="item_text">
                <div @click="toNewsBulletin(item3.articleId)">
                  {{ item3.title }}
                </div>
                <span>{{ item3.gmtCreate }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="rotation_nav">
        <span class="active" />
        <span />
        <span />
      </div>
    </div>
    <other-footer :type="pageType" />
  </div>
</template>

<script>
	import otherFooter from '../components/footer.vue'
	import {
		formatDatedian
	} from '../utils/formatDate.js'
	import api from '../request/api.js'
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'
	export default {
		components: {
			otherFooter,
			Swiper,
			SwiperSlide
		},
		data() {
			return {
				lang:sessionStorage.getItem('lang')||'s',
				newsSwiperOption: {
					autoplay: true,
					pagination: {
						el: '.rotation_nav',
						type: 'bullets',
						bulletElement: 'span',
						clickable: true
					}
				},
				pageType: 'other',
				ArticleList: {},
				newsContentMainList: [],
				articleId:'',
				lunbo1List: [],
				lunbo2List: [],
				lunbo3List: [],
				windowWidth:window.document.documentElement.clientWidth
			}
		},
		watch:{
			$route(to,from){
				this.articleId=this.$route.query.articleId
			},
			articleId(){
				this.getArticleList(this.articleId)
			}
		},
		created() {

			this.getArticleList(this.$route.query.articleId)
			this.getNewsContentMainList(this.$route.query.catalogueId)
			
		},
		mounted() {
			var that = this;
			window.onresize = () => {
			  return (() => {
			    window.fullWidth = document.documentElement.clientWidth;
			    that.windowWidth = window.fullWidth; // 宽
			  })()
			}
		},
		methods: {
			toNewsBulletin(articleId){
				this.$router.push({
					path:'/newsBulletinItem',
					query:{
						articleId
					}
				})
			},
			
			getArticleList(articleId, language = 0) {
				api.getArticleById(articleId, language).then(res => {
					this.ArticleList = res.articleDO
					this.ArticleList.gmtCreate=formatDatedian(parseInt(this.ArticleList.gmtCreate))
					console.log(this.ArticleList)
				})
			},
			getNewsContentMainList(catalogueId, language = 0, pageNum = 1, pageSize = 6, isDesc = true) {
				api.getAllArticles({
					pageNum,
					pageSize,
					catalogueId,
					language,
					isDesc
				}).then(res => {
					this.newsContentMainList = res.articleDOs
					this.newsContentMainList.forEach((item, index) => {
						item.gmtCreate = formatDatedian(parseInt(item.gmtCreate))
						if (index < 2) {
							this.lunbo1List.push(item)
						} else if (index < 4) {
							this.lunbo2List.push(item)
						} else if (index < 6) {
							this.lunbo3List.push(item)
						}
					})
					
				})
			},
		}

	}
</script>

<style lang="less" scoped>
	/deep/ .syl-page-img{
		width: 100% !important;
	}
	.disno{
		display: none;
	}
	.news {
		width: 100%;

		.nav {
			text-align: center;
			width: 100%;
			height: 13.75rem; //550
			background: url(../assets/static/bj.png);
			position: relative;

			.newsName {
				position: absolute;
				top: 50%;
				left: 50%;
				color: #FFFFFF;
				transform: translate(-50%, -50%);

				>h1 {
					font-size: 1.2rem; //48
					font-family: Source Han Sans SC;
					font-weight: bold;
					color: #FFFFFF;
				}

				span {
					font-size: 0.6rem; //24
					font-family: Roboto;
					font-weight: 400;
					color: #FFFFFF;
					letter-spacing: 3px
				}
			}
		}

		.context {
			width: 100%;
			padding-bottom: 10px;
			background-color: #F8F8F8;
			.contextMain{
				width: 36rem;
				background-color: #FFFFFF;
				margin: 0 auto;
				padding-bottom: 20px;
				.titleName {
					width: 36rem; //1440
					height: 4.85rem; //202
					background: #FFFFFF;
					border-radius: 5px;
					overflow: hidden;
					position: relative;
					z-index: 99;
					top: -2rem; //80
					left: 50%;
					transform: translateX(-50%);
				
					>h2 {
						width: 21.5rem; //1100
						margin: 2.475rem auto 0 auto; //99
						text-align: center;
						font-weight: 400;
						color: #333333;
						font-size: 0.6rem; //36
						line-height: 1.2rem; //48
					}
				}
				
				.timeAndsource {
					width: 36rem; //1440
					margin: 0 auto;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					margin-top: -1.5rem; //60
				
					.time {
						color: #2459D9;
					}
				
					.division {
						width: 70%;
						margin: 40px auto 10px;
						border-bottom: 1px #BFBFBF solid;
					}
				}
				.newsContent {
					width: 25rem; //1000
					margin: 40px auto;
					color: #666666;
					font-family: Source Han Sans SC;
					font-weight: 400;
					font-size: 0.45rem; //22
					line-height: 1rem;
					overflow: hidden;
					// img{
					// 	width: 100% !important;
					// }
					/deep/ img{
						width: 100% !important;
						margin-top: 1rem ;
						//height: 12rem !important;//400
						height: auto !important;
					}
				}
			}
			

			
		}

		.rotation {
			width: 100%;
			height: 9.65rem; //386
			background-color: #F8F8F8;

			.rotation_item {
				width: 32.5rem; //1200
				margin: 0 auto;
				padding-top: 1.5rem; //60
				display: flex;
				justify-content: space-between;

				.item {
					width: 15rem; //600
					height: 5rem; //200
					background-color: #ffffff;
					box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
					display: flex;

					.img {
						display: block;
						width: 7.5rem; //300
						height: 5rem; //200
						background-color: green;
						cursor: pointer;
					}

					.item_text {
						position: relative;
						width: 7.5rem; //300
						height: 5rem; //200
						color: #333333;
						padding: 0 0.6rem; //24

						div {
							margin-top: 0.7rem; //28
							font-size: 0.475rem; //15
							cursor: pointer;
						}

						span {
							display: block;
							position: absolute;
							bottom: 0.25rem;
							//margin-top: 1.5rem; //60
							color: #2459D9;
							font-size: 0.375rem; //15
						}
					}
				}
			}

			.rotation_nav {
				display: flex;
				justify-content: center;
				margin-top: 0.75rem; //30

				/deep/.swiper-pagination-bullet {
					display: inline-block;
					width: 0.6rem; //24
					height: 0.15rem; //6
					margin-left: 0.25rem; //10
					background-color: #E5E5E5;
					border-radius: 3px;
					opacity: 1;
				}

				/deep/.swiper-pagination-bullet-active {
					background: #2459D9 !important;
					opacity: 1;
				}
			}
		}
	}

	.active {
		background-color: #2459D9 !important;
	}
	@import url("../utils/mediaCss/newsBulletinItemPhone.less");
</style>
