<template>
  <div class="tankInformationItem">
    <header>
      <h2>{{ routeData.name }}</h2>
      <div>
        <p v-if="routeData.onlineDate">
          {{$t('tankInformationItem.onlineTime')}}：{{ routeData.onlineDate.substring(0,10) }}
        </p>
        <p>{{$t('tankInformationItem.Status')}}：{{ routeData.status }}</p>
      </div>
    </header>
    <main>
      <div
        
        class="projectDescriptionTitle"
      >
        <img src="../assets/static/xiangmu.png">
        <h3>{{$t('tankInformationItem.projectDetails')}}</h3>
      </div>
      <p class="projectDescription">
        {{$t('tankInformationItem.icpNo')}}：{{ routeData.icpNo||'-' }}
      </p>
	  <p class="projectDescription">
	    {{$t('tankInformationItem.website')}}：{{ routeData.website||'-' }}
	  </p>
	  <p class="projectDescription">
	    {{$t('tankInformationItem.projectDescription')}}：{{ routeData.websiteDescription||'-' }}
	  </p>
      <div class="affiliatedEnterpriseTitle">
        <img src="../assets/static/qiye.png">
        <h3>{{$t('tankInformationItem.belongTo')}}</h3>
      </div>
      <p v-if="companyData.companyName">
        {{$t('tankInformationItem.companyName')}}：{{ companyData.companyName }}
      </p>
      <p>{{$t('tankInformationItem.legalPerson')}}：{{ companyData.legalPerson }}</p>
     <p>{{$t('tankInformationItem.address')}}：{{refCompanyData.regLocation}}</p>
      <p v-if="refCompanyData.actualCapital">
        {{$t('tankInformationItem.capital')}}：{{ refCompanyData.actualCapital }}
      </p>
      <p>{{$t('tankInformationItem.companyStatus')}}：{{ companyData.status }}</p>
      <p>{{$t('tankInformationItem.companyWebsite')}}：{{ companyData.website||'' }}</p>
    </main>
    <other-footer :type="pageType" />
  </div>
</template>

<script>
	import otherFooter from '../components/footer.vue'
	import {msFormatDate} from '../utils/formatDate.js'
	import api from '../request/api.js'
	export default{
		components: {
			otherFooter
		},
		data(){
			return{
				pageType:'other',
				//项目详情
				routeData:{},
				companyData:{},
				refCompanyData:{}
			}
		},
		created(){
			this.routeData=this.$route.params
			
			this.getProjectItem(this.$route.params.id)
			this.getProjectCompany(this.$route.params.companyId)
			this.getRefCompany(this.$route.params.ref)
			//this.routeData.onlineTime?this.routeData.onlineTime=this.formatDateChina(this.routeData.onlineTime):this.routeData.onlineTime=''
			
		
		},
		methods: {
			formatDateChina(date){
				let newDate=msFormatDate(date)
				return newDate
			},
			getProjectItem(id){
				api.getProjectItem(id).then(res=>{
					this.routeData=res.data
					// this.routeData.onlineTime=this.routeData.onlineTime?this.formatDateChina(this.routeData.onlineTime):''
					
				})
			},
			getProjectCompany(id){
				api.getProjectCompany(id).then(res=>{
					this.companyData=res.data
				
				})
			},
			getRefCompany(id){
				api.getRefCompany(id).then(res=>{
					this.refCompanyData=res.data
				})
			}
		},
	}
</script>

<style lang="less" scoped>
	.tankInformationItem{
		width: 100%;
		overflow: hidden;
		header{
			width: 100%;
			height: 13.75rem;//550
			overflow: hidden;
			background: url(../assets/static/zkbanner.png);
			>h2{
				font-size: 1.2rem;//48
				font-family: Source Han Sans SC;
				font-weight: bold;
				color: #FFFFFF;
				width: max-content;
				margin: 5.125rem auto 0;//205
			}
			>div{
				display: flex;
				justify-content: center;
				margin: 0.9rem auto 0;//36
				>p{
					font-size: 0.6rem;//24
					margin: 0 0.5rem;//20
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #FFFFFF;
					height: max-content;
				}
			}
		}
		main{
			width: 100%;
			overflow: hidden;
			padding-bottom: 2rem;//80
			background-color: #FFFFFF;
			.projectDescriptionTitle,.affiliatedEnterpriseTitle{
				display: flex;
				align-items: center;
				margin: 1.75rem 0 0 12.5%;//70
				>h3{
					margin-left: 0.5rem;//20
					font-size: 0.6rem;//24
					font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
				}
			}
			// .projectDescription{
			// 	//width: 36rem;//1440
			// 	//margin: 0.55rem auto 0;//22
			// 	font-size: 0.45rem;//18
			// 	font-family: Source Han Sans SC;
			// 	font-weight: 400;
			// 	color: #666666;
			// 	line-height: 0.85rem;//34
			// 	@media (max-width:1024px){
			// 		width: 75%;
			// 	}
			// }
			p{
				font-size: 0.45rem;//18
				font-family: Source Han Sans SC;
				font-weight: 400;
				color: #666666;
				line-height: 50px;
				margin-top: 0.65rem;//26
				margin-left: 12.5%;
				text-align: justify;
				@media (max-width:1024px){
					width: 75%;
				}
			}
		}
		
	}
</style>
