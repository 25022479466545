import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import newsBulletinItem from '../views/newsBulletinItem.vue'
import newsBulletin from '../views/newsBulletin.vue'
import webDetection from '../views/webDetection.vue'
import certificateQuery from '../views/certificateQuery.vue'
import certificateView from '../views/certificateView.vue'
import programTestingItem from '../views/programTestingItem.vue'
import tankInformation from '../views/tankInformation.vue'
import tankInformationItem from '../views/tankInformationItem.vue'
import tankInformationItemCompany from '../views/tankInformationItemCompany.vue'
import test from '../views/test.vue'

Vue.use(VueRouter)


const routes = [
	{
		path:'/',
		redirect:'/index'
	},
	{
		name:'index',
		path:'/index',
		component:index
	},
	{
		name:'newsBulletin',
		path:'/newsBulletin',
		component:newsBulletin
	},
	{
		name:'newsBulletinItem',
		path:'/newsBulletinItem',
		component:newsBulletinItem
	},
	{
		name:'webDetection',
		path:'/webDetection',
		component:webDetection
	},
	{
		name:'certificateQuery',
		path:'/certificateQuery',
		component:certificateQuery
	},
	{
		name:'certificateView',
		path:'/certificateView',
		component:certificateView
	},
	{
		name:'programTestingItem',
		path:'/programTestingItem',
		component:programTestingItem,
	},
	{
		name:'tankInformation',
		path:'/tankInformation',
		component:tankInformation
	},
	{
		name:'tankInformationItem',
		path:'/tankInformationItem',
		component:tankInformationItem
	},
	{
		name:'tankInformationItemCompany',
		path:'/tankInformationItemCompany',
		component:tankInformationItemCompany
	},
	{
		name:'test',
		path:'/test',
		component:test
	}
	
]



const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
